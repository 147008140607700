import styled, {keyframes} from 'styled-components';

import {DeterminateProgressBarProps} from './DeterminateProgressBarProps';

const indeterminate = keyframes`
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
`;

export const BarContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const Bar = styled.div<DeterminateProgressBarProps>`
  height: ${({small, theme}) => theme.getSize(small ? 1 : 2)};
  background-color: ${({theme, severity}) => theme.colors.severity[severity ?? 'informational']};
  width: ${({percentage}) => `${percentage * 100}%`};
  transition: width 100ms ease-in-out;
  position: relative;
`;

export const BarAnimation = styled.div`
  width: 100%;
  height: 100%;
  transform-origin: 0% 50%;
  background-color: #00000050;
  animation: indeterminateAnimation 1s infinite linear;
  animation: ${indeterminate} 1s linear infinite;
`;
