import {useCallback, useEffect} from 'react';

import {LightboxApi} from './useLightbox';

export const useLightboxEvents = (api: LightboxApi) => {
  const handler = useCallback(
    (ev: KeyboardEvent) => {
      ev.stopPropagation();

      switch (ev.key) {
        case 'ArrowLeft':
          api.prev();
          break;
        case 'ArrowRight':
          api.next();
          break;
        case 'Escape':
          api.close();
          break;
      }
    },
    [api]
  );

  useEffect(() => {
    document.addEventListener('keyup', handler);
    return () => {
      document.removeEventListener('keyup', handler);
    };
  }, [handler]);
};
