import {useEffect, useRef, useState} from 'react';

/**
 * Custom React hook that syncs a state with a prop value.
 * When the prop value changes, the state is updated to reflect it.
 * This can be useful in controlled components where the prop value
 * can change from the outside, and the state needs to stay in sync.
 *
 * @param {T} initialValue - The initial value of the state, usually passed as a prop.
 * @returns A tuple containing the current state and the function to update it.
 */
export function useStateProp<T>(initialValue: T) {
  // useRef to persist the latest value without causing re-renders.
  // This ref object will hold the current value across re-renders
  // but won't trigger a new render if the value changes.
  const valueRef = useRef(initialValue);

  // useState to hold the value and provide a way to update it.
  // `value` is the current state, and `setValue` is the function that allows
  // updating the state.
  const [value, setValue] = useState<T>(initialValue);

  // useEffect to perform side effects in the component.
  // In this case, it's used to check if the initialValue has changed,
  // indicating a new value has been passed as a prop.
  // If initialValue has changed, update both the ref and the state.
  useEffect(() => {
    if (valueRef.current !== initialValue) {
      valueRef.current = initialValue;
      setValue(initialValue);
    }
  }, [initialValue]);

  return [value, setValue] as const;
}
