import {Flag, Tooltip} from 'platform/components';
import {Box, HStack, Show, Text} from 'platform/foundation';

import {take} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {getWordsFromLowerCase} from '../../../utils/getWordsFromLowerCase';

interface UseCaseTableCellProps extends TestIdProps {
  useCaseIds?: string[];
}

export function UseCaseTableCell(props: UseCaseTableCellProps) {
  if (!props.useCaseIds?.length) {
    return (
      <Text data-testid={suffixTestId('empty-useCase', props)} size="small">
        -
      </Text>
    );
  }

  const items = take(1, props.useCaseIds);

  const tooltipDescription = (
    <Box paddingVertical={2}>
      <HStack spacing={2} wrap>
        {props.useCaseIds.map((useCase) => (
          <Flag
            key={`tooltip-${useCase}`}
            data-testid={suffixTestId(`${useCase}-useCase`, props)}
            label={getWordsFromLowerCase(useCase)}
          />
        ))}
      </HStack>
    </Box>
  );

  return (
    <Tooltip
      isDisabled={props.useCaseIds.length <= 2}
      label="Use Cases"
      description={tooltipDescription}
      placement="top-start"
    >
      <HStack spacing={2}>
        {items.map((useCase) => (
          <Flag
            key={useCase}
            data-testid={suffixTestId(`${useCase}-useCase`, props)}
            label={getWordsFromLowerCase(useCase)}
          />
        ))}
        <Show when={props.useCaseIds.length > 2}>
          <Text size="small">...</Text>
        </Show>
      </HStack>
    </Tooltip>
  );
}
