import {ThemeComponentProps} from '@chakra-ui/react';

export interface SliderCaseValueCases<T> {
  single: T;
  inverted: T | ((props: ThemeComponentProps) => T);
  unturned: T | ((props: ThemeComponentProps) => T);
}

export const sliderCaseValue = <T>(props: ThemeComponentProps, cases: SliderCaseValueCases<T>) => {
  const value = props.value;
  if (!Array.isArray(value)) {
    return cases.single;
  }

  const maybeFactory =
    (Number(value[1]) ?? 0) < (Number(value[0]) ?? 0) ? cases.inverted : cases.unturned;

  return isFactory(maybeFactory) ? maybeFactory(props) : maybeFactory;
};

function isFactory<T>(
  maybeFactory: T | ((props: ThemeComponentProps) => T)
): maybeFactory is (props: ThemeComponentProps) => T {
  return typeof maybeFactory === 'function';
}
