import {ReactNode, useEffect, useState} from 'react';

import {isNotNil, isPositive} from 'ramda-adjunct';

import {PaginationProperties} from '../types/PaginationProperties';
import {PaginationContext} from '../utils/context';

interface PaginationProviderProps extends PaginationProperties {
  defaultPage: number;
  page?: number;
  onPageChange: (page: number) => void;
  children: ReactNode;
}

export function PaginationProvider({
  children,
  defaultPage,
  page,
  pagesQuantity,
  onPageChange,
  ...props
}: PaginationProviderProps) {
  const [activePage, setActivePage] = useState<number>(page ?? defaultPage);

  useEffect(() => {
    if (isNotNil(page)) {
      setActivePage(page);
    }
  }, [page]);

  const changePage = (page: number) => {
    if (!isPositive(page)) {
      setActivePage(page);
    }
    onPageChange(page);
  };

  const setFirstPage = () => {
    if (!isPositive(page)) {
      setActivePage(1);
    }
    onPageChange(1);
  };

  const setPrevPage = () => {
    if (activePage === 1) {
      return;
    }
    if (!isPositive(page)) {
      setActivePage(activePage - 1);
    }
    onPageChange(activePage - 1);
  };
  const setNextPage = () => {
    if (activePage === pagesQuantity) {
      return;
    }
    if (!isPositive(page)) {
      setActivePage(activePage + 1);
    }
    onPageChange(activePage + 1);
  };

  const setLastPage = () => {
    if (!isPositive(page)) {
      setActivePage(pagesQuantity);
    }
    onPageChange(pagesQuantity);
  };

  const state = {
    pagesQuantity,
    activePage,
    ...props,
  };

  const actions = {
    changePage,
    setFirstPage,
    setPrevPage,
    setNextPage,
    setLastPage,
  };

  return (
    <PaginationContext.Provider value={{state, actions}}>{children}</PaginationContext.Provider>
  );
}
