import {Show, VStack} from 'platform/foundation';

/* eslint-disable react/forbid-component-props */
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {useId} from 'react';
import {MentionsInput as MentionsInputComponent, Mention, SuggestionDataItem} from 'react-mentions';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps, OptionType} from '../../types/FormControlProps';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {EMAIL_REGEX, URL_REGEX, getFormattedValue} from './utils/getFormattedValue';

const EMPTY_ARRAY: SuggestionDataItem[] = [];

export interface MentionsInputProps
  extends FormControlProps<string | null, HTMLTextAreaElement>,
    TestIdProps {
  options?: OptionType[];
  placeholder?: string;
}

export function MentionsInput(props: MentionsInputProps) {
  const theme = useTheme();
  const id = useId();

  const borderColor = match(props)
    .when(
      ({errorMessage, isInvalid}) => isNotNilOrEmpty(errorMessage) || isInvalid,
      always(theme.colors.severity.danger)
    )
    .with({isDisabled: true}, always(theme.colors.palettes.neutral[70][40]))
    .otherwise(always(theme.colors.palettes.neutral[70][100]));

  const suggestions: SuggestionDataItem[] =
    props.options?.map((option) => ({
      id: option.value,
      display: option.label,
    })) ?? EMPTY_ARRAY;

  return (
    <VStack>
      <Label
        id={id}
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <MentionsInputComponent
        placeholder={props.placeholder}
        id={id}
        name={props.name}
        disabled={props.isDisabled}
        onBlur={(event) => props.onBlur?.(event as React.FocusEvent<HTMLTextAreaElement>)}
        onFocus={props.onFocus}
        value={props.value ?? undefined}
        onChange={(event, newValue) => props.onChange?.(getFormattedValue(newValue))}
        style={{
          highlighter: {
            padding: '6px 8px',
            fontSize: theme.fontSizes.text.small,
            lineHeight: theme.lineHeights.text.small,
          },
          input: {
            padding: '6px 8px',
            outline: 'none',
            borderRadius: theme.radii.small,
            fontSize: theme.fontSizes.text.small,
            lineHeight: theme.lineHeights.text.small,
          },
          suggestions: {
            list: {
              minWidth: '216px',
              border: '1px solid',
              padding: '8px',
              backgroundColor: theme.colors.general.white,
              borderRadius: theme.radii.medium,
              boxShadow: theme.shadows.elevation_3,
              borderColor: theme.colors.general.separator,
            },
            item: {
              padding: '8px',
              fontSize: theme.fontSizes.text.small,
              borderRadius: theme.radii.small,
              '&focused': {
                backgroundColor: theme.colors.palettes.blue[10][100],
                color: theme.colors.palettes.blue[80][100],
              },
            },
          },
        }}
        css={css`
          textarea {
            border: 1px solid;
            border-color: ${borderColor};
            :hover {
              border-color: ${!props.isDisabled && theme.colors.severity.informational};
            }
            :focus {
              border-color: ${theme.colors.severity.informational};
              box-shadow: ${theme.shadows.active};
            }
            cursor: ${props.isDisabled && 'not-allowed'};
            ::placeholder {
              color: ${theme.colors.text.tertiary};
            }
          }
        `}
        data-testid={props['data-testid']}
      >
        <Mention
          trigger="@"
          data={suggestions}
          style={{
            backgroundColor: theme.colors.palettes.blue[30][100],
          }}
        />
        <Mention
          trigger={EMAIL_REGEX}
          markup="email[__display__]"
          data={EMPTY_ARRAY}
          style={{
            backgroundColor: theme.colors.palettes.blue[30][100],
          }}
        />
        <Mention
          trigger={URL_REGEX}
          markup="url[__display__]"
          data={EMPTY_ARRAY}
          style={{
            backgroundColor: theme.colors.palettes.blue[30][100],
          }}
        />
      </MentionsInputComponent>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
