import {useTheme} from 'styled-components';

import {ReactNode, useEffect, useMemo} from 'react';

import {forEachObjIndexed} from 'ramda';

import {ConfirmDialog} from '../../ConfirmDialog/ConfirmDialog';
import {DeleteDialog} from '../../DeleteDialog/DeleteDialog';
import {Portal} from '../../Portal/components/Portal';
import {PortalManager} from '../../Portal/components/PortalManager';
import {Dialog} from '../Dialog';
import {useDialogsState} from '../hooks/useDialogsState';
import {DIALOGS_EVENTS, DialogEventType} from '../utils/dialogsEvents';
import {DialogsContext} from './DialogsContext';

export interface DialogsProviderProps {
  children: ReactNode;
}

export function DialogsProvider(props: DialogsProviderProps) {
  const theme = useTheme();
  const {
    dialogs,
    openDialog,
    closeDialog,
    openDeleteDialog,
    closeCurrentDialog,
    openConfirmDialog,
  } = useDialogsState();

  const events = useMemo<Record<DialogEventType, EventListener>>(
    () => ({
      openDelete: (event: Event) => {
        isCustomEvent(event) && openDeleteDialog(event.detail.options);
      },
      openConfirm: (event: Event) => {
        isCustomEvent(event) && openConfirmDialog(event.detail.options);
      },
      open: (event: Event) => {
        isCustomEvent(event) && openDialog(event.detail.content, event.detail.options);
      },
      close: (event: Event) => {
        isCustomEvent(event) && closeDialog(event.detail.id);
      },
      closeCurrent: closeCurrentDialog,
    }),
    [openDialog, openDeleteDialog, openConfirmDialog, closeDialog, closeCurrentDialog]
  );

  useEffect(() => {
    forEachObjIndexed(
      (listener, event) =>
        window.addEventListener(DIALOGS_EVENTS[event as DialogEventType], listener),
      events
    );
    return () => {
      forEachObjIndexed(
        (listener, event) =>
          window.removeEventListener(DIALOGS_EVENTS[event as DialogEventType], listener),
        events
      );
    };
  }, [events]);

  const isAnyDialogOpen = dialogs.some((dialog) => dialog.props.isOpen);

  return (
    <>
      <PortalManager zIndex={theme.zIndices.MODAL_COMPONENT}>
        <Portal>
          {dialogs.map((dialog) => {
            if ('dialog' === dialog.type) return <Dialog {...dialog.props} key={dialog.props.id} />;
            if ('confirm' === dialog.type)
              return <ConfirmDialog {...dialog.props} key={dialog.props.id} />;
            if ('delete' === dialog.type)
              return <DeleteDialog {...dialog.props} key={dialog.props.id} />;
            return null;
          })}
        </Portal>
      </PortalManager>
      <DialogsContext.Provider
        value={{
          isAnyDialogOpen,
        }}
      >
        {props.children}
      </DialogsContext.Provider>
    </>
  );
}

const isCustomEvent = (event: Event): event is CustomEvent => !!('detail' in event);
