import {inc} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {range} from '../../../utils/range';

export const getPageNumbers = (
  pagesQuantity: number,
  activePage: number,
  siblingCount: number,
  boundaryCount: number,
  showFirstPage: boolean,
  showLastPage: boolean
): number[] => {
  if (!isPositive(pagesQuantity)) {
    return [];
  }

  // Generate array of all pages.
  const allPages = Array.from(range(0, pagesQuantity)).map(inc);

  let startSegment: number[] = [];
  if (showFirstPage) {
    const boundaryLeftLimit = allPages.slice(0, 1 + boundaryCount);
    startSegment = startSegment.concat(boundaryLeftLimit);
  }

  let endSegment: number[] = [];
  if (showLastPage) {
    const boundaryRightLimit = allPages.slice(1).slice(-boundaryCount - 1);
    endSegment = endSegment.concat(boundaryRightLimit);
  }

  const firstIndexOfLeftSibling = activePage - 1 - siblingCount;
  const leftSiblingItems = allPages.slice(
    firstIndexOfLeftSibling > 0 ? firstIndexOfLeftSibling : undefined,
    activePage - 1
  );

  const firstIndexOfRightSibling = activePage - 1 + 1;
  const rightSiblingItems = allPages.slice(firstIndexOfRightSibling, activePage + siblingCount);

  const allLeftItems = Array.from(new Set([...startSegment, ...leftSiblingItems]));
  const allRightItems = Array.from(new Set([...rightSiblingItems, ...endSegment]));

  const unduplicatedPages = Array.from(new Set([...allLeftItems, activePage, ...allRightItems]));

  const addSeparator = (pages: number[]) =>
    pages.reduce<number[]>((acc: number[], page: number, index: number) => {
      const checkPageForSeparator = () => {
        const pagesLength = pages.length;
        if (index + 1 <= pagesLength - 1) {
          const checkValue = pages[index + 1];

          if (isPositive(checkValue) && checkValue - page === 1) {
            return [page];
          } else {
            return [page, 0];
          }
        }
        return [page];
      };

      return [...acc, ...checkPageForSeparator()];
    }, []);

  const pages = addSeparator(unduplicatedPages);

  return pages;
};
