import {MouseEvent, RefObject, useCallback, useEffect} from 'react';

import {isPositive} from 'ramda-adjunct';

import {LightboxApi} from './useLightbox';

export const useThumbnailEvents = (ref: RefObject<HTMLDivElement>, api: LightboxApi) => {
  const onMouseMove = (ev: MouseEvent) => {
    if (!ref.current) return;

    if (ref.current.classList.contains('mouseDown')) {
      if (isPositive(ev.nativeEvent.movementX) || isPositive(ev.nativeEvent.movementY)) {
        ref.current.classList.add('isMoving');
      }

      ref.current.scrollLeft = ref.current.scrollLeft + ev.nativeEvent.movementX * -1;
    }
  };

  const onMouseDown = (ev: MouseEvent) => {
    ev.stopPropagation();
    ref.current?.classList.add('mouseDown');
    return false;
  };

  const onMouseUp = (ev: MouseEvent) => {
    ev.stopPropagation();
    ref.current?.classList.remove('isMoving');
    ref.current?.classList.remove('mouseDown');
    return false;
  };

  const onMouseLeave = (ev: MouseEvent) => {
    ref.current?.classList.remove('isMoving');
    ref.current?.classList.remove('mouseDown');
  };

  const handleThumbnailsCentering = useCallback(
    (target?: HTMLDivElement | null, smooth = true) => {
      if (!ref.current || !target) return;

      if (target.offsetLeft > ref.current.clientWidth / 2 || ref.current.scrollLeft > 0) {
        ref.current.scroll({
          left: target.offsetLeft - ref.current.clientWidth / 2 + target.clientWidth / 2,
          behavior: smooth ? 'smooth' : 'auto',
        });
      }
    },
    [ref]
  );

  const onThumbnailClick = (slideIndex: number) => () => {
    api.goTo(slideIndex);
  };

  useEffect(() => {
    handleThumbnailsCentering(ref.current?.querySelector('.isActive'));
  }, [api.activeSlide, ref, handleThumbnailsCentering]);

  useEffect(() => {
    handleThumbnailsCentering(ref.current?.querySelector('.isActive'), false);
  }, [api.state.isOpen, ref, handleThumbnailsCentering]);

  return {
    onThumbnailClick,
    onMouseLeave,
    onMouseUp,
    onMouseMove,
    onMouseDown,
  };
};
