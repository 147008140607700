import {css} from 'styled-components';

import {AriaRole, MouseEvent, MouseEventHandler, ReactNode} from 'react';

import {suffixTestId, Nullish, TestIdProps} from 'shared';

export interface ClickableProps extends TestIdProps {
  children?: ReactNode;
  isDisabled?: boolean | Nullish;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseWheelClick?: MouseEventHandler<HTMLDivElement>;
  onContextMenu?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  role?: AriaRole;
}

export function Clickable(props: ClickableProps) {
  const onAuxClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.button === 1) {
      props.onMouseWheelClick?.(event);
    }
  };
  return (
    <div
      role={props.role}
      onClick={props.onClick}
      onAuxClick={onAuxClick}
      onContextMenu={props.onContextMenu}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-testid={suffixTestId('clickable', props)}
      css={css`
        cursor: ${props.isDisabled ?? !props.onClick ? 'initial' : 'pointer'};
        pointer-events: ${props.isDisabled ? 'none' : 'all'};
      `}
    >
      {props.children}
    </div>
  );
}
