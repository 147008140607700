import {Button, DataStatus, openDialog, Table, TableRow} from 'platform/components';
import {Heading, HStack, Text} from 'platform/foundation';

import {isEmpty} from 'ramda';

import {useGetAuthorizationsQuery} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {AuthorizationType} from '../types';
import {AuthorizationForm} from './AuthorizationForm';

interface AuthorizationsProps {
  tenantId: string;
}

export function Authorizations(props: AuthorizationsProps) {
  const {data, isLoading, isError} = useGetAuthorizationsQuery({'x-tenant': props.tenantId});

  const openFormDialog = (authorizationProfile?: AuthorizationType | null) => {
    openDialog(
      <AuthorizationForm tenantId={props.tenantId} defaultValues={authorizationProfile} />,
      {
        title: authorizationProfile?.name ?? i18n.t('authorization.actions.newAuthorization'),
        scrollBehavior: 'outside',
      }
    );
  };

  return (
    <>
      <HStack width="100%" justify="space-between">
        <Heading size={4}>{i18n.t('authorization.labels.authorizations')}</Heading>
        <Button
          variant="ghostLink"
          leftIcon="content/add_circle"
          onClick={() => openFormDialog()}
          title={i18n.t('authorization.actions.newAuthorization')}
        />
      </HStack>
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        isEmpty={isEmpty(data)}
        minHeight={15}
        errorMessage={i18n.t('authorization.labels.emptyAuthorizations')}
        emptyMessage={i18n.t('authorization.labels.emptyAuthorizations')}
      >
        <Table tableLayout="fixed">
          <TableRow>
            <Text alternative size="small">
              {i18n.t('common.name')}
            </Text>
            <Text alternative size="small">
              {i18n.t('authorization.labels.code')}
            </Text>
          </TableRow>
          {data?.map((authorization) => (
            <TableRow
              key={authorization?.id}
              actions={{
                primary: [
                  {
                    title: i18n.t('authorization.actions.editAuthorization'),
                    icon: 'image/edit',
                    onClick: () => openFormDialog(authorization),
                  },
                ],
              }}
            >
              <Text size="small" noWrap>
                {authorization?.name}
              </Text>
              <Text size="small" noWrap>
                {authorization?.code}
              </Text>
            </TableRow>
          ))}
        </Table>
      </DataStatus>
    </>
  );
}
