/**
 * !!! WARNING !!! DEPRACATED !!! use useDebouncedCallback instead
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `leading` is passed, trigger the function on the
 * leading edge and debounce subsequent calls.
 *
 * @param func
 * @param wait
 * @param leading
 */
export const debounce = <T extends unknown[]>(
  func: (...params: T) => void,
  wait: number,
  leading = false
): ((...params: T) => void) => {
  let timeout: number;
  let shouldTriggerNow = leading;
  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func.apply(this, args);
      if (leading) {
        shouldTriggerNow = true;
      }
    };
    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (shouldTriggerNow) {
      shouldTriggerNow = false;
      func.apply(this, args);
    }
  };
};
