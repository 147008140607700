import {testIds} from '@omnetic-admin-service/shared';
import {
  closeCurrentDialog,
  DataStatus,
  Form,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import * as Yup from 'yup';

import {useMemo} from 'react';

import {useListProjectsQuery, useUpdateTenantLicenseMutation} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {
  GetTenantApiResponse,
  ListTenantLicensesApiResponse,
  TenantLicenseResponseBody,
  UpdateTenantLicenseApiArg,
} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';
import {AssignLicenseForm} from '../types';
import {AssignLicenseDialogForm} from './AssignLicenseDialogForm';

interface EditLicenceDialogProps {
  tenantId: string;
  defaultValues: TenantLicenseResponseBody | null;
  tenant?: GetTenantApiResponse;
  tenantLisenses?: ListTenantLicensesApiResponse;
}

export function EditLicenseDialog(props: EditLicenceDialogProps) {
  const [updateLicense, {isLoading: isUpdateLicenseLoading}] = useUpdateTenantLicenseMutation();
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isError: hasProjectsError,
  } = useListProjectsQuery();

  const onSubmit: FormSubmitHandler<AssignLicenseForm> = async (values) => {
    const args: UpdateTenantLicenseApiArg = {
      tenantId: props.tenantId,
      licenseId: props.defaultValues!.licenseId,
      tenantAddLicenseRequestBody: {licenseId: values.license},
    };

    await updateLicense(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('tenants.editLicenseSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const defaultValues = useMemo(() => {
    const project = projects?.find((project) => project.name === props.defaultValues?.project);
    const module = project?.modules.find((module) => module.name === props.defaultValues?.module);
    return {
      project: project?.id,
      module: module?.id,
      license: props.defaultValues?.licenseId,
    };
  }, [projects, props.defaultValues]);

  return (
    <DataStatus isLoading={isProjectsLoading} isError={hasProjectsError} minHeight={15}>
      <Form<AssignLicenseForm> onSubmit={onSubmit} schema={schema} defaultValues={defaultValues}>
        {(control, formApi) => (
          <AssignLicenseDialogForm
            edit
            control={control}
            formApi={formApi}
            projects={projects}
            tenantName={props.tenant?.name ?? '-'}
            tenantLisenses={props.tenantLisenses}
            isLoading={isUpdateLicenseLoading}
            defaultValues={defaultValues}
            data-testid={testIds.tenantDetail('editLicense')}
          />
        )}
      </Form>
    </DataStatus>
  );
}

const schema = Yup.object().shape({
  project: Yup.string().required(),
  module: Yup.string().required(),
  license: Yup.string().required(),
});
