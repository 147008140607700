/**
 * Checks if a given property key exists in a provided object.
 *
 * @template T - The type of the object.
 * @param {T} x - The object to check for the existence of the property key.
 * @param {PropertyKey} k - The property key to check for existence in the object.
 * @returns {k is keyof T} - Returns `true` if the property key exists in the object, otherwise `false`.
 *
 * @example
 * const myObject = { foo: 42, bar: 'hello' };
 * if (isKey(myObject, 'foo')) {
 *   console.log('The property key "foo" exists in the object.');
 * } else {
 *   console.log('The property key "foo" does not exist in the object.');
 * }
 */
export function isKey<T extends Record<string, unknown>>(x: T, k: PropertyKey): k is keyof T {
  return k in x;
}
