import {HStack, Show} from 'platform/foundation';
import styled from 'styled-components';

import {path} from 'ramda';

import {useBoolean} from 'shared';

import {IconButton} from '../../IconButton/IconButton';
import {LightboxApi} from '../hooks/useLightbox';
import {LightboxState} from '../types/LightboxState';

const StyledLightboxToolbar = styled.div<LightboxState>`
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const LightboxControls = styled.div`
  position: relative;
  height: 65px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
`;

type LightboxToolbarProps = {
  api: LightboxApi;
};

export function LightboxToolbar({api}: LightboxToolbarProps) {
  const hasOriginal = !!path([api.activeSlide, 'original'], api.slides ?? []);
  const [downloading, startDownloading, stopDownloading] = useBoolean();

  const download = async () => {
    startDownloading();
    await api.download();
    stopDownloading();
  };

  return (
    <StyledLightboxToolbar {...api.state}>
      <LightboxControls>
        <HStack spacing={1}>
          <Show when={api.state.isZoomed}>
            <IconButton
              priority="primary"
              onClick={api.resetZoom}
              icon="navigation/fullscreen_exit"
            />
          </Show>
          <Show when={api.state.isZoomed}>
            <IconButton
              priority="primary"
              onClick={api.zoomOut}
              icon="content/remove_circle_outline"
            />
          </Show>
          <IconButton priority="primary" onClick={api.zoomIn} icon="content/add_circle_outline" />
          <Show when={hasOriginal}>
            <IconButton
              priority="primary"
              isDisabled={downloading}
              onClick={download}
              icon="file/download"
            />
          </Show>
          <IconButton priority="primary" onClick={api.close} icon="navigation/close" />
        </HStack>
      </LightboxControls>
    </StyledLightboxToolbar>
  );
}
