import {Spinner} from 'platform/foundation';
import {VStack} from 'platform/foundation';

import {Outlet} from 'react-router-dom';

import {useQuickInfoQuery} from '../../api/adminApi';

export function Initialize() {
  const {data: userInfo, isLoading: isUserInfoLoading} = useQuickInfoQuery();

  if (!userInfo || isUserInfoLoading) {
    return (
      <VStack height="100%" align="center" justify="center">
        <Spinner />
      </VStack>
    );
  }

  return <Outlet />;
}
