import {ReactNode} from 'react';

import {createContext} from 'shared';

import {defaultHttpClient, HttpClient} from '../utils/httpClient';

type ProviderConfig = {
  httpClient: HttpClient;
};

export const [Provider, useConfig] = createContext<ProviderConfig>({
  name: 'DynamicUiContext',
  strict: false,
});

type DynamicUiProviderProps = {children: ReactNode; httpClient?: HttpClient};

export function DynamicUiProvider(props: DynamicUiProviderProps) {
  return (
    <Provider value={{httpClient: props.httpClient || defaultHttpClient}}>
      {props.children}
    </Provider>
  );
}
