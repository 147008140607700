import {testIds} from '@omnetic-admin-service/shared';
import {
  Action,
  Attributes,
  AttributesRow,
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  EmptyStatus,
  Flag,
  FormSubmitHandler,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Center, Right, Show, Space} from 'platform/foundation';

import {groupBy} from 'ramda';

import {buildArray, useRequiredParams} from 'shared';

import {
  useAddModuleMutation,
  useArchiveProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
} from '../../api/adminApi';
import {PageHeader} from '../../components/PageHeader/PageHeader';
import {ProjectForm, ProjectFormDialog} from '../../components/ProjectFormDialog/ProjectFormDialog';
import {i18n} from '../../i18n/config';
import {AddModuleApiArg, ArchiveProjectApiArg, UpdateProjectApiArg} from '../../types';
import {handleApiError} from '../../utils/handleApiError';
import {ModuleSection} from './components/ModuleSection';

function ProjecetDetail() {
  const {id} = useRequiredParams();

  const [updateProject, {isLoading: isEditProjectLoading}] = useUpdateProjectMutation();
  const [addModule, {isLoading: isCreateModuleLoading}] = useAddModuleMutation();
  const [archiveProject, {isLoading: isArchiveProjectLoading}] = useArchiveProjectMutation();
  const {data, isLoading, isError} = useGetProjectQuery(
    {projectId: id},
    {
      selectFromResult: (res) => {
        const modules = groupBy(
          (module) => (module.archived ? i18n.t('common.archived') : i18n.t('common.active')),
          res.data?.modules ?? []
        );
        return Object.assign({}, res, {data: Object.assign({}, res.data, {modules})});
      },
    }
  );

  const onEditDialogOpen = () =>
    openDialog(
      <ProjectFormDialog
        data-testid={testIds.projectDetail('editProject')}
        description={i18n.t('projects.editDescription')}
        isLoading={isEditProjectLoading}
        defaultValues={{
          id: data?.id,
          name: data?.name,
          description: data?.description,
        }}
        onSubmit={onEditProject}
      />,
      {
        id: 'editDialog',
        'data-testid': testIds.projectDetail('editProject'),
        title: i18n.t('projects.editTitle', {project: data?.name ?? '-'}),
      }
    );

  const onCreateModuleDialog = () =>
    openDialog(
      <ProjectFormDialog
        data-testid={testIds.projectDetail('createModule')}
        isLoading={isCreateModuleLoading}
        defaultValues={{id: data?.id, name: ''}}
        onSubmit={onCreateModule}
      />,
      {
        id: 'createModuleDialog',
        'data-testid': testIds.projectDetail('createModule'),
        title: i18n.t('projects.createModule'),
      }
    );

  const onArchiveProjectDialogOpen = () =>
    openDialog(
      <ButtonGroup align="right">
        <Button
          data-testid={testIds.projectDetail('archiveProject-confirmDialog-cancel')}
          variant="secondary"
          onClick={closeCurrentDialog}
          isDisabled={isArchiveProjectLoading}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          data-testid={testIds.projectDetail('archiveProject-confirmDialog-confirm')}
          variant="primary"
          onClick={onArchiveProject}
          isLoading={isArchiveProjectLoading}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>,
      {
        'data-testid': testIds.projectDetail('archiveProject'),
        id: 'archiveProjectDialog',
        title: i18n.t('projects.archiveProjectDescription', {project: data.name}),
      }
    );

  const onEditProject: FormSubmitHandler<ProjectForm> = async (values) => {
    const arg: UpdateProjectApiArg = {
      projectId: values.id,
      updateProjectRequestBody: {
        name: values.name,
        description: values.description ?? null,
      },
    };

    await updateProject(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.editProjectSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onCreateModule: FormSubmitHandler<ProjectForm> = async (values) => {
    const arg: AddModuleApiArg = {
      projectId: values.id,
      addModuleRequestBody: {
        name: values.name,
        description: values.description ?? null,
      },
    };

    await addModule(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.createModuleSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onArchiveProject = () => {
    const arg: ArchiveProjectApiArg = {
      projectId: data.id,
    };
    archiveProject(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.archiveProjectSuccessMessage'));
        closeCurrentDialog();
      })
      .catch((err) => handleApiError(err, {callback: () => closeDialog('archiveProject')}));
  };

  return (
    <>
      <PageHeader
        data-testid={testIds.projectDetail('header')}
        title={data?.name ?? id}
        isLoading={isLoading}
        actions={buildArray<Action>()
          .whenNot(data.archived, {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('common.edit'),
            onClick: onEditDialogOpen,
          })
          .whenNot(data.archived, {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('projects.archiveProject'),
            onClick: onArchiveProjectDialogOpen,
          })}
      />

      <DataStatus isLoading={isLoading} isError={isError} isEmpty={!data} minHeight={30}>
        <>
          <Box width={177}>
            <Card variant="inlineWhite">
              <Attributes
                size="third"
                data-testid={testIds.projectDetail('info')}
                rows={buildArray<AttributesRow>()
                  .add({label: i18n.t('common.id'), value: data?.id})
                  .add({label: i18n.t('common.name'), value: data?.name})
                  .add({label: i18n.t('common.description'), value: data?.description})}
              />
              <Show when={data.archived}>
                <Right>
                  <Flag
                    data-testid={testIds.projectDetail('status')}
                    colorScheme="orange"
                    label={i18n.t('common.archived')}
                  />
                </Right>
              </Show>
            </Card>
          </Box>
          <Separator />
          <Show when={!data.archived}>
            <Center width="100%" justify="flex-end">
              <Button
                key="create-project"
                variant="ghostLink"
                leftIcon="content/add_circle"
                data-testid={testIds.projectDetail('openCreateModuleDialog')}
                onClick={onCreateModuleDialog}
                title={i18n.t('projects.createModule')}
              />
            </Center>
          </Show>
          <Space vertical={4} />
          <Show when={data?.modules?.active?.length}>
            <ModuleSection
              projectId={data.id}
              data-testid={testIds.projectDetail('activeModuleSection')}
              data={data.modules.active}
            />
          </Show>
          <Show when={!data?.modules?.active?.length}>
            <EmptyStatus
              headline={i18n.t('projects.noActiveModules')}
              data-testid={testIds.projectDetail('activeModuleSectionEmpty')}
            />
          </Show>
          <Space vertical={4} />
          <Show when={data?.modules?.archived?.length}>
            <ModuleSection
              archived
              projectId={data.id}
              data-testid={testIds.projectDetail('archivedModuleSection')}
              data={data.modules.archived}
            />
          </Show>
          <Show when={!data?.modules?.archived?.length}>
            <EmptyStatus
              headline={i18n.t('projects.noArchivedModules')}
              data-testid={testIds.projectDetail('archivedModuleSection')}
            />
          </Show>
        </>
      </DataStatus>
    </>
  );
}

export const ProjectDetail = ProjecetDetail;
