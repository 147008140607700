import {testIds} from '@omnetic-admin-service/shared';
import {
  Attributes,
  AttributesRow,
  Button,
  Card,
  DataStatus,
  openDialog,
  Separator,
  Table,
  TableRow,
  TableRowActionsData,
  Tooltip,
  copyToClipboard,
} from 'platform/components';
import {Box, Clickable, Heading, HStack, Text, VStack} from 'platform/foundation';

import {buildArray, useRequiredParams} from 'shared';

import {useGetTenantQuery, useListTenantLicensesQuery} from '../../api/adminApi';
import {PageHeader} from '../../components/PageHeader/PageHeader';
import {i18n} from '../../i18n/config';
import {TenantLicenseResponseBody} from '../../types';
import {AssignLicenseDialog} from './components/AssignLicenseDialog';
import {AuthorizationProfiles} from './components/AuthorizationProfiles';
import {Authorizations} from './components/Authorizations';
import {EditLicenseDialog} from './components/EditLicenseDialog';
import {EditTenantDialog} from './components/EditTenantDialog';
import {RemoveLicenseDialog} from './components/RemoveLicenseDialog';

export function TenantDetail() {
  const {id} = useRequiredParams();

  const {
    isLoading: isTenantLoading,
    data: tenant,
    isError: hasTenantError,
  } = useGetTenantQuery({
    tenantId: id,
  });
  const {
    isLoading: isLicensesLoading,
    data: licenses,
    isError: hasLicensesError,
  } = useListTenantLicensesQuery({
    tenantId: id,
  });

  const onEditDialogOpen = () =>
    openDialog(<EditTenantDialog data-testid={testIds.tenantDetail('edit')} tenant={tenant} />, {
      'data-testid': testIds.tenantDetail('edit'),
      title: i18n.t('tenants.editTenantTitle', {name: tenant?.name ?? '-'}),
      id: 'editTenantDialog',
    });

  const onAssignDialogOpen = () =>
    openDialog(
      <AssignLicenseDialog
        data-testid={testIds.tenantDetail('assignLicense')}
        tenantId={id}
        tenant={tenant}
        tenantLisenses={licenses}
      />,
      {
        'data-testid': testIds.tenantDetail('assignLicense'),
        title: i18n.t('tenants.assignLicenseTitle', {
          name: tenant?.name ?? '-',
        }),
        scrollBehavior: 'outside',
      }
    );

  const onLicenseEditDialogOpen = (license: TenantLicenseResponseBody | null) =>
    openDialog(
      <EditLicenseDialog
        data-testid={testIds.tenantDetail('editLicense')}
        tenantId={id}
        defaultValues={license}
        tenant={tenant}
        tenantLisenses={licenses}
      />,
      {
        'data-testid': testIds.tenantDetail('editLicense'),
        title: i18n.t('tenants.editLicenseTitle', {
          name: tenant?.name ?? '-',
        }),
        scrollBehavior: 'outside',
      }
    );

  const onLicenseRemoveDialogOpen = (license: TenantLicenseResponseBody | null) =>
    openDialog(
      <RemoveLicenseDialog
        data-testid={testIds.tenantDetail('removeLicense')}
        tenantId={id}
        license={license}
      />,
      {
        title: i18n.t('tenants.removeLicenseTitle', {name: license?.name ?? '-'}),
        scrollBehavior: 'outside',
        'data-testid': testIds.tenantDetail('removeLicense'),
      }
    );

  const getTableRowActions = (license: TenantLicenseResponseBody) => {
    const actions: TableRowActionsData = {
      primary: [
        {
          title: i18n.t('common.editLicense'),
          icon: 'image/edit',
          onClick: () => onLicenseEditDialogOpen(license),
        },
        {
          title: i18n.t('common.removeLicense'),
          icon: 'action/delete',
          severity: 'danger',
          onClick: () => onLicenseRemoveDialogOpen(license),
        },
      ],
    };
    return actions;
  };

  return (
    <>
      <PageHeader
        data-testid={testIds.tenantDetail('header')}
        title={tenant?.name ?? id}
        isLoading={isTenantLoading}
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('common.edit'),
            onClick: onEditDialogOpen,
          },
        ]}
      />

      <DataStatus
        isLoading={isTenantLoading}
        isError={hasTenantError}
        isEmpty={!tenant}
        minHeight={30}
      >
        <>
          <Box width={177}>
            <Card variant="inlineWhite">
              <Attributes
                size="third"
                data-testid={testIds.tenantDetail('info')}
                rows={buildArray<AttributesRow>()
                  .add({label: i18n.t('common.id'), value: tenant?.id})
                  .add({label: i18n.t('common.name'), value: tenant?.name})
                  .add({label: i18n.t('common.workspace'), value: tenant?.workspace})
                  .add({label: i18n.t('common.host'), value: tenant?.host})
                  .add({label: i18n.t('common.note'), value: tenant?.note})}
              />
            </Card>
          </Box>

          <Separator />
          <VStack spacing={4}>
            <HStack width="100%" justify="space-between">
              <Heading size={4}>{i18n.t('common.licenses')}</Heading>
              <Button
                data-testid={testIds.tenantDetail('assignLicense')}
                variant="ghostLink"
                leftIcon="action/assignment"
                onClick={onAssignDialogOpen}
                title={i18n.t('common.assignLicense')}
              />
            </HStack>
            <DataStatus
              isLoading={isLicensesLoading}
              isError={hasLicensesError}
              isEmpty={!licenses?.length}
              emptyMessage={i18n.t('tenants.noLicenses', {name: tenant?.name})}
            >
              <Table tableLayout="fixed" data-testid={testIds.tenantDetail('licenses')}>
                <TableRow actionsWidth={21}>
                  <Text alternative size="small">
                    {i18n.t('common.id')}
                  </Text>
                  <Text alternative size="small">
                    {i18n.t('common.project')}
                  </Text>
                  <Text alternative size="small">
                    {i18n.t('common.module')}
                  </Text>
                  <Text alternative size="small">
                    {i18n.t('common.license')}
                  </Text>
                </TableRow>
                {licenses?.map((license) => (
                  <TableRow
                    key={license.licenseId}
                    data-testid={testIds.tenantDetail(`licensesRow-${license.licenseId}`)}
                    actions={getTableRowActions(license)}
                  >
                    <Tooltip
                      label={i18n.t('common.clickMeToCopy')}
                      description={license.licenseId}
                      placement="top-start"
                    >
                      <Clickable onClick={() => copyToClipboard(license.licenseId)}>
                        <Text
                          data-testid={testIds.tenantDetail(`tenantsRow-${license.licenseId}-id`)}
                          size="small"
                          noWrap
                        >
                          {license.licenseId}
                        </Text>
                      </Clickable>
                    </Tooltip>
                    <Tooltip
                      label={i18n.t('common.project')}
                      description={license.project}
                      placement="top-start"
                    >
                      <Text
                        data-testid={testIds.tenantDetail(
                          `licensesRow-${license.licenseId}-project`
                        )}
                        size="small"
                        noWrap
                      >
                        {license.project}
                      </Text>
                    </Tooltip>
                    <Tooltip
                      label={i18n.t('common.module')}
                      description={license.module}
                      placement="top-start"
                    >
                      <Text
                        data-testid={testIds.tenantDetail(
                          `licensesRow-${license.licenseId}-module`
                        )}
                        size="small"
                        noWrap
                      >
                        {license.module}
                      </Text>
                    </Tooltip>
                    <Tooltip
                      label={i18n.t('common.license')}
                      description={license.name}
                      placement="top-start"
                    >
                      <Text
                        data-testid={testIds.tenantDetail(`licensesRow-${license.licenseId}-name`)}
                        size="small"
                        noWrap
                      >
                        {license.name}
                      </Text>
                    </Tooltip>
                  </TableRow>
                ))}
              </Table>
            </DataStatus>
            <Authorizations tenantId={id} />
            <AuthorizationProfiles tenantId={id} />
          </VStack>
        </>
      </DataStatus>
    </>
  );
}
