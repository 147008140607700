import {ReactNode, useMemo} from 'react';

import {Breadcrumb as ChakraBreadcrumb} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {flattenReactNode} from './flattenReactNode';

export interface BreadcrumbProps extends TestIdProps {
  children: ReactNode;
}

export function Breadcrumb(props: BreadcrumbProps) {
  const flatChildren = useMemo<Array<ReactNode>>(
    () => flattenReactNode(props.children),
    [props.children]
  );

  return (
    <ChakraBreadcrumb data-testid={suffixTestId('breadcrumb', props)}>
      {flatChildren}
    </ChakraBreadcrumb>
  );
}
