import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  Option,
  Separator,
  TextInput,
} from 'platform/components';
import {Box, Hide, HStack, Show, Space, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {useCallback, useEffect, useState} from 'react';

import {flatten, head, isNil, map, pipe, uniq} from 'ramda';
import {isArray, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, yupString} from 'shared';

import {
  useGetAllApiEnumsQuery,
  useGetNationalSalesCompaniesQuery,
  useLazyGetApiMakeModelWithMakeQuery,
  usePatchAuthorizationMutation,
  usePostAuthorizationMutation,
} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {handleApiError} from '../../../utils/handleApiError';
import {AuthorizationType} from '../types';

const VEHICLE_TYPE_PROPERTY = 'vehicle_type';

interface AuthorizationFormProps {
  tenantId: string;
  defaultValues: AuthorizationType | Nullish;
}

export function AuthorizationForm(props: AuthorizationFormProps) {
  const {data: companies, isLoading: isCompaniesLoading} = useGetNationalSalesCompaniesQuery(
    {'x-tenant': props.tenantId},
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.nationalSalesCompnay.map((company) => ({
          label: company?.name,
          value: company?.id,
        })),
      }),
    }
  );
  const {data: vehicleTypes, isLoading: isVehicleTypesLoading} = useGetAllApiEnumsQuery(
    {lang: [i18n.language]},
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.find((item) => item.property_name === VEHICLE_TYPE_PROPERTY)?.keys,
      }),
    }
  );
  const [getMakeModelWithMake, {isLoading: isMakesLoading}] = useLazyGetApiMakeModelWithMakeQuery();

  const [makeOptions, setMakeOptions] = useState<Option[]>([]);

  const [patchAuthorization] = usePatchAuthorizationMutation();
  const [postAuthorization] = usePostAuthorizationMutation();

  const handleFormSubmit: FormSubmitHandler<AuthorizationType> = async (data) => {
    const body = {
      name: data.name ?? '',
      code: data.code ?? '',
      vehicleMakes: data.vehicleMakes ?? [],
      vehicleModels: data.vehicleModels ?? [],
      vehicleTypes: data.vehicleTypes ?? [],
      nationalSalesCompanyId: data.nationalSalesCompanyId,
    };

    if (isNil(data.id))
      return await postAuthorization({
        'x-tenant': props.tenantId,
        body,
      })
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);

    await patchAuthorization({
      'x-tenant': props.tenantId,
      authorizationId: data.id,
      body,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const handleTypeChange = useCallback(
    async (value: string | number | (string | number)[] | null) => {
      if (!isArray(value)) return;

      const results = await Promise.all(
        value.map((vehicleType) =>
          getMakeModelWithMake({vehicleType: String(vehicleType), lang: [i18n.language]}).unwrap()
        )
      );

      const options = pipe(
        flatten,
        map((make) => ({
          label: make.default_label,
          value: make.make,
        })),
        uniq
      )(results);

      setMakeOptions(options);
    },
    [getMakeModelWithMake]
  );

  useEffect(() => {
    if (isNil(props.defaultValues) || isNil(props.defaultValues.vehicleTypes)) return;

    handleTypeChange(props.defaultValues.vehicleTypes.filter(isNotNil));
  }, [handleTypeChange, props]);

  return (
    <Form<AuthorizationType>
      defaultValues={props.defaultValues}
      schema={FormSchema}
      onSubmit={handleFormSubmit}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                name="name"
                isRequired
                type="text"
                label={i18n.t('common.name')}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                name="code"
                isRequired
                type="text"
                label={i18n.t('authorization.labels.code')}
              />
            </Box>
          </HStack>
          <Separator spacing={0} />
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                name="vehicleTypes"
                type="multiChoice"
                isNotClearable
                label={i18n.t('authorization.labels.vehicleType')}
                options={vehicleTypes?.map((type) => ({
                  label: head(type.labels)?.label,
                  value: type.const_key,
                }))}
                isLoading={isVehicleTypesLoading}
                onChange={(value) => {
                  formApi.setValue('vehicleMakes', []);
                  handleTypeChange(value);
                }}
              />
            </Box>
            <Box flex={1}>
              <Show when={isNotNilOrEmpty(formApi.watch('vehicleTypes'))}>
                <FormField
                  control={control}
                  name="vehicleMakes"
                  type="multiChoice"
                  isNotClearable
                  label={i18n.t('authorization.labels.vehicleMake')}
                  options={makeOptions}
                  isLoading={isMakesLoading}
                />
              </Show>
              <Hide when={isNotNilOrEmpty(formApi.watch('vehicleTypes'))}>
                <Label>{i18n.t('authorization.labels.vehicleMake')}</Label>
                <TextInput value={null} isDisabled />
              </Hide>
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                name="nationalSalesCompanyId"
                type="choice"
                label={i18n.t('authorization.labels.nsc')}
                options={companies}
                isLoading={isCompaniesLoading}
              />
            </Box>
            <Space fillAvailable />
          </HStack>
          <ButtonGroup align="right">
            <FormButton
              control={control}
              title={i18n.t('general.actions.discard')}
              onClick={closeCurrentDialog}
              variant="secondary"
            />
            <FormButton control={control} title={i18n.t('general.actions.save')} type="submit" />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const FormSchema = object({
  name: yupString.required(),
  code: yupString.required(),
  vehicleMakes: array().of(yupString),
  vehicleTypes: array().of(yupString),
  nationalSalesCompanyId: yupString,
});
