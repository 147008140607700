import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  FormButton,
  FormControl,
  FormField,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {i18n} from '../../../i18n/config';
import {ListProjectsApiResponse, ListTenantLicensesApiResponse} from '../../../types';
import {getActiveOptions, getLicenseOptions, getModuleOptions} from '../assignLicenseUtils';
import {AssignLicenseForm} from '../types';

interface AssignLicenceDialogProps extends TestIdProps {
  control: FormControl<AssignLicenseForm>;
  formApi: UseFormReturn<AssignLicenseForm, object>;
  tenantName: string;
  projects?: ListProjectsApiResponse;
  tenantLisenses?: ListTenantLicensesApiResponse;
  defaultValues?: Partial<AssignLicenseForm>;
  isLoading?: boolean;
  edit?: boolean;
}

export function AssignLicenseDialogForm(props: AssignLicenceDialogProps) {
  const projectOptions = useMemo(() => getActiveOptions(props.projects), [props.projects]);

  const {watch, reset, resetField} = props.formApi;

  const watchProject = watch('project');
  const watchModule = watch('module');
  const watchLicense = watch('license');

  const moduleOptions = useMemo(
    () =>
      getModuleOptions({
        projects: props.projects,
        projectId: watchProject,
      }),
    [props.projects, watchProject]
  );

  const licenseOptions = useMemo(
    () =>
      getLicenseOptions({
        projects: props.projects,
        projectId: watchProject,
        moduleId: watchModule,
        tenantLisenses: props?.tenantLisenses,
        editedLicense: props?.defaultValues?.license,
      }),
    [
      props?.defaultValues?.license,
      props.projects,
      props?.tenantLisenses,
      watchModule,
      watchProject,
    ]
  );

  const projectPlaceholder = !projectOptions?.length
    ? i18n.t('tenants.placeholder1')
    : i18n.t('tenants.selectProject');

  const modulePlaceholder = isNil(watchProject)
    ? i18n.t('tenants.placeholder2')
    : !moduleOptions?.length
      ? i18n.t('tenants.placeholder3')
      : i18n.t('tenants.selectProject');

  const licensePlaceholder = isNil(watchProject)
    ? i18n.t('tenants.placeholder4')
    : isNil(watchModule)
      ? i18n.t('tenants.placeholder5')
      : !licenseOptions?.length
        ? i18n.t('tenants.placeholder6')
        : i18n.t('tenants.selectProject');

  return (
    <>
      <VStack spacing={4}>
        <FormField
          data-testid={suffixTestId('project', props)}
          name="project"
          type="choice"
          control={props.control}
          label={i18n.t('common.project')}
          isRequired
          options={projectOptions}
          placeholder={projectPlaceholder}
          onChange={(value) => reset({project: String(value)})}
          menuInPortal
        />
        <FormField
          data-testid={suffixTestId('module', props)}
          name="module"
          type="choice"
          control={props.control}
          label={i18n.t('common.module')}
          isRequired
          isDisabled={isNil(watchProject) ?? true}
          placeholder={modulePlaceholder}
          options={moduleOptions}
          onChange={() => resetField('license')}
          menuInPortal
        />
        <FormField
          data-testid={suffixTestId('license', props)}
          name="license"
          type="choice"
          control={props.control}
          label={i18n.t('common.license')}
          isRequired
          placeholder={licensePlaceholder}
          isDisabled={isNil(watchModule) ?? true}
          options={licenseOptions}
          menuInPortal
        />
        <ButtonGroup align="right" data-testid={suffixTestId('actions', props)}>
          <Button
            data-testid={suffixTestId('discard', props)}
            isDisabled={props.isLoading}
            variant="secondary"
            onClick={closeCurrentDialog}
            title={i18n.t('common.cancel')}
          />
          <FormButton
            data-testid={suffixTestId('submit', props)}
            control={props.control}
            type="submit"
            isLoading={props.isLoading}
            isDisabled={!watchLicense}
            title={i18n.t(props.edit ? 'common.save' : 'common.assign')}
          />
        </ButtonGroup>
      </VStack>
    </>
  );
}
