import {NumberInput} from '../../NumberInput/NumberInput';
import {useFieldProps} from '../hooks/useFieldProps';
import {NumberRendererResponseBody} from '../types/modelSchema';

export function NumberRenderer(props: NumberRendererResponseBody) {
  const {name} = props;
  const {onChange, onBlur, onFocus, value} = useFieldProps(props);

  return (
    <NumberInput
      label={props.label}
      data-testid={name}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || ''}
    />
  );
}
