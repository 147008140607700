import {Action, Actions, DataStatus} from 'platform/components';
import {Box, Heading, HStack, Show, Text} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {suffixTestId, TestIdProps} from 'shared';

interface PageHeaderProps extends TestIdProps {
  title: string;
  isLoading?: boolean;
  description?: string;
  actions?: Action[];
}

export function PageHeader(props: PageHeaderProps) {
  return (
    <DataStatus isLoading={props.isLoading}>
      <Box
        position="fixed"
        top={20}
        left={12}
        right={0}
        borderBottom="1px solid"
        borderColor="general.separator"
        backgroundColor="palettes.white.10.100"
        padding={4}
        data-testid={suffixTestId('pageHeaderContainer', props)}
      >
        <Helmet title={props.title} />
        <HStack align="center" justify="space-between">
          <Heading size={1} data-testid={suffixTestId('pageHeaderTitle', props)}>
            {props.title}
          </Heading>
          <Show when={props.actions?.length}>
            <Actions
              data-testid={suffixTestId('pageHeaderActions', props)}
              actions={props.actions!}
            />
          </Show>
        </HStack>
      </Box>
      <Show when={props.description}>
        <Box width={177} paddingTop={2} paddingBottom={4}>
          <Text size="small" data-testid={suffixTestId('pageHeaderDescription', props)}>
            {props.description}
          </Text>
        </Box>
      </Show>
    </DataStatus>
  );
}
