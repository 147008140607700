import {testIds} from '@omnetic-admin-service/shared';
import {
  closeCurrentDialog,
  DataStatus,
  Form,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import * as Yup from 'yup';

import {useListProjectsQuery, usePostTenantLicenseMutation} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {
  GetTenantApiResponse,
  ListTenantLicensesApiResponse,
  PostTenantLicenseApiArg,
} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';
import {AssignLicenseForm} from '../types';
import {AssignLicenseDialogForm} from './AssignLicenseDialogForm';

interface AssignLicenceDialogProps {
  tenantId: string;
  tenant?: GetTenantApiResponse;
  tenantLisenses?: ListTenantLicensesApiResponse;
}

export function AssignLicenseDialog(props: AssignLicenceDialogProps) {
  const [assignLicense, {isLoading: isAssignLicenceLoading}] = usePostTenantLicenseMutation();
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isError: hasProjectsError,
  } = useListProjectsQuery();

  const onSubmit: FormSubmitHandler<AssignLicenseForm> = async (values) => {
    const args: PostTenantLicenseApiArg = {
      tenantId: props.tenantId,
      tenantAddLicenseRequestBody: {licenseId: values.license},
    };

    await assignLicense(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('tenants.assignLicenseSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isProjectsLoading} isError={hasProjectsError} minHeight={15}>
      <Form onSubmit={onSubmit} schema={schema}>
        {(control, formApi) => (
          <AssignLicenseDialogForm
            data-testid={testIds.tenantDetail('assignLicense')}
            control={control}
            formApi={formApi}
            projects={projects}
            tenantName={props.tenant?.name ?? '-'}
            tenantLisenses={props.tenantLisenses}
            isLoading={isAssignLicenceLoading}
          />
        )}
      </Form>
    </DataStatus>
  );
}

const schema = Yup.object().shape({
  project: Yup.string().required(),
  module: Yup.string().required(),
  license: Yup.string().required(),
});
