import {isNilOrEmpty} from 'ramda-adjunct';

import {OMISSION_STRING} from '../constants/omissionString';

interface SoftTruncateOptions {
  omission: string;
  length: number;
}

const defaultOptions: SoftTruncateOptions = {
  omission: OMISSION_STRING,
  length: 25,
};

/**
 * @description Truncate text while keeping whole words.
 */
export function softTruncate(
  text: string,
  customOptions: Partial<SoftTruncateOptions> = defaultOptions
): string {
  const options = Object.assign({}, defaultOptions, customOptions);

  if (isNilOrEmpty(text)) {
    return '';
  }

  if (text.length <= options.length) {
    return text;
  }

  const replaceRegex = new RegExp(`^(.{${options.length}}\\S*).*`);

  return text.replace(replaceRegex, '$1') + options.omission;
}
