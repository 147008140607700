import {ReactNode, useState} from 'react';

import {isPositive} from 'ramda-adjunct';

import {useDialog} from '../Dialog/useDialog';
import {FormSaver} from './components/FormSaver';
import {
  FormSaverContextProviderProps,
  FormSaverContextProvider,
  FormSaverDialogStorageType,
  FormSaverFormData,
} from './hooks/useFormSaverContext';

export interface FormSaverContextProps extends Partial<FormSaverContextProviderProps> {
  /** Children to render */
  children: ReactNode;
}

export function FormSaverProvider(props: FormSaverContextProps) {
  const [formsData, setFormsData] = useState<Map<string, FormSaverFormData>>(new Map());

  const [isDialogOpen, openDialog, closeDialog, {storage}] =
    useDialog<FormSaverDialogStorageType>(false);

  const {children} = props;

  const registerForm: FormSaverContextProviderProps['registerForm'] = (formId, isDirtyGetter) => {
    setFormsData((prev) => new Map([...prev]).set(formId, {formId, isDirtyGetter}));
  };

  const unRegisterForm: FormSaverContextProviderProps['unRegisterForm'] = (formId) => {
    formsData.delete(formId);
    setFormsData(new Map(formsData));
  };

  const resetForm: FormSaverContextProviderProps['resetForm'] = () => {
    setFormsData(new Map());
  };

  const handleManualPageChange: FormSaverContextProviderProps['handleManualPageChange'] = ({
    callback,
    ...actions
  }) => {
    if (
      !isPositive(formsData.size) ||
      ![...formsData.values()].some((form) => form.isDirtyGetter()) ||
      isDialogOpen
    ) {
      return callback?.();
    }

    openDialog(actions);
  };

  return (
    <FormSaverContextProvider
      value={{
        isDialogOpen,
        openDialog,
        closeDialog,
        formsData,
        registerForm,
        unRegisterForm,
        resetForm,
        handleManualPageChange,
        storage,
      }}
    >
      {children}
      <FormSaver />
    </FormSaverContextProvider>
  );
}
