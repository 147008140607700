import i18n from 'i18next';
import {ComponentsProvider} from 'platform/components';

import {ReactNode} from 'react';

import {defaultTo} from 'ramda';

import {useGetCurrenciesQuery} from '../api/commonApi';

export type DataProviderWrapperProps = {
  children: ReactNode;
};

export const ComponentsProviderWrapper = (props: DataProviderWrapperProps) => {
  const {data: currenciesData} = useGetCurrenciesQuery();

  return (
    <ComponentsProvider
      currencies={defaultTo([], currenciesData)}
      t={i18n.t}
      language={i18n.language}
    >
      {props.children}
    </ComponentsProvider>
  );
};
