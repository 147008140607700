import {Icon} from 'platform/foundation';

import {ReactNode} from 'react';

import {BreadcrumbItem as ChakraBreadcrumbItem} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

export interface BreadcrumbItemProps extends TestIdProps {
  children: ReactNode;
  isCurrentPage?: boolean;
  isLastChild?: boolean;
}

export function BreadcrumbItem(props: BreadcrumbItemProps) {
  return (
    <ChakraBreadcrumbItem
      separator={separator}
      isLastChild={props.isLastChild}
      isCurrentPage={props.isCurrentPage}
      data-testid={suffixTestId('breadcrumbItem', props)}
    >
      {props.children}
    </ChakraBreadcrumbItem>
  );
}

const separator = <Icon value="navigation/arrow_forward_ios" size={3} />;
