import {ThemeIconKey, Icon, Show} from 'platform/foundation';

import {Box} from '@chakra-ui/react';
import {useStyleConfig} from '@chakra-ui/system';

import {suffixTestId, TestIdProps} from 'shared';

export type BadgeVariantType = 'bold' | 'subtle';
export type BadgeSizeType = 'default' | 'small';
export type BadgeColorScheme =
  | 'neutral'
  | 'red'
  | 'magenta'
  | 'purple'
  | 'blue'
  | 'teal'
  | 'green'
  | 'yellow'
  | 'orange';

export type BadgeProps = {
  size?: BadgeSizeType;
  variant?: BadgeVariantType;
  colorScheme?: BadgeColorScheme;
} & (
  | {
      value: string | number;
      icon?: never;
    }
  | {icon: ThemeIconKey; value?: never}
) &
  TestIdProps;

export function Badge(props: BadgeProps) {
  const styles = useStyleConfig('Badge', props);

  return (
    <Box data-testid={suffixTestId('badge', props)} className="eag--badge" sx={styles}>
      <Show when={props.value}>{props.value}</Show>
      <Show when={props.icon}>
        <Icon value={props.icon} />
      </Show>
    </Box>
  );
}
