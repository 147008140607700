export type LocaleDateTimeFormat =
  | 'timeShort'
  | 'timeMedium'
  | 'timeLong'
  | 'timeFull'
  | 'dateShort'
  | 'dateMedium'
  | 'dateLong'
  | 'dateFull'
  | 'dateTimeShort'
  | 'dateTimeMedium'
  | 'dateTimeLong'
  | 'dateTimeFull';
