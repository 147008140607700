import {RefObject, useCallback, useEffect, useRef, useState} from 'react';

import {LightboxConfig} from '../types/LightboxConfig';
import {LightboxSlide} from '../types/LightboxSlide';
import {LightboxState} from '../types/LightboxState';
import {useDeepCompareEffect} from './useDeepCompareEffect';

export interface LightboxApi {
  open: () => void;
  openAt: (slideIndex: number) => () => void;
  close: () => void;
  setState: (state: LightboxState) => void;
  prev: () => void;
  next: () => void;
  goTo: (slideIndex: number) => void;
  play?: () => void;
  pause?: () => void;
  toggleZoom: () => void;
  resetZoom: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
  download: () => Promise<unknown>;
  slides: null | undefined | Array<LightboxSlide>;
  state: LightboxState;
  config: LightboxConfig;
  activeSlide: number;
  refs: {
    body: RefObject<HTMLDivElement>;
    slider: RefObject<HTMLDivElement>;
    thumbnails: RefObject<HTMLDivElement>;
  };
}

type UseLightboxReturnType = {
  open: LightboxApi['open'];
  openAt: LightboxApi['openAt'];
  close: LightboxApi['close'];
  api: LightboxApi;
};

const SCALE_STEP = 1.4;

export function useLightbox(config: LightboxConfig): UseLightboxReturnType {
  const body = useRef<HTMLDivElement>(null);
  const slider = useRef<HTMLDivElement>(null);
  const thumbnails = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<LightboxState>({});
  const [activeSlide, setActiveSlide] = useState(0);
  const [slides, setSlides] = useState<LightboxConfig['data']>(config.data);

  const resetLightbox = () => {
    setSlides(config.data);
    setActiveSlide(0);
    setState({});
    resetZoom();
  };

  useDeepCompareEffect(() => {
    resetLightbox();
  }, [config]);

  const _addClass = (element: Element | null | undefined, ...tokens: string[]) => {
    if (!element) return;

    element.classList.add(...tokens);
  };

  const _removeClass = (element: Element | null | undefined, ...tokens: string[]) => {
    if (!element) return;

    element.classList.remove(...tokens);
  };

  const _hasClass = (element: Element | null | undefined, token: string) => {
    if (!element) return;

    return element.classList.contains(token);
  };

  const _setTransform = (element: HTMLElement | null | undefined, value: string) => {
    if (!element) return;

    element.style.transform = value;
  };

  const _getScale = (element: HTMLElement | null | undefined) => {
    if (!element) return;
    return /scale\((.*?)\)/g.exec(element.style.transform)?.[1];
  };

  const _getSlideElement = (slideIndex: number) => slider.current?.children[slideIndex];

  const _getActiveSlideElement = useCallback(
    () => slider.current?.children[activeSlide],
    [slider, activeSlide]
  );

  const _getAbsoluteIndex = useCallback(
    (slideIndex: number) =>
      slideIndex > (slides?.length ?? 0) - 1
        ? 0
        : slideIndex < 0
          ? (slides?.length ?? 0) - 1
          : slideIndex,
    [slides?.length]
  );

  const _setPrev = (slideIndex: number) => {
    const nextActiveIndex = _getAbsoluteIndex(slideIndex);

    _addClass(slider.current?.children[nextActiveIndex], 'fadeInLeft');
    _addClass(slider.current?.children[activeSlide], 'fadeOutRight');
  };

  const _setNext = (slideIndex: number) => {
    const nextActiveIndex = _getAbsoluteIndex(slideIndex);

    _addClass(slider.current?.children[nextActiveIndex], 'fadeInRight');
    _addClass(slider.current?.children[activeSlide], 'fadeOutLeft');
  };

  const _stopAnimation = useCallback(() => {
    slider.current
      ?.querySelectorAll('.fadeOutRight, .fadeOutLeft, .fadeInLeft, .fadeInRight')
      .forEach((element) => {
        _removeClass(element, 'fadeOutRight', 'fadeOutLeft', 'fadeInLeft', 'fadeInRight');
      });

    _removeClass(slider.current?.querySelector('.isZoomed'), 'isZoomed');
    _setTransform(slider.current?.querySelector('.isZoomed .visual'), '');
  }, []);

  const _setActive = useCallback(
    (slideIndex: number) => {
      const nextActiveIndex = _getAbsoluteIndex(slideIndex);

      setActiveSlide(nextActiveIndex);

      _removeClass(slider.current?.querySelector('.isActive'), 'isActive');
      _addClass(slider.current?.children[nextActiveIndex], 'isActive');
    },
    [_getAbsoluteIndex]
  );

  const _setLoaded = (slideIndex: number) => () => {
    setSlides(slides?.map((slide, i) => (i === slideIndex ? {...slide, loaded: true} : slide)));
  };

  const _preload = (index: number) => {
    const slideIndex = _getAbsoluteIndex(index);

    if (!slides?.[slideIndex]?.loaded) {
      const image = new Image();
      image.addEventListener('load', _setLoaded(slideIndex), true);
      image.src = slides?.[slideIndex]?.src ?? '';
    }
  };

  const goTo = (slideIndex: number) => {
    if (slideIndex === activeSlide) return;
    resetZoom();

    _stopAnimation();
    _preload(slideIndex);
    _setActive(slideIndex);

    if (slideIndex < activeSlide) {
      _setPrev(slideIndex);
    } else {
      _setNext(slideIndex);
    }
  };

  const prev = () => {
    resetZoom();
    if (config.loop || activeSlide !== 0) {
      goTo(activeSlide - 1);
    }
  };

  const next = () => {
    resetZoom();
    if (config.loop || slider.current?.children[activeSlide]?.nextElementSibling) {
      goTo(activeSlide + 1);
    }
  };

  const resetZoom = useCallback(() => {
    const slide = _getActiveSlideElement();
    const image = slide?.querySelector<HTMLElement>('.visual');
    const content = slide?.querySelector<HTMLElement>('.content');

    setState((state) => ({...state, isZoomed: false}));
    _removeClass(_getSlideElement(activeSlide), 'isZoomed');
    _setTransform(content, '');
    _setTransform(image, '');
  }, [_getActiveSlideElement, setState, activeSlide]);

  const close = useCallback(() => {
    // FIXME: T20-17234 Close animation cause random close on first open attempt
    config.onClose?.();
    setState((state) => ({...state, isOpen: false}));
    resetZoom();
  }, [setState, resetZoom, config]);

  const open = useCallback(() => {
    setState((state) => ({...state, isMounted: true, isOpen: true}));
    _setActive(0);
    //TODO: complete reset (by key?)
  }, [setState, _setActive]);

  const openAt = useCallback(
    (slideIndex: number) => () => {
      _setActive(slideIndex);
      _stopAnimation();
      setState((state) => ({...state, isMounted: true, isOpen: true}));
    },
    [_setActive, _stopAnimation, setState]
  );

  const toggleZoom = () => {
    const image = _getActiveSlideElement()?.querySelector<HTMLElement>('.visual');

    if (!_hasClass(_getActiveSlideElement(), 'isZoomed')) {
      setState({...state, isZoomed: true});
      _addClass(_getSlideElement(activeSlide), 'isZoomed');
      _setTransform(image, 'scale(2.5)');
    } else {
      setState({...state, isZoomed: false});
      _removeClass(_getSlideElement(activeSlide), 'isZoomed');
      _setTransform(image, '');
    }
  };

  const zoomIn = () => {
    if (!_hasClass(_getActiveSlideElement(), 'isZoomed')) {
      return toggleZoom();
    }

    const image = _getActiveSlideElement()?.querySelector<HTMLElement>('.visual');
    const scale = _getScale(image);

    if (!scale) {
      _setTransform(image, `scale(${SCALE_STEP})`);
    } else {
      _setTransform(image, `scale(${Number(scale) * SCALE_STEP})`);
    }
  };

  const zoomOut = () => {
    const image = _getActiveSlideElement()?.querySelector<HTMLElement>('.visual');
    const scale = _getScale(image);
    if (!scale) return resetZoom();

    const newScale = Number(scale) * 0.5;
    if (newScale <= SCALE_STEP) return resetZoom();
    _setTransform(image, `scale(${newScale})`);
  };

  const download = async () => {
    const slide = slides?.[activeSlide];
    if (!slide || !slide.original) return;

    // TODO: How to trigger errors from platform and catch in sentry? console.error, onError callback, ...
    const response = await fetch(slide.original, {
      mode: 'cors',
      redirect: 'follow',
    });
    const blob = await response.blob();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = slide.fileName ?? 'photo.jpg';
    a.click();
    URL.revokeObjectURL(a.href);
  };

  useEffect(() => {
    _preload(activeSlide);
    _setActive(activeSlide);
  }, [state.isMounted]);

  useEffect(() => {
    _preload(activeSlide);
  }, [activeSlide]);

  return {
    open,
    openAt,
    close,
    api: {
      open,
      openAt,
      close,
      setState,
      goTo,
      prev,
      next,
      toggleZoom,
      resetZoom,
      zoomIn,
      zoomOut,
      state,
      config,
      slides,
      activeSlide,
      download,
      refs: {
        body,
        slider,
        thumbnails,
      },
    },
  };
}
