import {match, Pattern} from 'ts-pattern';

import {F, T} from 'ramda';

import {Hour} from '../types/Hour';
import {Minute} from '../types/Minute';
import {isHourDisabled} from './isHourDisabled';

/**
 * Checks if given minute is disabled. Returns `true` if minute is inside the disabled range or if hour is disabled/not selected.
 */
export const isMinuteDisabled = (
  hour: Hour | null,
  minute: Minute,
  minHour: Hour,
  minMinute: Minute,
  maxHour: Hour,
  maxMinute: Minute
) => {
  if (!hour || isHourDisabled(hour, minHour, maxHour)) return true;

  return match<[Hour, Minute], boolean>([hour, minute])
    .with(
      [minHour, Pattern.when((m) => m < minMinute)],
      [maxHour, Pattern.when((m) => m > maxMinute)],
      T
    )
    .otherwise(F);
};
