import {environment} from 'environments/platform';
import {FoundationProvider} from 'platform/foundation';
import {Currency, LocaleConfig, LocaleProvider} from 'platform/locale';

import {ReactNode, useMemo} from 'react';

import {mergeDeepRight} from 'ramda';

import {ChakraProvider} from '@chakra-ui/react';

/**
 * Load theme from foundations and extend
 */
import {componentsTheme} from '../../utils/componentsTheme';
import {DialogsProvider} from '../Dialog/components/DialogsProvider';
import {DropdownProvider} from '../Dropdown/components/DropdownProvider';
import {FormSaverProvider} from '../FormSaverProvider/FormSaverProvider';
import {NotificationsProvider} from '../Notification/components/NotificationsProvider';
import {
  TranslationProvider,
  TranslationInterpreter,
} from '../TranslationProvider/TranslationProvider';
import {extendTheme} from './utils/theme';

type ExtendTheme = any;

type ComponentsProviderProps = {
  theme?: Record<string, unknown>;
  children?: ReactNode;
  t?: TranslationInterpreter;
  testMode?: boolean;
  localeConfig?: LocaleConfig;
  language: string;
  currencies?: Currency[];
};

export function ComponentsProvider(props: ComponentsProviderProps) {
  const mergedTheme = useMemo(
    () => mergeDeepRight(componentsTheme, props.theme ?? {}),
    [props.theme]
  );

  const chakraTheme = useMemo(() => {
    const extended = extendTheme<ExtendTheme>({
      ...mergedTheme,
    });

    const {
      components: {Dropdown: Menu, Dialog: Modal, ...components},
      ...restTheme
    } = extended;

    return {
      ...restTheme,
      components: {
        ...components,
        Menu,
        Modal,
      },
    };
  }, [mergedTheme]);

  /**
   * This nasty workaround is there because of an old part of omnetic
   * where in some cases dialogs, dropdowns, selects etc close themselves.
   *
   * Use this throw to forcefully disable closing these components on clicks to overlay etc.
   */
  if (props.testMode) {
    environment.testMode = props.testMode;
  }

  return (
    <FoundationProvider theme={mergedTheme}>
      <ChakraProvider theme={chakraTheme}>
        <TranslationProvider t={props.t}>
          <FormSaverProvider>
            <LocaleProvider
              currencies={props.currencies}
              language={props.language}
              config={props.localeConfig}
            >
              <NotificationsProvider>
                <DialogsProvider>
                  <DropdownProvider>{props.children}</DropdownProvider>
                </DialogsProvider>
              </NotificationsProvider>
            </LocaleProvider>
          </FormSaverProvider>
        </TranslationProvider>
      </ChakraProvider>
    </FoundationProvider>
  );
}
