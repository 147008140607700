import {APPLICATION_NAME} from '@omnetic-admin-service/config';
import {routes} from '@omnetic-admin-service/shared';

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Initialize} from './components/Initialize/Initialize';
import {Layout} from './components/Layout/Layout';
import {Dashboard} from './pages/Dashboard/Dashboard';
import {Login} from './pages/Login/Login';
import {ProjectDetail} from './pages/ProjectDetail/ProjectDetail';
import {ProjectOverview} from './pages/ProjectOverview/ProjectOverview';
import {TenantDetail} from './pages/TenantDetail/TenantDetail';
import {TenantOverview} from './pages/TenantOverview/TenantOverview';

export function App() {
  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s | ${APPLICATION_NAME}`}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Initialize />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path={routes.tenantOverview} element={<TenantOverview />} />
            <Route path={routes.tenantDetail} element={<TenantDetail />} />
            <Route path={routes.projectOverview} element={<ProjectOverview />} />
            <Route path={routes.projectDetail} element={<ProjectDetail />} />
            <Route path="*" element={<Navigate to={routes.loginPage} />} />
          </Route>
        </Route>
      </Routes>
    </HelmetProvider>
  );
}
