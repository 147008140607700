import {css} from 'styled-components';

import {ReactNode} from 'react';

import {Nullish} from 'shared';

import {Integer} from '../../../types/Integer';

export interface GridItemProps {
  span?: Integer | Nullish;
  children?: ReactNode;
}

export function GridItem(props: GridItemProps) {
  return (
    <div
      css={css`
        grid-column-end: ${props.span ? `span ${props.span}` : 'initial'};
        // Prevent content from expanding grid items
        // https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
        min-width: 0;
      `}
    >
      {props.children}
    </div>
  );
}
