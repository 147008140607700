import {match} from 'ts-pattern';

import {always, isNil, omit} from 'ramda';

import {Checkbox, CheckboxProps} from '../../Checkbox/Checkbox';
import {RadioItem, RadioItemProps} from '../../Radio/components/RadioItem';
import {Switch, SwitchProps} from '../../Switch/Switch';
import {Tooltip} from '../../Tooltip/Tooltip';

export type SwitchCardControlProps = {type: 'switch'} & SwitchProps;
export type RadioCardControlProps = {type: 'radio'} & RadioItemProps;
export type CheckboxCardControlProps = {type: 'checkbox'} & CheckboxProps;

export type CardControlProps =
  | RadioCardControlProps
  | SwitchCardControlProps
  | CheckboxCardControlProps;

export function CardControl(props: CardControlProps) {
  if (isNil(props.type)) return null;

  const controlProps = omit(['type'], props);

  const Control = match(props.type)
    .with('switch', always(Switch))
    .with('checkbox', always(Checkbox))
    .with('radio', always(RadioItem))
    .exhaustive();

  return (
    <Tooltip placement="top" label={props.tooltip}>
      <Control {...controlProps} />
    </Tooltip>
  );
}
