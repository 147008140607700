import {Box, Heading, HStack, Show, Text, VStack} from 'platform/foundation';

import {DOT_CHARACTER, suffixTestId, Nullish, TestIdProps} from 'shared';

import {FlagProps} from '../../Flag/Flag';
import {Flags} from '../../Flags/Flags';
import {Parameters, Parameter} from '../../Parameters/Parameters';

export interface CardProps extends TestIdProps {
  title?: string | Nullish;
  subtitle?: string | Nullish;
  parameters?: Parameter[];
  flags?: Omit<FlagProps, 'size'>[];
}

export function CardHeadings(props: CardProps) {
  return (
    <Box minWidth={0} paddingVertical={4}>
      <VStack spacing={1}>
        <HStack spacing={2}>
          <Show when={!!props.title}>
            <Heading size={4} data-testid={suffixTestId('cardHeadingTitle', props)}>
              {props.title}
            </Heading>
          </Show>
          <Show when={!!props.title && !!props.subtitle}>
            <Heading size={4}>{DOT_CHARACTER}</Heading>
          </Show>
          <Show when={!!props.subtitle}>
            <Text size="base" data-testid={suffixTestId('cardHeadingSubtitle', props)}>
              {props.subtitle}
            </Text>
          </Show>
          <Show when={!!props.flags}>
            <Flags flags={props.flags} spacing={2} />
          </Show>
        </HStack>
        <Parameters
          data-testid={suffixTestId('cardHeadingParameters', props)}
          parameters={props.parameters}
          size="small"
          color="secondary"
        />
      </VStack>
    </Box>
  );
}
