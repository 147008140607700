import {isDate} from 'date-fns';

import {parseDate} from 'shared';

import {DatePicker} from '../../DatePicker/DatePicker';
import {useFieldProps} from '../hooks/useFieldProps';
import {DateRendererResponseBody} from '../types/modelSchema';

export function DateRenderer(props: DateRendererResponseBody) {
  const {onChange, value} = useFieldProps(props);
  const {name} = props;

  const date = isDate(value) ? value : parseDate(value);

  return <DatePicker label={props.label} name={name} value={date} onChange={onChange} />;
}
