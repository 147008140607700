import {suffixTestId, TestIdProps} from 'shared';

import {IconButton} from '../IconButton/IconButton';
import {Portal} from '../Portal/components/Portal';
import {LightboxBody} from './components/LightboxBody';
import {LightboxSlider} from './components/LightboxSlider';
import {LightboxThumbnails} from './components/LightboxThumbnails';
import {LightboxToolbar} from './components/LightboxToolbar';
import {LightboxApi} from './hooks/useLightbox';
import {useLightboxEvents} from './hooks/useLightboxEvents';
import {
  LightboxButtons,
  LightboxNextButton,
  LightboxOverlay,
  LightboxPrevButton,
  LightboxTitle,
} from './Lightbox.styled';

export interface ILightboxProps extends TestIdProps {
  api: LightboxApi;
}

export function Lightbox(props: ILightboxProps) {
  const {
    api,
    api: {
      state,
      refs: {slider},
      activeSlide,
    },
    ...rest
  } = props;

  useLightboxEvents(api);

  return (
    <Portal>
      {state.isMounted && (
        <LightboxBody api={api} data-testid={suffixTestId('lightBox', rest)}>
          <LightboxOverlay />
          <LightboxToolbar api={api} data-testid={suffixTestId('lightBox', rest)} />
          <LightboxSlider ref={slider} api={api} data-testid={suffixTestId('lightBox', rest)} />

          <LightboxButtons data-testid={suffixTestId('lightBox', rest)}>
            <LightboxPrevButton data-testid={suffixTestId('lightBox', rest)}>
              <IconButton
                priority="primary"
                icon="navigation/arrow_back"
                onClick={api.prev}
                data-testid={suffixTestId('lightBox', rest)}
              />
            </LightboxPrevButton>
            <LightboxNextButton data-testid={suffixTestId('lightBox', rest)}>
              <IconButton
                priority="primary"
                icon="navigation/arrow_forward"
                onClick={api.next}
                data-testid={suffixTestId('lightBox', rest)}
              />
            </LightboxNextButton>
          </LightboxButtons>

          <LightboxTitle data-testid={suffixTestId('lightBox', rest)}>
            {api.config.data?.[activeSlide]?.title}
          </LightboxTitle>
          <LightboxThumbnails api={api} data-testid={suffixTestId('lightBox', rest)} />
        </LightboxBody>
      )}
    </Portal>
  );
}
