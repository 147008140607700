import {MouseEvent, RefObject, useRef} from 'react';

import {clamp} from 'ramda';

import {LightboxApi} from './useLightbox';

export const useSlideEvents = (
  {prev, next, state, toggleZoom, ...api}: LightboxApi,
  slide: RefObject<HTMLDivElement>,
  content: RefObject<HTMLDivElement>,
  visual: RefObject<HTMLDivElement>
) => {
  const loading = !api.slides?.[api.activeSlide].loaded;
  const currentXOffset = useRef(0);
  const currentYOffset = useRef(0);

  const setTranslate = (ref: RefObject<HTMLDivElement>, translateX: number, translateY: number) => {
    if (!ref.current) return;

    const rectangle = visual.current?.getBoundingClientRect();

    const maxXOffset = rectangle ? rectangle.width / 2 : Number.POSITIVE_INFINITY;
    const maxYOffset = rectangle ? rectangle.height / 2 : Number.POSITIVE_INFINITY;

    const xOffset = clamp(-1 * maxXOffset, maxXOffset, translateX);
    const yOffset = clamp(-1 * maxYOffset, maxYOffset, translateY);

    currentXOffset.current = xOffset;
    currentYOffset.current = yOffset;

    ref.current.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
  };

  const getState = (state: string) => slide.current?.classList.contains(state);

  const onMouseMove = (ev: MouseEvent) => {
    // TODO: Some events temporary disabled - bugs when moving zoomed photo - unintedneed swipe to another slide
    if (!slide.current?.classList.contains('isZoomed')) return;

    if (getState('isMouseDown')) {
      slide.current?.classList.add('isMoving');

      let translateY = currentYOffset.current;
      const translateX = currentXOffset.current + ev.nativeEvent.movementX;

      if (slide.current?.classList.contains('isZoomed')) {
        translateY = currentYOffset.current + ev.nativeEvent.movementY;
      }

      setTranslate(content, translateX, translateY);
    }
  };

  const onMouseDown = (ev: MouseEvent) => {
    ev.preventDefault();
    slide.current?.classList.add('isMouseDown');
    content.current?.classList.remove('isReturning');
  };

  const onMouseUp = (ev: MouseEvent) => {
    ev.preventDefault();
    if (!slide.current?.classList.contains('isMoving') && !loading) {
      if (slide.current?.classList.contains('isZoomed')) {
        setTranslate(content, 0, 0);
      }

      toggleZoom();
    } else if (!slide.current?.classList.contains('isZoomed')) {
      if (currentXOffset.current > 200) {
        prev();
      } else if (currentXOffset.current < -200) {
        next();
      } else {
        content.current?.classList.add('isReturning');
        setTranslate(content, 0, 0);
      }
    }

    slide.current?.classList.remove('isMouseDown');
    slide.current?.classList.remove('isMoving');
  };

  const onMouseLeave = (ev: MouseEvent) => {
    slide.current?.classList.remove('isMouseDown');
    slide.current?.classList.remove('isMoving');
    content.current?.classList.remove('isReturning');

    if (!slide.current?.classList.contains('isZoomed')) {
      setTranslate(content, 0, 0);
    }
  };

  const onAnimationEnd = () => {
    if (!slide.current?.classList.contains('isZoomed')) {
      setTranslate(content, 0, 0);
    }
  };

  return {
    onAnimationEnd,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  };
};
