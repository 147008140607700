import {Text} from 'platform/foundation';

import {useBoolean, suffixTestId, TestIdProps} from 'shared';

import {Dialog} from '../Dialog/Dialog';
import {useTranslationContext} from '../TranslationProvider/TranslationProvider';

export interface ConfirmDialogProps extends TestIdProps {
  isOpen: boolean;
  text?: string;
  isLoading?: boolean;
  id?: string;
  onClose?: () => void;
  /**
   * @about You can return a promise from onConfirm. Dialog
   * will wait for the promise to finish and will close then.
   * Also, cancel/submit buttons will be disabled while
   * the promise is pending.
   * @example onConfirm: async () => await confirmSomething()
   */
  onConfirm: () => void | Promise<unknown>;
  onCloseComplete?: () => void;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const t = useTranslationContext();

  const handleConfirm = async () => {
    startLoading();
    await props.onConfirm();
    stopLoading();
    props.onClose?.();
  };

  return (
    <Dialog
      size="small"
      id={props.id}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onCloseComplete={props.onCloseComplete}
      buttons={[
        {
          variant: 'secondary',
          onClick: props.onClose,
          isDisabled: isLoading || props.isLoading,
          title: t('general.actions.cancel'),
          'data-testid': suffixTestId('confirmDialog-cancel', props),
        },
        {
          variant: 'primary',
          onClick: handleConfirm,
          isLoading: isLoading || props.isLoading,
          title: t('general.actions.confirm'),
          'data-testid': suffixTestId('confirmDialog-confirm', props),
        },
      ]}
      data-testid={suffixTestId('confirmDialog', props)}
    >
      <Text>{props.text ?? t('general.actions.confirmNeutralQuestion')}</Text>
    </Dialog>
  );
}
