import {getPixelsFromRem, Show, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {useEffect, useMemo, useState} from 'react';
import {OnChangeValue} from 'react-select';
import AsyncReactSelect from 'react-select/async';

import {defaultTo, equals, isNil, reject} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {usePrevious} from '@chakra-ui/react';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../../types/FormControlProps';
import {OptionTypeBase} from '../../../types/OptionTypeBase';
import {HelperText} from '../../HelperText/HelperText';
import {Label} from '../../Label/Label';
import {useTranslationContext} from '../../TranslationProvider/TranslationProvider';
import {ChoiceProvider} from '../hooks/choiceContext';
import {ChoiceCommonProps} from '../types';
import {selectComponents} from '../utils/components';
import {formatOptionFieldLabel} from '../utils/formatOptionFieldLabel';
import {reactSelectStyles} from '../utils/reactSelectStyles';

export interface AsyncMultiChoiceProps<ValueType>
  extends FormControlProps<ValueType[] | null>,
    TestIdProps,
    Omit<ChoiceCommonProps<ValueType[], ValueType>, 'options'> {
  defaultOptions?: boolean;
  loadOptions?:
    | ((inputValue: string) => Promise<ReadonlyArray<OptionTypeBase<ValueType | Nullish>>>)
    | undefined;
  loadLabels?:
    | ((values: ValueType[]) => Promise<ReadonlyArray<OptionTypeBase<ValueType | Nullish>>>)
    | undefined;
}

const rejectNil = reject(isNil);

export function AsyncMultiChoice<ValueType>(props: AsyncMultiChoiceProps<ValueType>) {
  const theme = useTheme();
  const t = useTranslationContext();
  // contains options that were loaded in last call of props.loadOptions
  const [loadedOptions, setLoadedOptions] = useState<
    ReadonlyArray<OptionTypeBase<ValueType | Nullish>>
  >([]);
  // stores final options that are sent to AsyncReactSelect as selected values
  const [optionByValue, setOptionByValue] = useState<
    ReadonlyArray<OptionTypeBase<ValueType | Nullish>> | OptionTypeBase<ValueType | Nullish> | null
  >(null);

  // just use props.loadOptions and set loadedOptions
  const finalLoadOptions = (query: string) => {
    if (!props.loadOptions) {
      return;
    }

    return props.loadOptions(query).then((options) => {
      setLoadedOptions(options);
      return options;
    });
  };

  const prevValue = usePrevious(props.value);
  useEffect(() => {
    if (isNotNil(props.value) && !equals(props.value, prevValue)) {
      if (props.loadLabels && props.value.length > 0) {
        props.loadLabels(props.value).then((labels) => {
          setOptionByValue(labels);
        });
      }
      if (props.value.length === 0) {
        setOptionByValue(null);
      }
    }
  }, [props.value, loadedOptions, prevValue, props.loadLabels]);

  // This is a workaround for an issue in react-select, when using multiselect with custom components
  // https://github.com/JedWatson/react-select/issues/5489
  const components = useMemo(() => selectComponents<ValueType, true>(), []);

  const handleChange = (options: OnChangeValue<OptionTypeBase<ValueType | Nullish>, true>) => {
    if (!options) {
      props.onChange?.(null);
      return;
    }

    props.onChange?.(rejectNil(options.map((v) => v.value)));
  };

  const getOptionsByValue = (value: ValueType[] | Nullish) => {
    if (isNil(value)) return null;

    return rejectNil(value.map((v) => loadedOptions?.find((o) => o.value === v)));
  };

  return (
    <VStack>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <ChoiceProvider
        options={{
          'data-testid': props['data-testid'],
          hasOptionCheckbox: props.hasOptionCheckbox,
        }}
      >
        <AsyncReactSelect
          isMulti
          defaultOptions
          loadOptions={props.loadOptions ? finalLoadOptions : undefined}
          value={optionByValue}
          name={props.name}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onChange={handleChange}
          isDisabled={props.isDisabled}
          components={components}
          styles={reactSelectStyles<ValueType, true>(theme)}
          formatOptionLabel={props.formatOptionLabel ?? formatOptionFieldLabel}
          defaultValue={getOptionsByValue(props.defaultValue)}
          menuPortalTarget={props.menuInPortal ? document.body : undefined}
          placeholder={defaultTo(t('general.labels.select'), props.placeholder)}
          isClearable={!props.isNotClearable}
          isSearchable={props.isSearchable ?? true}
          menuPlacement={props.menuPlacement ?? 'auto'}
          maxMenuHeight={getPixelsFromRem(props.maxMenuHeight)}
          noOptionsMessage={props.noOptionsMessage}
          closeMenuOnScroll={props.closeMenuOnScroll}
          closeMenuOnSelect={props.closeMenuOnSelect}
          isLoading={props.isLoading}
          filterOption={props.filterOption}
        />
      </ChoiceProvider>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
