import {ComponentStyleConfig} from '@chakra-ui/react';

/**
 * Configuration:
 * paginationPageNormal
 * paginationPageActive
 */

// eslint-disable-next-line no-restricted-syntax
export const paginationTheme: ComponentStyleConfig = {
  parts: [
    'inputSeparator',
    'inputSegmentQuantity',
    'pageSeparator',
    'pageWrapper',
    'pages',
    'pageGroup',
    'body',
  ],
  variants: {
    default: {
      buttonVariant: 'secondary',
      buttonSize: 'small',
      paginationSize: 'small',
      paginationPageNormal: 'ghost',
      paginationPageActive: 'primary',
      inputVariant: 'default',
      inputSize: 'default',

      body: {
        '> *:not(style) ~ *:not(style)': {
          ms: '4px',
        },
      },

      pages: {
        me: '4px !important',
        ms: '8px !important',
        '> input': {
          w: '64px',
        },
      },

      inputSegmentQuantity: {
        ms: '4px !important',
      },

      pageSeparator: {
        p: '4px',
        svg: {
          width: '16px',
        },
      },

      pageGroup: {
        '> *:not(style) ~ *:not(style)': {
          ms: '4px',
        },
      },
    },
  },
  sizes: {},
  defaultProps: {
    variant: 'default',
  },
} as unknown as ComponentStyleConfig;
