import {AlignType, HStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {getJustifyFromAlign} from '../../utils/getJustifyFromAlign';
import {ButtonAction, ButtonActionProps} from './components/ButtonAction';
import {DropdownAction, DropdownActionProps} from './components/DropdownAction';
import {FormButtonAction, FormButtonActionProps} from './components/FormButtonAction';
import {IconButtonAction, IconButtonActionProps} from './components/IconButtonAction';

export type Action =
  | ButtonActionProps
  | DropdownActionProps
  | IconButtonActionProps
  | FormButtonActionProps;

export interface ActionsProps extends TestIdProps {
  actions: Action[] | Nullish;
  size?: 'small' | 'default';
  align?: AlignType;
}

export function Actions(props: ActionsProps) {
  if (isNilOrEmpty(props.actions)) return null;

  return (
    <HStack
      spacing={2}
      align="center"
      justify={getJustifyFromAlign(props.align)}
      data-testid={props['data-testid']}
    >
      {props.actions?.map((action, key) => {
        const testId = action['data-testid'] ?? `[${key}]`;
        if (action.type === 'button') {
          return (
            <ButtonAction
              key={`buttonAction-[${action.title}]-[${key}]`}
              size={props.size}
              data-testid={suffixTestId(testId, props)}
              {...action}
            />
          );
        }

        if (action.type === 'form-button') {
          return (
            <FormButtonAction
              key={`formButtonAction-[${action.title}]-[${key}]`}
              size={props.size}
              data-testid={suffixTestId(testId, props)}
              {...action}
            />
          );
        }

        if (action.type === 'iconButton') {
          return (
            <IconButtonAction
              key={`iconButtonAction-[${key}]`}
              size={props.size}
              data-testid={suffixTestId(testId, props)}
              {...action}
            />
          );
        }

        return (
          <DropdownAction
            key={`dropdownAction-[${key}]`}
            size={props.size}
            data-testid={suffixTestId(testId, props)}
            {...action}
          />
        );
      })}
    </HStack>
  );
}
