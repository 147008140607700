import {Severity} from 'platform/foundation';

import {ReactElement} from 'react';

import {Nullish} from 'shared';

import {Action} from '../Actions/Actions';

export type WizardStepConfig = {
  title: string;
  content: ReactElement;
  actions?: Action[] | Nullish;
  isDisabled?: boolean | Nullish;
  subtitle?: string | Nullish;
  severity?: Severity | Nullish;
};
