import {useContext} from 'react';

import {Box, Flex} from '@chakra-ui/react';
import {useMultiStyleConfig} from '@chakra-ui/system';

import {suffixTestId, TestIdProps} from 'shared';

import {Button, ButtonVariant} from '../../Button/Button';
import {SEPARATOR} from '../constants/separator';
import {PaginationContext} from '../utils/context';

interface PaginationPageProps extends TestIdProps {
  page: number;
}

export function PaginationPage(props: PaginationPageProps) {
  const {state, actions} = useContext(PaginationContext);

  const {changePage} = actions;
  const {activePage, isDisabled, showPageSeparator, size, styleVariant} = state;

  const isActive = activePage === props.page;
  const isNeedSeparator = props.page === SEPARATOR;

  const pageStyle = useMultiStyleConfig('Pagination', {size, variant: styleVariant});

  const activePageStyle = pageStyle.paginationPageActive as ButtonVariant;
  const normalPageStyle = pageStyle.paginationPageNormal as ButtonVariant;
  const pageSeparatorStyle = pageStyle.pageSeparator;

  if (isNeedSeparator) {
    return (
      <Flex
        as="li"
        sx={pageStyle.pageWrapper}
        data-testid={suffixTestId('paginationPageWrapper', props)}
      >
        <Box __css={pageSeparatorStyle}>{showPageSeparator}</Box>
      </Flex>
    );
  }

  return (
    <Flex
      as="li"
      sx={pageStyle.pageWrapper}
      data-testid={suffixTestId(`paginationPageWrapper`, props)}
    >
      <Button
        key={`button_${props.page}`}
        onClick={!isDisabled ? () => changePage(props.page) : undefined}
        isDisabled={isDisabled}
        variant={isActive ? activePageStyle : normalPageStyle}
        size="small"
        data-testid={suffixTestId('paginationPageButton', props)}
        title={String(props.page)}
      />
    </Flex>
  );
}
