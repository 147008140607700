import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

import {path} from 'ramda';
import {isNotObject} from 'ramda-adjunct';

type ValidationError = {
  field: string;
  message: string;
};

/**
 * @about Parses catched error from RTK Query
 * @returns Validation errors for RHF form
 */
export function getApiValidationErrors(error: FetchBaseQueryError) {
  if (isNotObject(error?.data)) return null;

  const errors = path<ValidationError[]>(['data', 'validationErrors'])(error);

  if (!errors?.length) return null;

  return errors.map((error: ValidationError) => ({
    name: error.field,
    message: error.message,
  }));
}
