import {motion} from 'framer-motion';
import {css, useTheme} from 'styled-components';

import {useEffect} from 'react';

import {isNotNil} from 'ramda';
import {isNilOrEmpty, isNotEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {CSSDimension} from '../../types/CSSDimension';
import {Integer} from '../../types/Integer';
import {ValueByDevice} from '../../types/ValueByDevice';
import {ThemeRadiusPath} from '../../utils/foundationTheme';
import {getCssSize} from '../../utils/getCssSize';
import {Box} from '../Box/Box';
import {Center} from '../Center/Center';
import {Hide} from '../Hide/Hide';
import {Icon} from '../Icon/Icon';
import {Show} from '../Show/Show';
import {Spinner} from '../Spinner/Spinner';
import {SpinnerColor} from '../Spinner/types/SpinnerColor';

export type ImageFit = 'contain' | 'cover' | 'scale-down';

export interface ImageProps extends TestIdProps {
  src: string | Nullish;
  ratio?: number;
  width?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  height?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  maxWidth?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  maxHeight?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  fit?: ImageFit;
  position?: string;
  alt?: string;
  borderRadius?: ThemeRadiusPath;
  isLazy?: boolean;
  hasSpinner?: boolean;
  spinnerColor?: SpinnerColor;
}

export function Image(props: ImageProps) {
  const theme = useTheme();
  const [isImageLoading, setImageLoadingTrue, setImageLoadingFalse] = useBoolean(true);
  const [isImageError, setImageErrorTrue, setImageErrorFalse] = useBoolean(false);

  const width = useResponsivePropValue(props.width);
  const height = useResponsivePropValue(props.height);
  const maxWidth = useResponsivePropValue(props.maxWidth);
  const maxHeight = useResponsivePropValue(props.maxHeight);

  useEffect(() => {
    setImageErrorFalse();
  }, [setImageErrorFalse, props.src]);

  const imageStyle = css`
    width: ${getCssSize(width) ?? '100%'};
    height: ${getCssSize(height) ?? '100%'};
    overflow: hidden;
    object-fit: ${props.fit};
    object-position: ${props.position};
    aspect-ratio: ${props.ratio};
    max-width: ${getCssSize(maxWidth) ?? 'initial'};
    max-height: ${getCssSize(maxHeight) ?? 'initial'};
    border-radius: ${({theme}) => (props.borderRadius ? theme.radii[props.borderRadius] : 0)};
    display: block;
  `;

  return (
    <Box
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      position="relative"
    >
      <Show when={isNotNil(props.src) && isNotEmpty(props.src) && !isImageError}>
        <Hide when={props.isLazy}>
          <img
            onLoad={setImageLoadingFalse}
            onLoadStart={setImageLoadingTrue}
            data-testid={suffixTestId('image', props)}
            src={props.src ?? undefined}
            onError={setImageErrorTrue}
            alt={props.alt}
            css={imageStyle}
          />
        </Hide>
        <Show when={props.isLazy}>
          <Center width="100%" height="100%">
            <motion.img
              onError={setImageErrorTrue}
              onLoad={setImageLoadingFalse}
              onLoadStart={setImageLoadingTrue}
              initial={{filter: 'blur(3px)', opacity: 0.5}}
              animate={{
                filter: isImageLoading ? 'blur(3px)' : 'blur(0px)',
                opacity: isImageLoading ? 0.5 : 1,
              }}
              transition={{
                filter: {duration: 0.3},
                opacity: {duration: 0.2},
              }}
              alt={props.alt}
              src={props.src ?? undefined}
              css={imageStyle}
              loading="lazy"
              data-testid={suffixTestId('animated-image', props)}
            />
          </Center>
        </Show>
      </Show>
      <Show when={isImageError || isNilOrEmpty(props.src)}>
        <Box
          width={width}
          height={height}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          borderRadius={props.borderRadius}
          backgroundColor={theme.components.Image.emptyFill}
          overflow="hidden"
        >
          <div
            css={css`
              aspect-ratio: ${props.ratio};
              width: 100%;
              height: 100%;
            `}
          >
            <Center width="100%" height="100%">
              <Icon color="palettes.neutral.70.100" value="image/photo" size={6} />
            </Center>
          </div>
        </Box>
      </Show>
      <Show when={isImageLoading && props.hasSpinner}>
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          borderRadius={props.borderRadius}
          overflow="hidden"
        >
          <Center width="100%" height="100%">
            <Spinner color={props.spinnerColor} />
          </Center>
        </Box>
      </Show>
    </Box>
  );
}
