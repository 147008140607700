import {Integer, HStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {getRandomId} from '../../utils/getRandomId';
import {Flag, FlagProps} from '../Flag/Flag';

export interface FlagsProps extends Omit<FlagProps, 'label'>, TestIdProps {
  flags?: FlagProps[] | null;
  spacing?: Integer;
}

export function Flags(props: FlagsProps) {
  const {flags, spacing, ...flagOverrides} = props;

  if (!flags?.length) return null;

  return (
    <HStack spacing={spacing ?? 2} data-testid={suffixTestId('flags', props)}>
      {flags.map((flag: FlagProps, index) => (
        <Flag
          {...flag}
          {...flagOverrides}
          key={`${flag.label}-${getRandomId()}`}
          data-testid={suffixTestId(`flag-[${index}]`, props)}
        />
      ))}
    </HStack>
  );
}
