import {createGlobalStyle} from 'styled-components';
import {Normalize} from 'styled-normalize';

const BasicStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  html, #root {
    height: 100%;
  }

  body {
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
    font-family: ${({theme}) => theme.fonts.body};
    font-size: ${({theme}) => theme.fontSizes.text.small};
    font-weight: normal;
    line-height: ${({theme}) => theme.lineHeights.text.base};
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
    overflow-x: hidden;
    overscroll-behavior-y: none;
    font-style: normal;

    @media print {
      background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
    }
	}
`;

export function GlobalStyles() {
  return (
    <>
      <Normalize />
      <BasicStyles />
    </>
  );
}
