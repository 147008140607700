import {ComponentStyleConfig} from '@chakra-ui/react';

// @ts-expect-error Vite rewrite
export const uploadTheme: ComponentStyleConfig = {
  parts: [
    'buttonVariant',
    'buttonSize',
    'card',
    'cardError',
    'cardDisabled',
    'imageVariant',
    'imageSize',
  ],
  baseStyle: {
    buttonVariant: 'secondary',
    buttonSize: 'default',
    card: {
      border: '1px dashed',
      borderColor: 'palettes.neutral.80.100',
      borderRadius: 'xSmall',
      w: '100%',
      h: '100%',
      minW: '108px',
      minH: '108px',
      fontSize: 'text.xSmall',
      lineHeight: 'text.xSmall',
      fontWeight: 'regular',
      letterSpacing: 0,
      bg: 'palettes.white.10.100',
      display: 'flex',

      _hover: {
        color: 'palettes.blue.80.100',
        borderColor: 'palettes.blue.60.100',
        background: 'palettes.blue.10.100',
      },

      svg: {
        w: '24px',
        h: '24px',

        '&[data-iconkey="alert/error"]': {
          color: 'severity.danger',
        },
      },

      '> .rc-upload': {
        flex: '1',
        height: 'auto !important',
      },
    },
    cardError: {
      borderColor: 'palettes.red.60.100',
    },
    cardDisabled: {
      borderColor: 'palettes.neutral.80.40',
      color: 'palettes.neutral.80.40',
      bg: 'palettes.white.10.100',

      _hover: {
        bg: 'palettes.white.10.100',
        borderColor: 'palettes.neutral.80.40',
      },

      '> .rc-upload': {
        cursor: 'not-allowed',
      },
    },
  },
};
