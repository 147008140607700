import {Box, Text} from 'platform/foundation';

import {useContext} from 'react';

import {useTranslationContext} from '../../TranslationProvider/TranslationProvider';
import {PaginationContext} from '../utils/context';

export function TextOnlySegment() {
  const {state} = useContext(PaginationContext);
  const t = useTranslationContext();

  const {activePage, pagesQuantity} = state;

  return (
    <Box paddingHorizontal={1}>
      <Text size="small" noWrap>{`${activePage} ${t('general.labels.of')} ${pagesQuantity}`}</Text>
    </Box>
  );
}
