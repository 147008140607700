import {ComponentStyleConfig} from '@chakra-ui/react';
import {CSSObject} from '@chakra-ui/styled-system';

import {AlertVariants} from './Alert';

export const alertTheme: ComponentStyleConfig = {
  parts: ['container', 'icon', 'title', 'description', 'timeStamp', 'hyperLinks'],
  baseStyle: {
    container: {
      color: 'palettes.neutral.900.100',
      ps: '12px',
      pe: '13px',
      paddingTop: '13px',
      paddingBottom: '13px',
      overflow: 'initial',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    '.eag--alert__close-button-wrapper': {
      top: '13px',
      right: '13px',
    },

    customBorder: {
      p: '13px 14px',
      ps: '14px',
      pe: '14px',
      paddingTop: '13px',
      paddingBottom: '13px',
      border: '--invalid--',
    },
    icon: {
      w: '20px',
      h: '20px',
      mr: '12px',
      svg: {
        w: '100%',
        h: '100%',
      },
    },
    title: {
      fontSize: 'heading.5',
      lineHeight: 'heading.5',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    description: {
      fontSize: 'text.small',
      lineHeight: 'text.small',
      fontWeight: 'regular',
      letterSpacing: 0,
    },
    timeStamp: {
      fontSize: 'text.xSmall',
      lineHeight: 'text.xSmall',
      opacity: 0.75,
    },
    hyperLinks: {
      right: '45px',
      display: 'inline-flex',
      mr: 0,
      '> a, > button': {
        mr: '12px',
        ':last-child': {
          mr: 0,
        },
      },
    },
  },
  variants: {
    inline: (props) => ({
      container: {
        p: '10px 10px 10px 12px',
        ps: '12px',
        pe: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        '.eag--alert__close-button-wrapper': {
          top: '8px',
          right: '8px',
        },
        ...colorSchemeMapping[props.colorScheme as AlertVariants],
      },
      icon: iconSchemeMapping[props.colorScheme as AlertVariants],
      hyperLinks: {
        right: '41px',
      },
    }),
    banner: (props) => ({
      container: {
        borderTop: '1px solid',
        borderRight: '1px solid',
        borderBottom: '1px solid',
        borderLeft: '3px solid transparent',
        _before: {
          position: 'absolute',
          content: '""',
          top: '-1px',
          left: '-3px',
          bottom: '-1px',
          width: '3px',
        },
        ...colorSchemeMapping[props.colorScheme as AlertVariants],
      },
      icon: iconSchemeMapping[props.colorScheme as AlertVariants],
    }),
  },
  defaultProps: {
    variant: 'banner',
    colorScheme: 'warning',
  },
};

const colorSchemeMapping: Record<AlertVariants, CSSObject> = {
  info: {
    bg: 'palettes.blue.10.100',
    borderTopColor: 'palettes.blue.30.100',
    borderRightColor: 'palettes.blue.30.100',
    borderBottomColor: 'palettes.blue.30.100',
    '&::before': {
      bg: 'severity.informational',
    },
    icon: {
      color: 'severity.informational',
    },
  },
  warning: {
    bg: 'palettes.orange.10.100',
    borderTopColor: 'palettes.orange.30.100',
    borderRightColor: 'palettes.orange.30.100',
    borderBottomColor: 'palettes.orange.30.100',
    '&::before': {
      bg: 'severity.warning',
    },
    icon: {
      color: 'severity.warning',
    },
  },
  success: {
    bg: 'palettes.green.10.100',
    borderTopColor: 'palettes.green.30.100',
    borderRightColor: 'palettes.green.30.100',
    borderBottomColor: 'palettes.green.30.100',
    '&::before': {
      bg: 'severity.success',
    },
    icon: {
      color: 'severity.success',
    },
  },
  error: {
    bg: 'palettes.red.10.100',
    borderTopColor: 'palettes.red.30.100',
    borderRightColor: 'palettes.red.30.100',
    borderBottomColor: 'palettes.red.30.100',
    '&::before': {
      bg: 'severity.danger',
    },
    icon: {
      color: 'severity.danger',
    },
  },
};

const iconSchemeMapping: Record<AlertVariants, CSSObject> = {
  info: {
    color: 'severity.informational',
  },
  warning: {
    color: 'severity.warning',
  },
  success: {
    color: 'severity.success',
  },
  error: {
    color: 'severity.danger',
  },
};
