import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  IconButton,
  TextInput,
  copyToClipboard,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {i18n} from '../../../i18n/config';
import {ResetHelpDeskPasswordResponseBody} from '../../../types';

interface ShowGeneratedPasswordDialogProps extends ResetHelpDeskPasswordResponseBody, TestIdProps {}

export function ShowGeneratedPasswordDialog(props: ShowGeneratedPasswordDialogProps) {
  return (
    <VStack spacing={4} width="100%">
      <HStack spacing={4}>
        <Box flex={6}>
          <TextInput
            value={props.password}
            isDisabled
            helperText={i18n.t('tenants.newPasswordDescription')}
          />
        </Box>
        <Box flex={1}>
          <IconButton icon="content/copy" onClick={() => copyToClipboard(props.password)} />
        </Box>
      </HStack>
      <ButtonGroup align="right" data-testid={suffixTestId('actions', props)}>
        <Button
          data-testid={suffixTestId('close', props)}
          variant="secondary"
          onClick={closeCurrentDialog}
          title={i18n.t('common.close')}
        />
      </ButtonGroup>
    </VStack>
  );
}
