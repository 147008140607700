import {ThemeIconKey, Icon, Show, Text, VStack} from 'platform/foundation';
import RcUpload from 'rc-upload';
import {
  Action,
  RcFile,
  UploadProps as RcUploadProps,
  UploadRequestMethod,
  UploadRequestOption,
} from 'rc-upload/es/interface';

import {useEffect, useState} from 'react';

import {map, split, trim} from 'ramda';

import {Box} from '@chakra-ui/layout';
import {Center, Flex} from '@chakra-ui/react';
import {chakra, useMultiStyleConfig} from '@chakra-ui/system';

import {suffixTestId, TestIdProps} from 'shared';

import {Button, ButtonVariant} from '../Button/Button';
import {ProgressBar} from '../ProgressBar/ProgressBar';
import {useTranslationContext} from '../TranslationProvider/TranslationProvider';

export enum UploadState {
  Idle = 'idle',
  Uploading = 'uploading',
  Success = 'success',
  Error = 'error',
}

export type UploadType = 'button' | 'card';
export type UploadButtonVariant = 'secondary' | 'ghostLink' | 'errorLink';
export type OnUploadError = NonNullable<RcUploadProps['onError']>;
export type OnUploadSuccess = NonNullable<RcUploadProps['onSuccess']>;
export type OnBeforeUpload = NonNullable<RcUploadProps['beforeUpload']>;
export type CustomRequestOption = UploadRequestOption;

export class UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField extends Error {}

export type File = {
  name: string;
  status: UploadState;
  url: string;
};

export interface UploadProps<T extends UploadType = UploadType> extends TestIdProps {
  /**
   * @about
   * Uploading URL
   */
  action?: Action;
  /**
   * @about
   * Request method
   */
  method?: UploadRequestMethod;
  /**
   * @about
   * File types that can be accepted
   */
  accept?: string;
  /**
   * @about
   * If set true, component will validate file types according to accept property
   * before upload, and if file is not of appropriate file type, it will reject it
   * */
  shouldValidateAccept?: boolean;
  /**
   * @about
   * Whether to support selected multiple file.
   */
  isMultiple?: boolean;
  /**
   * @about
   * The file upload will be disabled.
   */
  isDisabled?: boolean;
  /**
   * @about
   * The current state of upload ("Idle" | "Uploading" | "Success" | "Error").
   */
  uploadState?: UploadState;
  /**
   * @about
   * The type of upload ("button" | "card").
   */
  type?: T;
  uploadText?: string;
  uploadingText?: string;
  name?: string;
  uploadIcon?: ThemeIconKey;
  uploadingIcon?: ThemeIconKey;
  errorIcon?: ThemeIconKey;
  buttonVariant?: T extends 'button' ? UploadButtonVariant : never;
  /**
   * @about
   * Provide an override for the default xhr behavior for additional customization
   */
  customRequest?: (option: CustomRequestOption) => void;
  onStart?: (file: RcFile) => void;
  onError?: OnUploadError;
  onSuccess?: OnUploadSuccess;
  beforeUpload?: OnBeforeUpload;
}

export function Upload<T extends UploadType = 'card'>({
  uploadingIcon = 'action/watch_later',
  uploadIcon = 'file/upload',
  errorIcon = 'alert/error',
  buttonVariant,
  ...props
}: UploadProps<T>) {
  const t = useTranslationContext();

  const styles = useMultiStyleConfig('Upload', props);
  const [internalUploadState, setInternalUploadState] = useState<UploadState>(UploadState.Idle);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const uploadText = props.uploadText ?? t('general.actions.upload');
  const uploadingText = props.uploadingText ?? t('general.labels.uploading');

  useEffect(() => {
    if (props.uploadState) setInternalUploadState(props.uploadState);
  }, [props.uploadState]);

  const hoverStyles = isMouseOver ? styles.cardHover : {};

  const errorStyles = internalUploadState === UploadState.Error ? styles.cardError : {};

  const disabledStyles = props.isDisabled ? styles.cardDisabled : {};

  const cardStyles = {
    ...styles.card,
    ...errorStyles,
    ...disabledStyles,
    ...hoverStyles,
  };

  const handleOnStart = (file: RcFile) => {
    setInternalUploadState(UploadState.Uploading);
    props.onStart?.(file);
  };

  const handleOnSuccess: RcUploadProps['onSuccess'] = (response, file, xhr) => {
    props.onSuccess?.(response, file, xhr);
    setInternalUploadState(UploadState.Success);
  };

  const handleOnError: RcUploadProps['onError'] = (error, ret, file) => {
    props.onError?.(error, ret, file);
    setInternalUploadState(UploadState.Error);
  };

  const handleValidateByAccept = (file: RcFile, files: RcFile[]) =>
    validateFileByAccept(props.accept, handleOnError, file)
      ? props.beforeUpload?.(file, files) || file
      : false;

  const beforeUpload = !props.shouldValidateAccept ? props.beforeUpload : handleValidateByAccept;

  const isUploading = internalUploadState === UploadState.Uploading;

  const button = (
    <Button
      variant={buttonVariant as ButtonVariant}
      leftIcon={isUploading ? uploadingIcon : uploadIcon}
      isDisabled={props.isDisabled || isUploading}
      data-testid={suffixTestId('uploadButton', props)}
      title={isUploading ? uploadingText : uploadText}
    />
  );

  const card = () => {
    if (isUploading) {
      return (
        <>
          <Box></Box>
          <Center>{uploadingText}</Center>
          <ProgressBar indeterminate small />
        </>
      );
    }

    if (internalUploadState === UploadState.Error && !isMouseOver) {
      return (
        <Flex direction="column" align="center">
          <IconWrapper data-testid={suffixTestId('uploadIconErrorWrapper', props)}>
            <Icon value={errorIcon} />
          </IconWrapper>
          <Box marginTop="10px">{props.name}</Box>
        </Flex>
      );
    }

    return (
      <>
        {uploadIcon ? (
          <VStack align="center" spacing={2}>
            <IconWrapper data-testid={suffixTestId('iconUploadWrapper', props)}>
              <Icon value={uploadIcon} />
            </IconWrapper>

            <Show when={uploadText}>
              <Text size="small" alternative>
                {uploadText}
              </Text>
            </Show>
          </VStack>
        ) : (
          uploadText
        )}
      </>
    );
  };

  const handleMouseIn = () => setIsMouseOver(true);
  const handleOnDragLeave = () => setIsMouseOver(false);

  const isCard = props.type === 'card';
  const isButton = props.type === 'button';

  return (
    <Box
      sx={isCard ? cardStyles : undefined}
      onDragEnter={handleMouseIn}
      onMouseEnter={handleMouseIn}
      onDragLeave={handleOnDragLeave}
      onMouseLeave={handleOnDragLeave}
      data-testid={suffixTestId('uploadWrapper', props)}
    >
      <RcUpload
        style={
          isCard
            ? {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent:
                  props.uploadState === UploadState.Uploading ? 'space-between' : 'center',
                height: '100%',
                width: '100%',
                padding: '8px',
                cursor: props.isDisabled ? 'not-allowed' : 'pointer',
              }
            : {}
        }
        action={props.action}
        method={props.method}
        accept={props.accept}
        multiple={props.isMultiple}
        customRequest={props.customRequest}
        onStart={handleOnStart}
        onSuccess={handleOnSuccess}
        onError={handleOnError}
        beforeUpload={beforeUpload}
        disabled={props.isDisabled}
        data-testid={suffixTestId('rcUpload', props)}
      >
        {isButton ? button : card()}
      </RcUpload>
    </Box>
  );
}

const validateFileByAccept = (
  accept: string | undefined,
  onError: OnUploadError | undefined,
  file: RcFile
) => {
  if (!accept) return file;

  const acceptedFileTypes = map(trim, split(',', accept));
  if (acceptedFileTypes.includes(file.type)) return file;

  onError?.(new UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField(), {}, file);

  // Stops upload.
  return false;
};

const IconWrapper = chakra('div', {
  baseStyle: {
    width: '24px',
    height: '24px',
  },
});
