import {SliderTick} from '../types';
import {getNonlinearTickValues} from './getNonlinearTickValues';
import {toPercentage} from './toPercentage';

type getNonlinearValueArgs = {
  min: number;
  max: number;
  clickOffset: number;
  trackWidth: number;
  nonlinearTicks: SliderTick[];
};

/**
 * @description
 * Calculate value for non-linear ticks, by position on track
 *
 * @param {number} param.min - min value for slider
 * @param {number} param.max - max value for slider
 * @param {number} param.clickOffset - Position from left on slider track
 * @param {number} param.trackWidth - width of slider track
 * @param {SliderTick[]} param.nonlinearTicks - Non-linear ticks passed as param in Slider
 *
 * @example
 * // return 5
 * getNonlinearValue({
 *   min: 0,
 *   max: 10,
 *   clickOffset: 750,
 *   trackWidth: 1000,
 *   nonlinearTicks: [{value: 1}, {value: 2}, {value: 5}],
 * })
 */

export const getNonlinearValue = ({
  min,
  max,
  clickOffset,
  trackWidth,
  nonlinearTicks,
}: getNonlinearValueArgs) => {
  if (clickOffset <= 0) return min;
  if (clickOffset >= trackWidth) return max;

  const tickValues = getNonlinearTickValues({min, max, nonlinearTicks});

  // get index of used step
  const stepSize = trackWidth / (tickValues.length - 1);
  const stepIndex = Math.floor(clickOffset / stepSize);

  // calculate range in selected step
  const stepFrom = tickValues[stepIndex];
  const stepTo = tickValues[stepIndex + 1];

  const stepRange = stepTo - stepFrom;

  // calculate value by percentage in selected step
  const positionValue =
    stepRange * (toPercentage(stepIndex * stepSize, (stepIndex + 1) * stepSize, clickOffset) / 100);

  return tickValues[stepIndex] + positionValue;
};
