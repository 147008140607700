import styled from 'styled-components';

import {MouseEventHandler} from 'react';

import {LightboxItem} from '../types/LightboxItem';

export interface LightboxItemProps extends LightboxItem {
  src: string;
  title?: string;
  current?: boolean;
  goToSlide: MouseEventHandler;
}

export const LightboxThumbnailStyled = styled.div`
  opacity: 0.6;
  min-width: 5rem;

  img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    object-position: center;
    pointer-events: 'none';
  }

  &:hover {
    opacity: 0.8;
  }

  &.isActive,
  &.isActive:hover {
    opacity: 1;
  }
`;

export function LightboxThumbnail({src, title, current, goToSlide}: LightboxItemProps) {
  return (
    <LightboxThumbnailStyled className={current ? 'isActive' : undefined} onClick={goToSlide}>
      <img src={src} alt={title} />
    </LightboxThumbnailStyled>
  );
}
