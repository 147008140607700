import {AsYouType} from 'libphonenumber-js';
import {Icon, Show, Space, VStack} from 'platform/foundation';

import {Fragment, useState, useMemo, useRef, useId} from 'react';

import {chakra, useMultiStyleConfig} from '@chakra-ui/system';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {DropdownDivider} from '../Dropdown/components/DropdownDivider';
import {DropdownItem} from '../Dropdown/components/DropdownItem';
import {Dropdown} from '../Dropdown/Dropdown';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {PlatformInputElement} from '../TextInput/components/PlatformInputElement';
import {PlatformInputWrapper} from '../TextInput/components/PlatformInputWrapper';
import {dialCodePrefix, flagIcons, FlagIcons} from './constants';
import {Country} from './types/Country';
import {PhoneNumber} from './types/PhoneNumber';
import {
  Alpha3CountryCode,
  getAlpha2CodeByAlpha3code,
  getAlpha3CodeByAlpha2code,
} from './utils/countryCodes';
import {getCountryCodeFromNumber} from './utils/getCountryCodeFromNumber';
import {getDefaultCountryCode} from './utils/getDefaultCountryCode';
import {getDefaultInnerValue} from './utils/getDefaultInnerValue';
import {getFormattedNumber} from './utils/getFormattedNumber';
import {sortCountries} from './utils/sortCountries';

export interface PhoneInputProps
  extends FormControlProps<PhoneNumber, HTMLInputElement, [string]>,
    TestIdProps {
  /**
   * @about
   * The default country code of default selected country.
   */
  defaultCountry?: Alpha3CountryCode;
  /**
   * @about
   * Preferred countries will be displayed at the top of dropdown with countries.
   */
  preferredCountries?: Alpha3CountryCode[];
  /**
   * @about
   * The countries data that contains name, country code and country dialing code about each country.
   */
  countries?: Country[];
  placeholder?: string;
  /**
   * @about
   * Set `true` for "national" phone number format.
   * Leave `undefined` for "international" phone number format.
   * By default it's international.
   */
  isNational?: true;
  label?: string | Nullish;
  /**
   * @about
   * The custom icons for country flags.
   */
  flagIcons?: FlagIcons;
  isRecommended?: boolean;
}

export function PhoneInput(props: PhoneInputProps) {
  const id = useId();
  const inputRef = useRef<HTMLInputElement>(null);

  const [innerCountry, setInnerCountry] = useState<Alpha3CountryCode>(
    () =>
      props.value?.countryCode ??
      getDefaultCountryCode(props.defaultCountry, props.preferredCountries, props.countries)
  );

  const [innerPhoneNumber, setInnerPhoneNumber] = useState<string>(() =>
    getDefaultInnerValue(innerCountry, props.value, props.isNational)
  );

  const countriesData = useMemo(
    (): Country[] | undefined => sortCountries(props.preferredCountries, props.countries),
    [props.countries, props.preferredCountries]
  );

  const {
    dropdownControl: dropdownControlStyles,
    dropdownControlFlag: dropdownControlFlagStyles,
    dropdownControlArrowIcon: dropdownControlArrowIconStyles,
  } = useMultiStyleConfig('Phone', props);

  const getFlagIcon = (countryCode: Alpha3CountryCode) => {
    const flagIcon = (props.flagIcons ?? flagIcons)?.[countryCode];
    if (!flagIcon) {
      return countryCode;
    }

    return <Icon value={flagIcon} />;
  };

  const onValueChange = (value: string | null) => {
    const countryCode = getCountryCodeFromNumber(value ?? '', countriesData) ?? innerCountry;
    const formattedNumber = getFormattedNumber(value ?? '', countryCode);

    setInnerValues(countryCode, formattedNumber);

    if (!value) return;

    const oldIndex = inputRef.current?.selectionStart ?? 0;
    const isCursorAtTheEnd = value.length <= oldIndex;
    const oldLength = value.substring(0, oldIndex).length;

    // This moves the cursor from the end to the correct position
    window.requestAnimationFrame(() => {
      const newLength = formattedNumber.substring(0, oldIndex).length;
      const newIndex = isCursorAtTheEnd
        ? formattedNumber.length + 1
        : Math.max(0, newLength - oldLength + oldIndex, oldIndex);

      inputRef?.current?.setSelectionRange(newIndex, newIndex);
    });
  };

  const onSelectCountry = (country: Country) => {
    const newInnerValue = props.isNational
      ? props.value.number
      : country.dialCode + props.value.number;

    const formattedNumber = getFormattedNumber(newInnerValue, country.countryCode);

    setInnerValues(country.countryCode, formattedNumber);
  };

  const provideChanges = (phoneNumber: string, country: Alpha3CountryCode) => {
    if (!props.onChange) return;

    const alpha2Code = getAlpha2CodeByAlpha3code(country);
    const asYouType = new AsYouType(alpha2Code);
    asYouType.input(phoneNumber);

    const number = asYouType.getNumber();
    const countryCode = number?.country ? getAlpha3CodeByAlpha2code(number.country) : country;

    props.onChange?.(
      {
        countryCode,
        number: number?.nationalNumber.toString() ?? '',
        prefix: number ? `${dialCodePrefix}${number.countryCallingCode}` : phoneNumber,
      },
      undefined,
      number?.number.toString() ?? phoneNumber
    );
  };

  const setInnerValues = (countryCode: Alpha3CountryCode, phoneNumber: string) => {
    setInnerCountry(countryCode);
    setInnerPhoneNumber(phoneNumber);
    provideChanges(phoneNumber, countryCode);
  };

  const isInvalid = props.isInvalid || !!props.errorMessage;

  return (
    <VStack>
      <Label
        id={id}
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        isRecommended={props.isRecommended}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <PlatformInputWrapper
        isDisabled={props.isDisabled}
        isInvalid={isInvalid}
        isRecommended={props.isRecommended}
      >
        <>
          <Show when={countriesData}>
            <Dropdown
              isHeightLimited
              isOpen={props.isDisabled ? false : undefined}
              dropdownControl={
                <chakra.div
                  __css={dropdownControlStyles}
                  data-disabled={props.isDisabled ? '' : null}
                >
                  <chakra.span __css={dropdownControlFlagStyles}>
                    {getFlagIcon(innerCountry)}
                  </chakra.span>
                  <chakra.span __css={dropdownControlArrowIconStyles}>
                    <Icon value="navigation/arrow_drop_down" size={4} />
                  </chakra.span>
                </chakra.div>
              }
              data-testid={suffixTestId('phoneDropdown', props)}
            >
              {countriesData?.map((country, index) => (
                <Fragment key={country.countryCode}>
                  {index === props.preferredCountries?.length && <DropdownDivider />}
                  <DropdownItem
                    onClick={() => onSelectCountry(country)}
                    prefix={getFlagIcon(country.countryCode)}
                    label={country.name}
                    data-testid={suffixTestId('phoneDropdown', props)}
                  />
                </Fragment>
              ))}
            </Dropdown>
          </Show>
          <Space horizontal={2} />
          <PlatformInputElement
            autoComplete="disabled"
            ref={inputRef}
            id={id}
            isInvalid={isInvalid}
            isRequired={props.isRequired}
            isDisabled={!!props.isDisabled}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={onValueChange}
            value={innerPhoneNumber}
            data-testid={suffixTestId('phoneInput', props)}
          />
        </>
      </PlatformInputWrapper>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
