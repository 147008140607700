import {ThemeIconKey} from 'platform/foundation';

import {ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {DropdownActionProps} from '../Actions/components/DropdownAction';
import {IconButtonActionProps} from '../Actions/components/IconButtonAction';
import {BadgeProps} from '../Badge/Badge';

export type MenuBadge = Pick<BadgeProps, 'colorScheme' | 'value' | 'icon'>;
export type MenuAction =
  | Omit<DropdownActionProps & {type: 'dropdown-iconButton'}, 'size'>
  | Omit<IconButtonActionProps, 'size'>;
export interface MenuGroup extends TestIdProps {
  id: string;
  label: string;
  items: MenuItem[];
  leftIcon?: ThemeIconKey;
  actions?: MenuAction[];
  hasSeparator?: boolean;
  badge?: MenuBadge;
  tooltip?: ReactNode;
}

export interface MenuLink extends TestIdProps {
  id: string;
  label: string;
  isDisabled?: boolean;
  leftIcon?: ThemeIconKey;
  rightIcon?: ThemeIconKey;
  actions?: MenuAction[];
  hasSeparator?: boolean;
  badge?: MenuBadge;
  tooltip?: ReactNode;
}

export type MenuItem = MenuGroup | MenuLink;
