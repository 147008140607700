import {getCssSize, CSSDimension, Integer, Box, HStack, Right, Show} from 'platform/foundation';
import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

import {IconButton} from '../../IconButton/IconButton';
import {Tooltip} from '../../Tooltip/Tooltip';
import {useTableContext} from '../context';
import {TableRowAction} from '../types/TableRowAction';
import {TableVariant} from '../types/TableVariant';
import {TableRowActionsDropdown} from './TableRowActionsDropdown';

export interface TableRowActionsData {
  primary?: TableRowAction[];
  secondary?: TableRowAction[];
}

interface TableRowActionsProps extends TestIdProps {
  actions?: TableRowActionsData;
  actionsWidth?: CSSDimension | Integer;
}

export function TableRowActions(props: TableRowActionsProps) {
  const context = useTableContext();

  if (!props.actions?.primary?.length && !props.actions?.secondary?.length) {
    return <TDActionsStyled variant={context.variant} actionsWidth={props.actionsWidth} />;
  }

  return (
    <TDActionsStyled variant={context.variant} actionsWidth={props.actionsWidth}>
      <Box paddingHorizontal={2} paddingVertical={1}>
        <Right>
          <HStack data-testid={suffixTestId('actions', props)}>
            {props.actions.primary?.map((primaryAction, index) => (
              <Tooltip
                key={primaryAction.title}
                isDisabled={!primaryAction.title}
                label={primaryAction.title}
                description={primaryAction.description}
                placement="top-start"
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    primaryAction.onClick();
                  }}
                  icon={primaryAction.icon}
                  isDisabled={primaryAction.isDisabled}
                  severity={primaryAction.severity}
                  data-testid={suffixTestId(`[${index}]`, props)}
                />
              </Tooltip>
            ))}

            <Show when={!!props.actions.secondary?.length}>
              <TableRowActionsDropdown
                actions={props.actions.secondary}
                data-testid={props['data-testid']}
              />
            </Show>
          </HStack>
        </Right>
      </Box>
    </TDActionsStyled>
  );
}

export const TDActionsStyled = styled.td<{
  actionsWidth: TableRowActionsProps['actionsWidth'];
  variant: TableVariant;
}>`
  width: ${(props) => getCssSize(props.actionsWidth) ?? '1px'};
  min-height: 2.688rem;
  height: 2.688rem;
  border-left: ${(props) =>
    props.variant === 'bordered' ? `1px solid ${props.theme.colors.general.separator}` : undefined};
`;
