import {createContext} from 'react';

import {defaultProps} from '../constants/defaultProps';
import {PaginationProperties} from '../types/PaginationProperties';

export type PaginationContextValues = {
  state: PaginationProperties & {activePage: number};
  actions: {
    changePage: (page: number) => void;
    setFirstPage: () => void;
    setPrevPage: () => void;
    setNextPage: () => void;
    setLastPage: () => void;
  };
};

export const PaginationContext = createContext<PaginationContextValues>({
  state: defaultProps,
  actions: {
    changePage: () => null,
    setFirstPage: () => null,
    setPrevPage: () => null,
    setNextPage: () => null,
    setLastPage: () => null,
  },
});
