import {IconButton, useAnimatedPopper, Card} from 'platform/components';
import {Box, Text, Clickable, Heading, Space, HStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {selectQuickInfo} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';

export function StatsIconButton() {
  const {data} = useSelector(selectQuickInfo);

  const {openPopper, referenceRef, popperProps, Popper, closePopper} = useAnimatedPopper({
    placement: 'bottom-end',
    gutter: 10,
  });

  return (
    <Clickable
      onMouseEnter={openPopper}
      onMouseLeave={closePopper}
      data-testid="layout-header-statsPopup-container"
    >
      <div ref={referenceRef}>
        <IconButton data-testid="layout-header-statsPopup" icon="editor/show_chart" />
      </div>
      <Popper {...popperProps}>
        <Card data-testid="layout-header-statsPopup">
          <Box minWidth={45}>
            <HStack align="center" justify="space-between">
              <Heading headingLevel={5} size={5}>
                {`${i18n.t('common.activeTenants')}:`}
              </Heading>
              <Text size="small" data-testid="layout-header-statsPopup-activeTenants">
                {data ? String(data.activeTenantsCount) : '0'}
              </Text>
            </HStack>
            <Space vertical={2} />
            <HStack align="center" justify="space-between">
              <Heading size={5}>{`${i18n.t('common.activeProjects')}:`}</Heading>
              <Text size="small" data-testid="layout-header-statsPopup-activeProjects">
                {data ? String(data.activeProjectsCount) : '0'}
              </Text>
            </HStack>
          </Box>
        </Card>
      </Popper>
    </Clickable>
  );
}
