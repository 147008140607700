import {browserStorageKey} from '@omnetic-admin-service/config';
import {environment} from '@omnetic-admin-service/environment';
import {routes} from '@omnetic-admin-service/shared';
import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query';

import {publicApi} from '../publicApi';

const getArgsWithAuthorization = (args: FetchArgs) => {
  const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);

  if (!accessToken) return args;

  return {
    ...args,
    headers: {
      ...args.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const redirectToLoginPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(browserStorageKey.REDIRECT_LINK);

  const query = searchParams.toString();

  const redirectUrl = `${window.location.pathname}${query ? `?${query}` : ''}${
    window.location.hash
  }`;

  sessionStorage.setItem(browserStorageKey.REDIRECT_LINK, redirectUrl);

  return `${routes.loginPage}`;
};

const fetchBaseQueryInstance = fetchBaseQuery({
  baseUrl: localStorage.getItem('BE_URL') || environment.API_URL,
});

export const adminBaseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await fetchBaseQueryInstance(getArgsWithAuthorization(args), api, extraOptions);

  // @ts-ignore
  if (
    result?.error?.status === 401 ||
    (result?.error?.status === 500 &&
      // @ts-ignore
      result?.error?.data?.error?.message?.includes('ExpiredException: Expired token'))
  ) {
    if (window.location.pathname.includes('/login')) {
      return result;
    }

    const getRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);

    if (!getRefreshToken) {
      window.location.href = `${window.location.origin}${redirectToLoginPage()}`;
      return result;
    }

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(browserStorageKey.REDIRECT_LINK);

    await api
      .dispatch(
        publicApi.endpoints.refreshToken.initiate({
          refreshTokenRequestBody: {refreshToken: getRefreshToken},
        })
      )
      .unwrap()
      .then((refreshResponse) => {
        if (refreshResponse.refreshToken && refreshResponse.token) {
          sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, refreshResponse.token);
          localStorage.setItem(browserStorageKey.REFRESH_TOKEN, refreshResponse.refreshToken);
        }
      })
      .catch(() => {
        sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
        localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
        window.location.href = `${window.location.origin}${redirectToLoginPage()}`;
      });

    result = await fetchBaseQueryInstance(getArgsWithAuthorization(args), api, extraOptions);
  }
  return result;
};
