import {createApi} from '@reduxjs/toolkit/query/react';

import {
  GetAlphaCatalogueApiArg,
  GetAlphaCatalogueApiResponse,
  GetBankListApiArg,
  GetBankListApiResponse,
  GetCountriesApiArg,
  GetCountriesApiResponse,
  GetCurrenciesApiArg,
  GetCurrenciesApiResponse,
  GetGendersApiArg,
  GetGendersApiResponse,
  GetIdentityCardTypesApiResponse,
  GetIdentityCardTypesArg,
  GetPhoneNumbersApiArg,
  GetPhoneNumbersApiResponse,
  GetPrioritizedCountriesApiArg,
  GetRoundTypeApiArg,
  GetRoundTypeApiResponse,
  GetVatRatesApiArg,
  GetVatRatesApiResponse,
} from '../types';
import {adminBaseQuery} from './baseQuery/baseQuery';

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: adminBaseQuery,
  keepUnusedDataFor: ONE_DAY_IN_SECONDS,
  endpoints: (build) => ({
    getAlphaCatalogue: build.query<GetAlphaCatalogueApiResponse, GetAlphaCatalogueApiArg>({
      query: (queryArg) => ({
        method: 'GET',
        url: `/vehicle-catalogue/api/enum`,
        params: {lang: queryArg.lang},
      }),
    }),
    getPhoneNumbers: build.query<GetPhoneNumbersApiResponse, GetPhoneNumbersApiArg>({
      query: () => ({
        url: `/common/v1/phone-numbers`,
      }),
    }),
    getCountries: build.query<GetCountriesApiResponse, GetCountriesApiArg>({
      query: () => ({
        url: `/common/v1/countries`,
      }),
    }),
    getPrioritizedCountries: build.query<GetCountriesApiResponse, GetPrioritizedCountriesApiArg>({
      query: (queryArgs: GetPrioritizedCountriesApiArg) => ({
        url: `/common/v1/countries`,
        params: {countryCode: queryArgs.countryCode},
      }),
    }),
    getCurrencies: build.query<GetCurrenciesApiResponse, GetCurrenciesApiArg>({
      query: () => ({
        url: `/common/v1/currencies`,
      }),
    }),
    getGenders: build.query<GetGendersApiResponse, GetGendersApiArg>({
      query: () => ({url: `/common/v1/genders`}),
    }),
    getBankList: build.query<GetBankListApiResponse, GetBankListApiArg>({
      query: (queryArg) => ({
        url: `/common/v2/bank-list`,
        params: {countryCode: queryArg.countryCode},
      }),
    }),
    getIdentityCardTypes: build.query<GetIdentityCardTypesApiResponse, GetIdentityCardTypesArg>({
      query: () => ({
        url: `/common/v1/identity-card-types`,
      }),
    }),
    getRoundType: build.query<GetRoundTypeApiResponse, GetRoundTypeApiArg>({
      query: () => ({
        url: `/common/v1/round-type`,
      }),
    }),
    getVatRates: build.query<GetVatRatesApiResponse, GetVatRatesApiArg>({
      query: () => ({
        url: `/common/v1/vat-rates`,
      }),
    }),
  }),
});

export const {
  useLazyGetAlphaCatalogueQuery,
  useLazyGetCountriesQuery,
  useLazyGetCurrenciesQuery,
  useLazyGetGendersQuery,
  useLazyGetBankListQuery,
  useLazyGetIdentityCardTypesQuery,
  useLazyGetPhoneNumbersQuery,
  useLazyGetRoundTypeQuery,
  useLazyGetVatRatesQuery,
  useGetAlphaCatalogueQuery,
  useGetBankListQuery,
  useGetCountriesQuery,
  useGetPrioritizedCountriesQuery,
  useGetCurrenciesQuery,
  useGetGendersQuery,
  useGetIdentityCardTypesQuery,
  useGetPhoneNumbersQuery,
  useGetRoundTypeQuery,
  useGetVatRatesQuery,
} = commonApi;
