import {testIds} from '@omnetic-admin-service/shared';
import {Card} from 'platform/components';
import {Box, Heading, HStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {selectQuickInfo} from '../../api/adminApi';
import {PageHeader} from '../../components/PageHeader/PageHeader';
import {i18n} from '../../i18n/config';

export function Dashboard() {
  const {data} = useSelector(selectQuickInfo);

  return (
    <>
      <PageHeader title={i18n.t('home.pageTitle')} />
      <HStack width="100%" spacing={4}>
        <Card title={i18n.t('common.activeTenants')}>
          <Box width={70}>
            <Heading data-testid={testIds.home('activeTenantsCount')} size={1}>
              {(data?.activeTenantsCount ?? 0).toString()}
            </Heading>
          </Box>
        </Card>
        <Card title={i18n.t('common.activeProjects')}>
          <Box width={70}>
            <Heading data-testid={testIds.home('activeProjectsCount')} size={1}>
              {(data?.activeProjectsCount ?? 0).toString()}
            </Heading>
          </Box>
        </Card>
      </HStack>
    </>
  );
}
