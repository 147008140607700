import {ThemeIconKey} from 'platform/foundation';

import {useContext} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {IconButton} from '../../IconButton/IconButton';
import {PaginationContext} from '../utils/context';

interface ControlButtonProps extends TestIdProps {
  icon: ThemeIconKey;
  action: () => void;
}

export function ControlButton(props: ControlButtonProps) {
  const {state} = useContext(PaginationContext);

  return (
    <IconButton
      size="small"
      priority="secondary"
      icon={props.icon}
      onClick={props.action}
      isDisabled={state.isDisabled}
      data-testid={suffixTestId('controlButton', props)}
    />
  );
}
