import {Nullish} from 'shared';

import {TimeType} from '../types/TimeType';

/**
 * Returns empty string if given Nullish value or 'Invalid time' if time is not valid. Otherwise returns given value.
 */
export const getInputValue = (time: TimeType | Nullish) => {
  const regex = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);

  if (!time) return '';
  if (!regex.test(time)) return 'Invalid time';
  return time;
};
