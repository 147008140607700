import {Box, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import {union, without} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps, OptionType} from '../../types/FormControlProps';
import {Checkbox} from '../Checkbox/Checkbox';
import {CheckboxPropsBase} from '../Checkbox/types';

export type CheckboxTreeOptionType = CheckboxPropsBase &
  OptionType & {
    options?: CheckboxTreeOptionType[];
  };

export interface CheckboxTreeProps extends FormControlProps<string[] | null>, TestIdProps {
  options?: CheckboxTreeOptionType[];
}

export function CheckboxTree(props: CheckboxTreeProps) {
  const getOnChangeForOption = useCallback(
    (option: CheckboxTreeOptionType) => (isChecked: boolean) => {
      const nextValue = isChecked
        ? union(props.value ?? [], getNestedOptionValues(option))
        : without(getNestedOptionValues(option), props.value ?? []);

      props.onChange?.(nextValue);
    },
    [props]
  );

  const getCheckboxes = (options?: CheckboxTreeOptionType[]) =>
    options?.length
      ? options?.map((option, index) => {
          const selectedAllChildren = hasEveryOptionSelected(option, props.value);
          const isChecked = selectedAllChildren || isSelected(option, props.value);
          const isIndeterminated = !isChecked && hasSomeOptionsSelected(option, props.value);

          return (
            <VStack key={option?.value || `${props.name}-${index}`} spacing={2}>
              <Checkbox
                data-testid={suffixTestId(`${props.name}-${option.value}`, props)}
                label={option?.label}
                value={isChecked}
                isIndeterminate={isIndeterminated}
                onChange={getOnChangeForOption(option)}
              />

              {option?.options?.length ? (
                <Box paddingLeft={4}>
                  <VStack spacing={2}>{getCheckboxes(option?.options)}</VStack>
                </Box>
              ) : null}
            </VStack>
          );
        })
      : null;

  return <VStack spacing={2}>{getCheckboxes(props.options)}</VStack>;
}

const isSelected = (option: CheckboxTreeOptionType, selected: null | string[]) =>
  !!selected?.includes(option.value);

const hasSomeOptionsSelected = (
  option: CheckboxTreeOptionType,
  selected: null | string[]
): boolean =>
  option.options?.length
    ? option.options.some(
        (child: CheckboxTreeOptionType) =>
          isSelected(child, selected) ||
          (child.options?.length && hasSomeOptionsSelected(child, selected))
      )
    : false;

const hasEveryOptionSelected = (
  option: CheckboxTreeOptionType,
  selected: null | string[]
): boolean =>
  option.options?.length
    ? option.options.every(
        (child: CheckboxTreeOptionType) =>
          isSelected(child, selected) ||
          (child.options?.length && hasEveryOptionSelected(child, selected))
      )
    : false;

const getNestedOptionValues = (option: CheckboxTreeOptionType): string[] =>
  option?.options?.length
    ? option?.options?.flatMap((child: CheckboxTreeOptionType) => getNestedOptionValues(child))
    : [option?.value];
