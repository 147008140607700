import {useEffect, useId} from 'react';

import {Nullish} from 'shared';

import {useFormSaverContext} from './useFormSaverContext';

interface UseFormSaverProps {
  getIsDirty: () => boolean;
  isDisabled?: boolean | Nullish;
  formId?: string;
}

export function useFormSaver({getIsDirty, isDisabled, formId}: UseFormSaverProps) {
  const hookId = useId();
  const id = formId ?? hookId;
  const {registerForm, unRegisterForm} = useFormSaverContext();

  useEffect(() => {
    if (isDisabled) return;
    registerForm(id, getIsDirty);

    return () => {
      if (!getIsDirty()) unRegisterForm(id);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIsDirty, isDisabled]);
}
