export type Permission = {
  action: string;
  resource: string;
};

type CachedPermission = {action: {id: string}; resource: {id: string}};

export const hasPermission = (permission: Permission) => {
  const permissions: CachedPermission[] = JSON.parse(
    localStorage.getItem('userPermissions') || '[]'
  );

  return permissions?.some(
    ({action, resource}) =>
      action?.id === permission?.action && resource?.id === permission?.resource
  );
};
