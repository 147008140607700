import {ThemeIconKey} from 'platform/foundation';

import {ReactNode} from 'react';

import {Nullish} from 'shared';

export type NotificationItemData = {
  id: string;
  isRead?: boolean;
  resource?: string;
  url?: string | Nullish;
  userName?: string;
  icon?: ThemeIconKey;
  created?: Date;
  title: string | ReactNode;
  message?: string;
};
