import {Integer} from 'platform/foundation';

import {RendererRegistry} from './RendererRegistry';

export type GetSchemaApiResponse = /** status 200  */ SchemaResponseBody;
export type GetSchemaApiArg = {
  id: string;
};

export type SchemaResponseBody = {
  root: CardRendererResponseBody | RowRendererResponseBody;
};

export type OptionResponseBody = {
  value: string;
  label: string;
};
export type DependentOptionResponseBody = {
  name: string;
  action: 'lt' | 'lte' | 'gt' | 'gte';
};
export type EventActionResponseBody = {
  field: string;
  action: 'reset';
};
export type ShowWhenResponseBody = {
  field: string;
  isEqualTo: string | boolean | number;
};
export type CheckboxesRendererResponseBody = {
  renderer: 'checkboxes';
  name: string;
  url: string | null;
  label: string;
  columnSpan: Integer | null;
  defaultOptions: OptionResponseBody[];
  dependentOptions: DependentOptionResponseBody[] | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type CheckboxRendererResponseBody = {
  renderer: 'checkbox';
  name: string;
  label: string;
  columnSpan: Integer | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type ChipsRendererResponseBody = {
  renderer: 'chips';
  name: string;
  label: string;
  columnSpan: Integer | null;
  url: string | null;
  defaultOptions: OptionResponseBody[];
  dependentOptions: DependentOptionResponseBody[] | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type DateRangeRendererResponseBody = {
  renderer: 'dateRange';
  name: string;
  label: string;
  columnSpan: Integer | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type DateRendererResponseBody = {
  renderer: 'date';
  name: string;
  label: string;
  columnSpan: Integer | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type DividerRendererResponseBody = {
  renderer: 'divider';
  name: string;
  columnSpan: Integer | null;
  orientation: ('horizontal' | 'vertical') | null;
  spacing: number | null;
};
export type EmailRendererResponseBody = {
  renderer: 'email';
  name: string;
  label: string;
  columnSpan: Integer | null;
  placeholder: string | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type NumberRendererResponseBody = {
  renderer: 'number';
  name: string;
  label: string;
  columnSpan: Integer | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type PhoneRendererResponseBody = {
  renderer: 'phone';
  name: string;
  label: string;
  columnSpan: Integer | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type SelectRendererResponseBody = {
  renderer: 'select';
  name: string;
  url: string | null;
  label: string;
  placeholder: string | null;
  isMulti: boolean | null;
  isClearable: boolean | null;
  columnSpan: Integer | null;
  defaultOptions: OptionResponseBody[];
  dependentOptions: DependentOptionResponseBody[] | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type SliderRendererResponseBody = {
  renderer: 'slider';
  name: string;
  label: string;
  min: number | null;
  max: number | null;
  step: number | null;
  columnSpan: Integer | null;
  isRange: boolean | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type SwitchRendererResponseBody = {
  renderer: 'switch';
  name: string;
  label: string;
  columnSpan: Integer | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type TextRendererResponseBody = {
  renderer: 'text';
  name: string;
  label: string;
  columnSpan: Integer | null;
  placeholder: string | null;
  onChange: EventActionResponseBody[] | null;
  onBlur: EventActionResponseBody[] | null;
  onFocus: EventActionResponseBody[] | null;
  showWhen: ShowWhenResponseBody | null;
};
export type RowRendererResponseBody = {
  renderer: 'row';
  name: string;
  columnSpan: Integer | null;
  columns: Integer;
  rendererRegistry: RendererRegistry;
  elements: (
    | CardRendererResponseBody
    | CheckboxesRendererResponseBody
    | CheckboxRendererResponseBody
    | ChipsRendererResponseBody
    | DateRangeRendererResponseBody
    | DateRendererResponseBody
    | DividerRendererResponseBody
    | EmailRendererResponseBody
    | NumberRendererResponseBody
    | PhoneRendererResponseBody
    | RowRendererResponseBody
    | SelectRendererResponseBody
    | SliderRendererResponseBody
    | SwitchRendererResponseBody
    | TextRendererResponseBody
  )[];
};

export type CardRendererResponseBody = {
  renderer: 'card';
  header: string | null;
  name: string;
  variant: ('default' | 'inlineGrey' | 'inlineWhite') | null;
  columnSpan: Integer | null;
  isExpandable: boolean | null;
  isClosedByDefault: boolean | null;
  rendererRegistry: RendererRegistry;
  elements: (
    | CardRendererResponseBody
    | CheckboxesRendererResponseBody
    | CheckboxRendererResponseBody
    | ChipsRendererResponseBody
    | DateRangeRendererResponseBody
    | DateRendererResponseBody
    | DividerRendererResponseBody
    | EmailRendererResponseBody
    | NumberRendererResponseBody
    | PhoneRendererResponseBody
    | RowRendererResponseBody
    | SelectRendererResponseBody
    | SliderRendererResponseBody
    | SwitchRendererResponseBody
    | TextRendererResponseBody
  )[];
};
