import {routes} from '@omnetic-admin-service/shared';
import {IconButton, useAnimatedPopper, Card, Button} from 'platform/components';
import {Box, Text, Clickable, Heading, Right, Space} from 'platform/foundation';

import {i18n} from '../../../i18n/config';

function Header() {
  const {openPopper, referenceRef, popperProps, Popper, closePopper} = useAnimatedPopper({
    placement: 'bottom-end',
    gutter: 10,
  });

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${window.location.origin}${routes.loginPage}`;
  };

  return (
    <Clickable
      onMouseEnter={openPopper}
      onMouseLeave={closePopper}
      data-testid="layout-header-logoutPopup-container"
    >
      <div ref={referenceRef}>
        <IconButton
          data-testid="layout-header-logoutPopup"
          severity="danger"
          icon="action/settings_power"
        />
      </div>
      <Popper {...popperProps}>
        <Card data-testid="layout-header-logoutPopup">
          <Box minWidth={50}>
            <Text size="small" data-testid="layout-header-logoutPopup">
              {localStorage.getItem('user')}
            </Text>
            <Heading headingLevel={5} size={5}>
              {i18n.t('layout.logout.message')}
            </Heading>
            <Space vertical={4} />
            <Right>
              <Button
                data-testid="layout-header-logoutPopup"
                variant="danger"
                onClick={onLogout}
                title={i18n.t('layout.logout.action')}
              />
            </Right>
          </Box>
        </Card>
      </Popper>
    </Clickable>
  );
}

export const LogoutIconButton = Header;
