import {indexOf} from 'ramda';

import {roundToStep} from './roundToStep';

/**
 * @description
 * Returns value between current rounded value and next or previous step value
 *
 * @param {number} value - current value
 * @param {number | number[]} step - step value or array
 * @param {"next" | "prev"} direction - step value or array
 *
 * @example
 * // return 20
 * getStep(9, 10, 'next');
 *
 * // return 0
 * getStep(9, 10, 'prev');
 *
 * // return 20
 * getStep(9, [0, 10, 20], 'next');
 *
 * // return 0
 * getStep(9, [0, 10, 20], 'prev');
 */

export const getStep = (
  value: number,
  step: number | number[],
  direction: 'next' | 'prev' = 'next'
) => {
  const roundedValue = roundToStep(value, step);

  if (Array.isArray(step)) {
    const currentIndex = indexOf(roundedValue, step);

    if (direction === 'next') return step[currentIndex + 1] - value;
    return value - step[currentIndex - 1];
  }

  return step;
};
