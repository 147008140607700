import {ComponentStyleConfig} from '@chakra-ui/theme';

export const breadcrumbTheme: ComponentStyleConfig = {
  parts: ['container', 'item', 'link', 'separator'],
  baseStyle: {
    item: {
      fontSize: 'text.xSmall',
      lineHeight: 'text.xSmall',
      fontWeight: 'regular',
      letterSpacing: 0,
      color: 'general.accent',
      'span[aria-current="page"]': {
        color: 'text.tertiary',
        pointerEvents: 'none',
      },
    },
    separator: {
      fontSize: 'text.xSmall',
      lineHeight: 'text.xSmall',
      fontWeight: 'regular',
      letterSpacing: 0,
      color: 'palettes.neutral.40.100',
      w: '12px',
      h: '12px',
      ml: '4px',
      mr: '4px',
      pointerEvents: 'none',
    },
    container: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
      },
    },
  },
  variants: {},
  sizes: {},
  defaultProps: {},
};
