export interface SliderTick {
  value: number;
  label?: string;
  small?: boolean;
}

export type DisplayValue = 'auto' | 'on' | 'off';

export type SliderValue<IsRange extends boolean = false> = IsRange extends true
  ? [number, number]
  : number;
