import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Show, Space, Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useMemo} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {i18n} from '../../i18n/config';

export type ProjectForm = {
  id: string;
  name: string;
  description?: string | null;
};

interface EditLicenceDialogProps extends TestIdProps {
  isLoading: boolean;
  description?: string;
  defaultValues?: ProjectForm | null;
  readOnlyDescription?: boolean;
  onSubmit: FormSubmitHandler<ProjectForm>;
}

export function ProjectFormDialog(props: EditLicenceDialogProps) {
  const getDefaultValues = useMemo(() => {
    if (!props.defaultValues?.id && !props.defaultValues?.name) return;
    return props.defaultValues;
  }, [props.defaultValues]);

  return (
    <Form onSubmit={props.onSubmit} schema={schema} defaultValues={getDefaultValues}>
      {(control) => (
        <>
          <Show when={props.description}>
            <>
              <Text size="small">{props.description}</Text>
              <Space vertical={4} />
            </>
          </Show>
          <VStack spacing={4}>
            <FormField
              data-testid={suffixTestId('name', props)}
              name="name"
              type="text"
              control={control}
              label={i18n.t('common.name')}
              isRequired
            />
            <FormField
              data-testid={suffixTestId('description', props)}
              name="description"
              type="textarea"
              minRows={5}
              control={control}
              isDisabled={props.readOnlyDescription}
              label={i18n.t('common.description')}
            />

            <ButtonGroup align="right" data-testid={suffixTestId('projectDetailDialog', props)}>
              <Button
                data-testid={suffixTestId('cancel', props)}
                isDisabled={props.isLoading}
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('common.cancel')}
              />
              <FormButton
                data-testid={suffixTestId('submit', props)}
                control={control}
                type="submit"
                isLoading={props.isLoading}
                isDisabled={props.isLoading}
                title={i18n.t('common.save')}
              />
            </ButtonGroup>
          </VStack>
        </>
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().optional(),
});
