import {ComponentStyleConfig} from '@chakra-ui/react';

// eslint-disable-next-line no-restricted-syntax
export const choiceTheme: ComponentStyleConfig = {
  baseStyle: {
    choiceTag: {
      backgroundColor: 'palettes.neutral.900.100',
      color: 'general.white',
      borderRadius: '2px',
      pl: '4px',
      pr: 0,
      '.eag--tag-close': {
        mr: 0,
        ml: '4px',
      },
    },
  },
  parts: [
    'menuVariant', // Menu control variant used for styling the drop down menu with options
    'valueTagVariant', // Tag control variant for standard values in multi select
    'moreTagVariant', // Tag control variant for "more tag" (showing the number of hidden values)
    'choiceTag', // Additional common styles for a div wrapping all tags
    'inputSize', // for each Select size specify which size from Input theme to use for Control subcomponent
    'tagSize', // for each Select size specify which size to use for tags
    'clearButtonVariant', // variant for clear values button
    'buttonSize', // size of the buttons (clear button so far)
  ],
  variants: {
    default: {
      menuVariant: 'default',
      valueTagVariant: 'bold',
      moreTagVariant: 'outline',
      clearButtonVariant: 'ghost',
    },
  },
  sizes: {
    md: {
      tagSize: 'md',
      buttonSize: 'xs',
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
} as unknown as ComponentStyleConfig;
