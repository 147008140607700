import {Button, DataStatus, openDialog, Table, TableRow} from 'platform/components';
import {Icon, Heading, HStack, Show, Text} from 'platform/foundation';

import {isEmpty} from 'ramda';

import {useGetAuthorizationProfilesQuery} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {AuthorizationProfileType} from '../types';
import {AuthorizationProfileForm} from './AuthorizationProfileForm';

interface AuthorizationProfilesProps {
  tenantId: string;
}

export function AuthorizationProfiles(props: AuthorizationProfilesProps) {
  const {data, isLoading, isError} = useGetAuthorizationProfilesQuery({'x-tenant': props.tenantId});

  const openFormDialog = (authorizationProfile?: AuthorizationProfileType | null) => {
    openDialog(
      <AuthorizationProfileForm tenantId={props.tenantId} defaultValues={authorizationProfile} />,
      {
        title:
          authorizationProfile?.name ?? i18n.t('authorization.actions.newAuthorizationProfile'),
        scrollBehavior: 'outside',
      }
    );
  };

  return (
    <>
      <HStack width="100%" justify="space-between">
        <Heading size={4}>{i18n.t('authorization.labels.authorizationProfiles')}</Heading>
        <Button
          variant="ghostLink"
          leftIcon="content/add_circle"
          onClick={() => openFormDialog()}
          title={i18n.t('authorization.actions.newAuthorizationProfile')}
        />
      </HStack>
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        isEmpty={isEmpty(data)}
        minHeight={15}
        errorMessage={i18n.t('authorization.labels.emptyAuthorizationsProfiles')}
        emptyMessage={i18n.t('authorization.labels.emptyAuthorizationsProfiles')}
      >
        <Table tableLayout="fixed">
          <TableRow>
            <Text alternative size="small">
              {i18n.t('common.name')}
            </Text>
            <Text alternative size="small">
              {i18n.t('common.isDefault')}
            </Text>
          </TableRow>
          {data?.map((profile) => (
            <TableRow
              key={profile?.id}
              actions={{
                primary: [
                  {
                    title: i18n.t('authorization.actions.editAuthorizationProfile'),
                    icon: 'image/edit',
                    onClick: () => openFormDialog(profile),
                  },
                ],
              }}
            >
              <Text size="small" noWrap>
                {profile?.name}
              </Text>
              <Text size="small" noWrap>
                <Show when={profile?.isDefault}>
                  <Icon value="action/check_circle_outline" color="palettes.green.60.100" />
                </Show>
              </Text>
            </TableRow>
          ))}
        </Table>
      </DataStatus>
    </>
  );
}
