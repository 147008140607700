import {format} from 'date-fns';
import {Box, DayMonthType, HStack} from 'platform/foundation';
import {
  convertAppLanguageToDateFns,
  getDateValueFromDayMonth,
  useDayMonthFormatter,
  useLocale,
} from 'platform/locale';
import styled from 'styled-components';

import {useEffect, useRef} from 'react';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {useAnimatedPopper} from '../AnimatedPopper/hooks/useAnimatedPopper';
import {TextInput} from '../TextInput/TextInput';
import {months} from './constants/months';
import {useMonthName} from './hooks/useMonthName';
import {getDropdownDays} from './utils/getDropdownDays';
import {isDayInMonth} from './utils/isDayInMonth';

const DEFAULT_MONTH = 1;
const DEFAULT_DAY = 1;

export interface DayMonthPickerProps extends FormControlProps<DayMonthType | null>, TestIdProps {
  isRecommended?: boolean;
}

export function DayMonthPicker(props: DayMonthPickerProps) {
  const {language} = useLocale();
  const {getMonthName} = useMonthName();
  const dayMonthFormatter = useDayMonthFormatter();

  const {popperProps, Popper, openPopper, closePopper, referenceRef, isOpen} = useAnimatedPopper();

  const isInvalid = props.isInvalid || !!props.errorMessage;

  const monthRef = useRef<HTMLDivElement>(null);
  const dayRef = useRef<HTMLDivElement>(null);

  const days = getDropdownDays(props.value?.month);

  useEffect(() => {
    if (isOpen) {
      monthRef.current?.scrollIntoView({block: 'center'});
      dayRef.current?.scrollIntoView({block: 'center'});
    }
  }, [isOpen]);

  const onMonthSelect = (month: number) => {
    const selectedDay = props.value?.day;

    let day = isNil(selectedDay) ? DEFAULT_DAY : selectedDay;

    day = isDayInMonth(day, month) ? day : DEFAULT_DAY;

    props.onChange?.({month, day});
  };

  const onDaySelect = (day: number) => {
    const selectedMonth = props.value?.month;

    if (isNil(selectedMonth)) return;

    props.onChange?.({month: selectedMonth, day});
    closePopper();
  };

  return (
    <>
      <div ref={referenceRef}>
        <TextInput
          leftIcon="action/today"
          onClick={props.isDisabled ? undefined : openPopper}
          isRequired={props.isRequired}
          isDisabled={props.isDisabled}
          value={props.value ? dayMonthFormatter('dateMedium', props.value) : null}
          placeholder="dd MMMM"
          label={props.label}
          tooltip={props.tooltip}
          isInvalid={isInvalid}
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          isRecommended={props.isRecommended}
          data-testid={suffixTestId('input', props)}
        />
      </div>
      <Popper {...popperProps}>
        <Box paddingTop={1}>
          <Box
            borderRadius="medium"
            boxShadow="elevation_2"
            border="1px solid"
            borderColor="palettes.neutral.40.100"
            backgroundColor="general.white"
            data-testid={suffixTestId('dropdown', props)}
          >
            <HStack width="auto">
              <Box flex={1}>
                <List data-testid={suffixTestId('monthList', props)}>
                  {months.map((month) => (
                    <Selection
                      isSelected={month === props.value?.month}
                      ref={month === props.value?.month ? monthRef : null}
                      onClick={() => onMonthSelect(month)}
                      key={month}
                    >
                      {getMonthName(month)}
                    </Selection>
                  ))}
                </List>
              </Box>
              <List data-testid={suffixTestId('dayList', props)}>
                {days.map((day) => (
                  <Selection
                    isDisabled={isNil(props.value?.month)}
                    isSelected={day === props.value?.day}
                    ref={day === props.value?.day ? dayRef : null}
                    onClick={() => isNotNil(props.value?.month) && onDaySelect(day)}
                    key={day}
                  >
                    {format(
                      getDateValueFromDayMonth(props.value?.month ?? DEFAULT_MONTH, day),
                      'd',
                      {
                        locale: convertAppLanguageToDateFns(language),
                      }
                    )}
                  </Selection>
                ))}
              </List>
            </HStack>
          </Box>
        </Box>
      </Popper>
    </>
  );
}

interface SelectionProps {
  isSelected?: boolean;
  isDisabled?: boolean;
}

const Selection = styled.div<SelectionProps>`
  text-align: center;
  cursor: ${({isDisabled}) => (isDisabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({theme}) => theme.radii.small};
  padding: ${({theme}) => theme.getSize(2)};
  padding-left: ${({theme}) => theme.getSize(4)};
  padding-right: ${({theme}) => theme.getSize(4)};
  background-color: ${({isSelected, theme}) =>
    isSelected ? theme.colors.palettes.blue[10][100] : 'initial'};
  color: ${({theme, isSelected, isDisabled}) => {
    if (isDisabled) return theme.colors.palettes.neutral[900][40];
    if (isSelected) return theme.colors.palettes.blue[80][100];
    return theme.colors.text.primary;
  }};
  :hover {
    background-color: ${({theme, isDisabled}) =>
      !isDisabled && theme.colors.palettes.blue[10][100]};
    color: ${({theme, isDisabled}) => !isDisabled && theme.colors.palettes.blue[80][100]};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  scrollbar-gutter: stable;
  overflow-y: auto;
  height: ${({theme}) => theme.getSize(60)};
  :hover {
    overflow-y: auto;
  }
  padding: ${({theme}) => theme.getSize(4)};
  width: 100%;
`;
