import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

import {has, not, pluck, uniq} from 'ramda';
import {isPlainObj} from 'ramda-adjunct';

import {ApiException} from '../types';

function isApiException(errorData: unknown): errorData is ApiException {
  return !!(isPlainObj(errorData) && has('errors', errorData));
}

const NO_CODE = '???';

/**
 * @about Parses catched error from RTK Query
 */
export function getApiErrorMessages(error: FetchBaseQueryError) {
  const defaultMessage = 'Ups, something wrong happened.';
  if (!isApiException(error?.data)) return defaultMessage;

  /**
   * Filter out messages without proper code
   * TODO: Translate messages by code
   */
  const errors = error?.data?.errors.filter((error) =>
    not(!error?.message || !error?.code || NO_CODE === error?.code)
  );

  /**
   * Get unique error messsages
   */
  const messages = uniq(pluck('message', errors));

  return !!messages.length ? messages : defaultMessage;
}
