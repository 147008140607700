import {HStack, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {TimeType} from '../../types/TimeType';
import {getTimeFromDate} from '../../utils/getTimeFromDate';
import {setTimeOnDate} from '../../utils/setTimeOnDate';
import {DatePicker} from '../DatePicker/DatePicker';
import {Label} from '../Label/Label';
import {TimePicker} from '../TimePicker/TimePicker';

interface DateTimePickerProps extends FormControlProps<Date | null>, TestIdProps {
  minDate?: Date;
  maxDate?: Date;
  isRelativeDatesHidden?: boolean;
}

export function DateTimePicker(props: DateTimePickerProps) {
  const time = isNotNil(props.value) ? getTimeFromDate(props.value) : null;

  const onDateChange = (date: Date | null) => {
    if (!date) return;
    const dateTime = time ? setTimeOnDate(date, time) : date;
    props.onChange?.(dateTime);
  };
  const onTimeChange = (time: TimeType | null) => {
    time && props.onChange?.(setTimeOnDate(props.value ?? new Date(), time));
  };

  return (
    <VStack>
      <Label data-testid={suffixTestId('label', props)} tooltip={props.tooltip}>
        {props.label}
      </Label>
      <HStack spacing={2}>
        <DatePicker
          isDisabled={props.isDisabled}
          minDate={props.minDate}
          maxDate={props.maxDate}
          onChange={onDateChange}
          value={props.value}
          isRelativeDatesHidden={props.isRelativeDatesHidden}
          helperText={props.helperText}
          errorMessage={props.errorMessage}
          isInvalid={props.isInvalid}
          data-testid={suffixTestId('datePicker', props)}
        />
        <TimePicker
          isInvalid={props.isInvalid}
          value={time}
          onChange={onTimeChange}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('timePicker', props)}
        />
      </HStack>
    </VStack>
  );
}
