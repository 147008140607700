import {ComponentStyleConfig} from '@chakra-ui/theme';

// eslint-disable-next-line no-restricted-syntax
export const phoneTheme: ComponentStyleConfig = {
  parts: ['dropdownControl', 'dropdownControlFlag', 'dropdownControlArrowIcon'],
  defaultProps: {
    size: 'default',
    variant: 'default',
  },
  baseStyle: {
    dropdownControl: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'pointer',
      p: '4px',
      pe: 0,
      _disabled: {
        cursor: 'not-allowed',
        opacity: 0.4,
      },
    },
    dropdownControlFlag: {
      mr: '0',
      svg: {
        w: '22.4px',
      },
    },
    dropdownControlArrowIcon: {
      fontSize: 'sm',
      svg: {
        w: '16px',
        h: '16px',
      },
    },
  },
} as unknown as ComponentStyleConfig;
