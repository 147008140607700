import {routes} from '@omnetic-admin-service/shared';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, Heading, HStack, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useNavigate} from 'react-router-dom';

import {HTTP_STATUS_CODES, suffixTestId, composePath, TestIdProps} from 'shared';

import {useCreateTenantMutation} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {CreateTenantRequestBody} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';

type CreateTenant = Omit<CreateTenantRequestBody, 'host'>;

function CreateTenantDialog(props: TestIdProps) {
  const navigate = useNavigate();

  const [createTenant, {isLoading}] = useCreateTenantMutation();

  const onSubmit: FormSubmitHandler<CreateTenant> = async (createTenantRequestBody, setErrors) => {
    await createTenant({
      createTenantRequestBody: {host: null, ...createTenantRequestBody},
    })
      .unwrap()
      .then(({id}) => {
        navigate(composePath(routes.tenantDetail, {params: {id}}));
      })
      .catch((error) => {
        handleApiError(error, {
          callback: () => {
            if (error?.status === HTTP_STATUS_CODES.BAD_REQUEST)
              setErrors([
                {
                  name: 'registrationNumber',
                  message: i18n.t('general.errors.companyNotFound'),
                },
              ]);
          },
        });
      });
  };

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
      {(control) => (
        <VStack spacing={4}>
          <Heading size={4}>{i18n.t('common.tenant')}</Heading>

          <HStack width="100%" height="100%" spacing={4}>
            <Box flex={1}>
              <FormField
                name="workspace"
                type="text"
                control={control}
                label={i18n.t('common.workspace')}
                maxLength={100}
                isRequired
                data-testid={suffixTestId('workspace', props)}
              />
            </Box>

            <Box flex={1}>
              <FormField
                name="registrationNumber"
                type="text"
                control={control}
                label={i18n.t('common.registrationNumber')}
                isRequired
                data-testid={suffixTestId('registrationNumber', props)}
              />
            </Box>
          </HStack>

          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                name="country"
                type="choice"
                control={control}
                label={i18n.t('common.country')}
                options={COUNTRY_CODES}
                data-testid={suffixTestId('country', props)}
              />
            </Box>
            <Space fillAvailable />
          </HStack>

          <FormField
            name="note"
            type="textarea"
            control={control}
            label={i18n.t('common.note')}
            data-testid={suffixTestId('note', props)}
          />

          <Heading size={4}>{i18n.t('common.user')}</Heading>

          <HStack width="100%" height="100%" spacing={4}>
            <Box flex={1}>
              <FormField
                name="firstUser.firstName"
                type="text"
                control={control}
                isRequired
                label={i18n.t('common.firstName')}
                data-testid={suffixTestId('firstName', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                name="firstUser.lastName"
                type="text"
                isRequired
                control={control}
                label={i18n.t('common.lastName')}
                data-testid={suffixTestId('lastName', props)}
              />
            </Box>
          </HStack>
          <HStack width="100%" height="100%" spacing={4}>
            <Box flex={1}>
              <FormField
                name="firstUser.email"
                type="email"
                control={control}
                isRequired
                label={i18n.t('common.email')}
                data-testid={suffixTestId('email', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                name="firstUser.password"
                type="password"
                isRequired
                control={control}
                label={i18n.t('common.password')}
                data-testid={suffixTestId('password', props)}
              />
            </Box>
          </HStack>
          <ButtonGroup align="right" data-testid={suffixTestId('actions', props)}>
            <Button
              data-testid={suffixTestId('close', props)}
              isDisabled={isLoading}
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('common.close')}
            />
            <FormButton
              control={control}
              type="submit"
              isLoading={isLoading}
              data-testid={suffixTestId('submit', props)}
              title={i18n.t('tenants.createTenant')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  registrationNumber: Yup.string().required().min(3).max(20).trim(),
  workspace: Yup.string().required().min(1).max(30).trim(),
  country: Yup.string(),
  note: Yup.string().trim().max(255),
  firstUser: Yup.object().shape({
    firstName: Yup.string().required().min(2).max(20).trim(),
    lastName: Yup.string().required().min(2).max(20).trim(),
    email: Yup.string().required().min(2).max(40).trim(),
    password: Yup.string().required().min(2).max(40),
  }),
});

const defaultValues = {
  workspace: '',
  country: 'CZE',
  registrationNumber: '',
  note: '',
  firstUser: {email: '', firstName: '', lastName: '', password: ''},
} as const;

const COUNTRY_CODES = [
  {label: 'CZE', value: 'CZE'},
  {label: 'SVK', value: 'SVK'},
  {label: 'POL', value: 'POL'},
  {label: 'DEU', value: 'DEU'},
  {label: 'ESP', value: 'ESP'},
  {label: 'ITA', value: 'ITA'},
  {label: 'FRA', value: 'FRA'},
  {label: 'BEL', value: 'BEL'},
] as const;

export const CreateTenant = CreateTenantDialog;
