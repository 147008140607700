import {ComponentStyleConfig, ThemeComponentProps} from '@chakra-ui/react';

import {sliderCaseValue} from './utils/sliderCaseValue';

export const sliderTheme: ComponentStyleConfig = {
  baseStyle: (props) => ({
    wrapper: {
      px: 'calc(var(--thumb-size) / 2)',
      _disabled: {
        cursor: 'not-allowed',
      },
    },
    track: {
      position: 'relative',
      h: 'var(--thumb-size)',
      margin: '0 calc(var(--thumb-size) / 2)',
    },
    unfilledTrack: {
      position: 'absolute',
      top: '50%',
      left: 'calc(var(--thumb-size) / 2 * -1)',
      w: 'calc(100% + var(--thumb-size))',
      h: 'var(--track-height)',
      transform: 'translateY(-50%)',
    },
    filledTrack: {
      position: 'absolute',
      top: '50%',
      left: sliderCaseValue(props as ThemeComponentProps, {
        single: 'calc(var(--thumb-size) / 2 * -1)',
        inverted: 'calc(var(--slider-offset-end) * 1%)',
        unturned: 'calc(var(--slider-offset-start) * 1%)',
      }),
      h: 'calc(var(--track-height) + 2px)',
      w: 'calc(var(--slider-fill-size, var(--slider-offset)) * 1%)',
      transform: 'translateY(-50%)',
      cursor: props.allowMoveTrack ? 'grab' : undefined,
    },
    thumbWrapper: {
      position: 'absolute',
      w: '100%',
      h: '100%',
      left: '0px',
      top: 'calc(var(--thumb-size) + 1px)',
      pointerEvents: 'none',
    },
    thumbSingleWrapper: {
      transform: 'translateX(calc(var(--slider-offset) * 1%))',
    },
    thumbStartWrapper: {
      transform: 'translateX(calc(var(--slider-offset-start) * 1%))',
    },
    thumbEndWrapper: {
      transform: 'translateX(calc(var(--slider-offset-end) * 1%))',
    },
    thumb: {
      w: 'var(--thumb-size)',
      h: 'var(--thumb-size)',
      transform: 'translate(-50%, -50%) translateY(calc((-1 * (var(--thumb-size) + 1px)) / 2))',
      pointerEvents: 'auto',
      borderRadius: '50%',
      cursor: 'pointer',
      _disabled: {
        cursor: 'not-allowed',
      },
      _hover: {
        '.eag--slider__thumb-label': {
          display: 'var(--thumb-label-display-hover)',
        },
      },
    },
    ticks: {
      position: 'relative',
      margin: '0 calc(var(--thumb-size) / 2)',
    },
    tick: {
      position: 'absolute',
      top: '0px',
      left: 'calc(var(--tick-offset) * 1%)',
      transform: 'translateX(-50%)',
      '&.eag--slider__tick-min': {
        transform: 'none',
        left: 'calc(var(--thumb-size) / 2 * -1)',
        '.eag--slider__tick-indicator': {
          left: '0',
        },
      },
      '&.eag--slider__tick-max': {
        transform: 'translateX(-100%)',
        left: 'calc(var(--tick-offset) * 1% + var(--thumb-size) / 2)',
        '.eag--slider__tick-indicator': {
          left: 'auto',
          right: '0',
        },
      },
    },
    tickLabel: {
      whiteSpace: 'nowrap',
    },
    tickIndicator: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    thumbLabel: {
      position: 'absolute',
      bottom: 'calc(100% + 8px)',
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: 'xSmall',
      fontWeight: 'bold',
      display: 'var(--thumb-label-display)',
    },
  }),
  variants: {
    default: (props) => ({
      unfilledTrack: {
        bg: 'palettes.neutral.40.100',
      },
      filledTrack: {
        bg: sliderCaseValue(props as ThemeComponentProps, {
          single: 'general.accent',
          unturned: 'general.accent',
          inverted: 'severity.danger',
        }),
        _disabled: {
          bg: 'palettes.neutral.200.100',
        },
      },
      thumb: {
        bg: 'white',
        boxShadow: 'elevation_2',
        borderColor: 'palettes.neutral.40.100',
        _disabled: {
          borderColor: 'palettes.neutral.20.100',
        },
        _focus: {
          outline: 'none',
          boxShadow: '0 0 4px 1px grey',
        },
      },
      tickIndicator: {
        bg: 'palettes.neutral.40.100',
      },
      thumbLabel: {
        fontSize: 'text.xSmall',
        lineHeight: 'text.xSmall',
        padding: '4px 8px',
      },
    }),
  },
  sizes: {
    default: {
      wrapper: {
        '--track-height': '2px',
        '--thumb-size': '20px',
      },
      thumb: {
        border: '1px solid',
      },
      ticks: {
        height: '24px',
      },
      tickIndicator: {
        h: '12px',
        w: '1px',
        bottom: 'calc(100% + (var(--thumb-size) / 2))',
        transform: 'translateY(50%)',
        '&.eag--slider__tick-indicator-small': {
          h: '8px',
        },
      },
      thumbLabel: {
        bg: 'palettes.neutral.900.100',
        color: 'palettes.white.10.100',
      },
    },
  },
  defaultProps: {
    size: 'default',
    variant: 'default',
  },
  parts: [
    'wrapper',
    'track',
    'filledTrack',
    'unfilledTrack',
    'thumb',
    'thumbWrapper',
    'thumbSingle',
    'thumbSingleWrapper',
    'thumbStartWrapper',
    'thumbEndWrapper',
    'thumbLabel',
    'tick',
    'ticks',
    'tickLabel',
    'tickIndicator',
  ],
};
