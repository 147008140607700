import {createContext} from 'shared';

export type FormSaverDialogStorageType = {onCancel?: () => void; onContinue?: () => void};

export interface FormSaverFormData {
  formId: string;
  isDirtyGetter: () => boolean;
}

export interface FormSaverContextProviderProps {
  // Register form to the blocker
  registerForm: (formId: string, isDirtyGetter: () => boolean) => void;
  // unRegister form (unmount)
  unRegisterForm: (formId: string) => void;
  // Will clear all forms
  resetForm: () => void;
  // Manual blocker for tabs.onClick fncs
  handleManualPageChange: (
    actions: FormSaverDialogStorageType & {
      callback?: () => void;
    }
  ) => void;

  formsData: Map<string, FormSaverFormData>;
  // Modal window
  isDialogOpen: boolean;
  // Modal actions
  openDialog: () => void;
  closeDialog: () => void;
  storage: FormSaverDialogStorageType | null;
}

const [FormSaverContextProvider, useFormSaverContext] =
  createContext<FormSaverContextProviderProps>({
    strict: true,
    name: 'FormSaverContext',
  });

export {FormSaverContextProvider, useFormSaverContext};
