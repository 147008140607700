import {routes} from './routes';

const buildTestId = (routePath: string, id: string) => `routePath=${routePath};id=${id}`;

export const testIds = {
  login: (id: string) => buildTestId(routes.loginPage, id),
  home: (id: string) => buildTestId(routes.homepage, id),
  tenant: (id: string) => buildTestId(routes.tenantBase, id),
  tenantOverview: (id: string) => buildTestId(routes.tenantOverview, id),
  tenantDetail: (id: string) => buildTestId(routes.tenantDetail, id),
  project: (id: string) => buildTestId(routes.projectBase, id),
  projectOverview: (id: string) => buildTestId(routes.projectOverview, id),
  projectDetail: (id: string) => buildTestId(routes.projectDetail, id),
};
