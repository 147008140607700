import {routes} from '@omnetic-admin-service/shared';
import {Box} from 'platform/foundation';

import {i18n} from '../../../i18n/config';
import {MenuItem, MenuItemProps} from './MenuItem';

export function Menu() {
  return (
    <Box width={12} height="100%" backgroundColor="palettes.neutral.900.100">
      {menuItems.map((menuItem) => (
        <MenuItem
          data-testid={menuItem.id}
          key={menuItem.id}
          id={menuItem.id}
          icon={menuItem.icon}
          title={menuItem.title}
          path={menuItem.path}
        />
      ))}
    </Box>
  );
}

const menuItems: MenuItemProps[] = [
  {
    id: 'layoutMenuItem-home',
    icon: 'action/home',
    title: i18n.t('home.pageTitle'),
    path: routes.homepage,
  },
  {
    id: 'layoutMenuItem-tenants',
    icon: 'social/group',
    title: i18n.t('tenants.pageTitle'),
    path: routes.tenantOverview,
  },
  {
    id: 'layoutMenuItem-projects',
    icon: 'communication/business',
    title: i18n.t('projects.pageTitle'),
    path: routes.projectOverview,
  },
];
