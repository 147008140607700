import {SliderTick} from '../types';

/**
 * @description
 * Returns ticks extended by calculated half ticks
 *
 * @param ticks - Ticks passed as param or calculted in Slider
 *
 * @example
 * // return [{value: 1}, {value: 1.5, small: true, lable: ''}, {value: 2}]
 * withHalfTicks([{value: 1}, {value: 2}])
 */

export const withHalfTicks = (ticks: SliderTick[]) => {
  if (ticks.length < 2) return ticks;

  return ticks.reduce((memo: SliderTick[], tick, index) => {
    memo.push(tick);
    const nextTick = ticks[index + 1];
    if (nextTick) {
      memo.push({value: (tick.value + nextTick.value) / 2, small: true, label: ''});
    }
    return memo;
  }, []);
};
