import {useContext} from 'react';

import {Box, HStack} from '@chakra-ui/react';
import {useMultiStyleConfig} from '@chakra-ui/system';

import {suffixTestId, TestIdProps} from 'shared';

import {TextInput} from '../../TextInput/TextInput';
import {PaginationContext} from '../utils/context';

interface InputSegmentProps extends TestIdProps {}

export function InputSegment(props: InputSegmentProps) {
  const {state, actions} = useContext(PaginationContext);

  const {changePage} = actions;
  const {activePage, isDisabled, pagesQuantity, inputSegmentSeparator, variant, size} = state;

  const {
    inputSeparator,
    inputSegmentQuantity,
    pages: pagesStyles,
  } = useMultiStyleConfig('Pagination', {variant, size});

  const handleChange = (value: string | null) => {
    if (!value) {
      return;
    }

    changePage(+value);
  };

  return (
    <HStack
      isInline
      spacing={2}
      sx={pagesStyles}
      data-testid={suffixTestId('inputSegmentWrapper', props)}
    >
      <TextInput
        value={activePage.toString()}
        onChange={handleChange}
        type="number"
        isDisabled={isDisabled}
        data-testid={suffixTestId('inputSegment', props)}
      />
      <Box __css={inputSeparator}>{inputSegmentSeparator}</Box>
      <Box __css={inputSegmentQuantity} data-testid={suffixTestId('inputSegmentQuantity', props)}>
        {pagesQuantity}
      </Box>
    </HStack>
  );
}
