import {Variants} from 'framer-motion';

import {useRef} from 'react';

import {useDisclosure, useOutsideClick} from '@chakra-ui/hooks';
import {usePopper} from '@chakra-ui/popper';

import {Placement} from '../../../types/Placement';
import {AnimatedPopper, AnimatedPopperProps} from '../AnimatedPopper';
import {defaultVariants} from '../constants/defaultVariants';

interface UseAnimatedPopperProps {
  placement?: Placement;
  variants?: Variants;
  gutter?: number;
  /**
   * For debugg purposes
   */
  forceOpen?: boolean;
  /**
   * Prevents the popper from closing on outide click
   */
  isOutsideClickIgnored?: boolean;
  strategy?: 'absolute' | 'fixed';
}

interface UseAnimatedPopperReturnType {
  isOpen: boolean;
  popperProps: AnimatedPopperProps;
  Popper: typeof AnimatedPopper;
  openPopper: () => void;
  closePopper: () => void;
  togglePopper: () => void;
  referenceRef: <T extends Element>(node: T | null) => void;
}

const defaultProps: UseAnimatedPopperProps = {
  placement: 'top-start',
  gutter: 0,
  variants: defaultVariants,
  strategy: 'absolute',
};

export const useAnimatedPopper = (
  props: UseAnimatedPopperProps = defaultProps
): UseAnimatedPopperReturnType => {
  const {placement, variants, gutter, strategy} = {
    ...defaultProps,
    ...props,
  } as Required<UseAnimatedPopperProps>;

  const {isOpen, onOpen, onClose, onToggle} = useDisclosure({isOpen: props.forceOpen});

  const {getPopperProps, referenceRef, transformOrigin} = usePopper({
    gutter,
    placement,
    strategy,
    preventOverflow: true,
    flip: true,
  });

  const clickRef = useRef<HTMLDivElement>(null);

  const popperProps = getPopperProps();
  useOutsideClick({
    ref: clickRef,
    handler: onClose,
    enabled: !props.isOutsideClickIgnored,
  });

  return {
    isOpen,
    popperProps: {
      isOpen,
      popperProps,
      transformOrigin,
      variants,
      clickRef,
    },
    openPopper: onOpen,
    closePopper: onClose,
    togglePopper: onToggle,
    referenceRef,
    Popper: AnimatedPopper,
  };
};
