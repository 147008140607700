import {routes, testIds} from '@omnetic-admin-service/shared';
import {
  DataStatus,
  openDialog,
  showNotification,
  Table,
  TableRow,
  TableRowActionsData,
  Tooltip,
  copyToClipboard,
} from 'platform/components';
import {Clickable, Text} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {composePath} from 'shared';

import {
  useGetTenantListQuery,
  useMarkTenantMutation,
  useResetHelpDeskPasswordMutation,
  useUnmarkTenantMutation,
} from '../../api/adminApi';
import {PageHeader} from '../../components/PageHeader/PageHeader';
import {i18n} from '../../i18n/config';
import {TenantResponseBody} from '../../types';
import {handleApiError} from '../../utils/handleApiError';
import {CreateTenant} from './components/CreateTenant';
import {ShowGeneratedPasswordDialog} from './components/ShowGeneratedPasswordDialog';

export function TenantOverview() {
  const navigate = useNavigate();

  const {data, isLoading, isError} = useGetTenantListQuery();
  const [markTenant] = useMarkTenantMutation();
  const [unMarkTenant] = useUnmarkTenantMutation();
  const [getNewPassword] = useResetHelpDeskPasswordMutation();

  const handleMarkAction = (tenant: TenantResponseBody) => {
    const action = tenant.isMarked ? unMarkTenant : markTenant;
    action({tenantId: tenant.id}).unwrap().catch(handleApiError);
  };

  const onDetail = (id: string) => navigate(composePath(routes.tenantDetail, {params: {id}}));

  const handleResetPassword = async (id: string) => {
    await getNewPassword({tenantId: id})
      .unwrap()
      .then((newGeneratedPassword) => {
        showNotification.success(i18n.t('tenants.newPasswordGenerated'));
        openDialog(
          <ShowGeneratedPasswordDialog
            password={newGeneratedPassword.password}
            data-testid={testIds.tenantOverview('newPassword')}
          />,
          {
            title: i18n.t('tenants.newPassword'),
            size: 'small',
            'data-testid': testIds.tenantOverview('newPassword'),
          }
        );
      })
      .catch(handleApiError);
  };

  const onCreateTenantDialogOpen = () =>
    openDialog(<CreateTenant data-testid={testIds.tenantOverview('createTenant')} />, {
      title: i18n.t('tenants.createTenant'),
      'data-testid': testIds.tenantOverview('createTenant'),
    });

  const getTableRowActions = (tenant: TenantResponseBody) => {
    const actions: TableRowActionsData = {
      primary: [
        {
          title: i18n.t('tenants.markTenant', {name: tenant.name}),
          description: i18n.t('tenants.markTenantDescription'),
          icon: tenant.isMarked ? 'action/check_circle' : 'action/check_circle_outline',
          severity: tenant.isMarked ? 'warning' : undefined,
          onClick: () => handleMarkAction(tenant),
        },
        {
          title: i18n.t('common.openDetail'),
          icon: 'action/open_in_new',
          onClick: () => onDetail(tenant.id),
        },
        {
          title: i18n.t('tenants.resetPassword'),
          icon: 'action/autorenew',
          onClick: () => handleResetPassword(tenant.id),
        },
      ],
    };
    return actions;
  };

  return (
    <>
      <PageHeader
        data-testid={testIds.tenantOverview('overview')}
        title={i18n.t('tenants.pageTitle')}
        description={i18n.t('tenants.pageDescription')}
        isLoading={isLoading}
        actions={[
          {
            type: 'button',
            variant: 'primary',
            title: i18n.t('tenants.createTenant'),
            onClick: onCreateTenantDialogOpen,
          },
        ]}
      />
      <DataStatus isLoading={isLoading} isError={isError} isEmpty={!data?.length} minHeight={50}>
        <Table tableLayout="fixed" data-testid={testIds.tenantOverview('tenants')}>
          <TableRow actionsWidth={21}>
            <Text alternative size="small">
              {i18n.t('common.id')}
            </Text>
            <Text alternative size="small">
              {i18n.t('common.name')}
            </Text>
            <Text alternative size="small">
              {i18n.t('common.workspace')}
            </Text>
            <Text alternative size="small">
              {i18n.t('common.host')}
            </Text>
          </TableRow>
          {data?.map((tenant) => (
            <TableRow
              key={tenant.id}
              data-testid={testIds.tenantOverview(`tenantsRow-${tenant.id}`)}
              actions={getTableRowActions(tenant)}
            >
              <Tooltip
                label={i18n.t('common.clickMeToCopy')}
                description={tenant.id}
                placement="top-start"
              >
                <Clickable onClick={() => copyToClipboard(tenant.id)}>
                  <Text
                    data-testid={testIds.tenantOverview(`tenantsRow-${tenant.id}-id`)}
                    size="small"
                    noWrap
                  >
                    {tenant.id}
                  </Text>
                </Clickable>
              </Tooltip>
              <Tooltip
                label={i18n.t('common.name')}
                description={tenant.name}
                placement="top-start"
              >
                <Text
                  data-testid={testIds.tenantOverview(`tenantsRow-${tenant.id}-name`)}
                  size="small"
                  noWrap
                  alternative
                >
                  {tenant.name}
                </Text>
              </Tooltip>
              <Tooltip
                label={i18n.t('common.workspace')}
                description={tenant.workspace}
                placement="top-start"
              >
                <Text
                  data-testid={testIds.tenantOverview(`tenantsRow-${tenant.id}-workspace`)}
                  size="small"
                  noWrap
                >
                  {tenant.workspace}
                </Text>
              </Tooltip>
              <Tooltip
                label={i18n.t('common.host')}
                description={tenant.host}
                placement="top-start"
              >
                <Text
                  data-testid={testIds.tenantOverview(`tenantsRow-${tenant.id}-host`)}
                  size="small"
                  noWrap
                >
                  {tenant.host}
                </Text>
              </Tooltip>
            </TableRow>
          ))}
        </Table>
      </DataStatus>
    </>
  );
}
