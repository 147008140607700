import {isNotNil} from 'ramda-adjunct';

// eslint-disable-next-line eag/no-date-parsing
const invalidDate = new Date('Invalid Date');

/**
 * Default leap year to use new Date to get Date where is posibility to get 29.2
 */
const DEFAULT_LEAP_YEAR = 2000;

/**
 * Returns date for selected month and day or default date
 */
export const getDateValueFromDayMonth = (month: number, day?: number) => {
  if (month < 1 || month > 12 || (isNotNil(day) && (day < 1 || day > 31))) return invalidDate;

  const date = new Date(DEFAULT_LEAP_YEAR, month - 1, day ?? 1);

  if (date.getMonth() + 1 !== month) return invalidDate;

  return date;
};
