import {ComponentStyleConfig} from '@chakra-ui/react';
import {CSSObject} from '@chakra-ui/styled-system';

import {BadgeColorScheme} from './Badge';

export const badgeTheme: ComponentStyleConfig = {
  baseStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'full',
    fontWeight: 'medium',
    textAlign: 'center',
  },
  sizes: {
    default: {
      minW: '24px',
      w: 'auto',
      h: '24px',
      py: '2px',
      px: '8px',
      fontSize: 'text.small',
      lineHeight: 'text.small',
      fontWeight: 'medium',
      letterSpacing: 0,
      svg: {
        w: '16px',
        h: '16px',
        mx: '-4px',
      },
    },
    small: {
      w: 'auto',
      minW: '16px',
      h: '16px',
      fontSize: 'text.xSmall',
      lineHeight: 'text.xSmall',
      fontWeight: 'medium',
      letterSpacing: 0,
      py: 0,
      px: '4px',
      svg: {
        w: '12px',
        h: '12px',
        mx: '-4px',
      },
    },
  },
  variants: {
    bold: (props) => ({
      ...ColorSchemeMapping.bold[props.colorScheme as BadgeColorScheme],
    }),
    subtle: (props) => ({
      ...ColorSchemeMapping.subtle[props.colorScheme as BadgeColorScheme],
    }),
  },
  defaultProps: {
    colorScheme: 'green',
    variant: 'bold',
    size: 'default',
  },
};

const ColorSchemeMapping: Record<string, Record<BadgeColorScheme, CSSObject>> = {
  bold: {
    blue: {
      bg: 'palettes.blue.70.100',
      color: 'palettes.white.10.100',
    },
    green: {
      bg: 'palettes.green.60.100',
      color: 'palettes.white.10.100',
    },
    magenta: {
      bg: 'palettes.magenta.60.100',
      color: 'palettes.white.10.100',
    },
    neutral: {
      bg: 'palettes.neutral.300.100',
      color: 'palettes.white.10.100',
    },
    orange: {
      bg: 'palettes.orange.60.100',
      color: 'palettes.white.10.100',
    },
    purple: {
      bg: 'palettes.purple.60.100',
      color: 'palettes.white.10.100',
    },
    red: {
      bg: 'palettes.red.60.100',
      color: 'palettes.white.10.100',
    },
    teal: {
      bg: 'palettes.teal.60.100',
      color: 'palettes.white.10.100',
    },
    yellow: {
      bg: 'palettes.yellow.60.100',
      color: 'palettes.neutral.800.100',
    },
  },
  subtle: {
    blue: {
      bg: 'palettes.blue.20.100',
      color: 'palettes.blue.80.100',
    },
    green: {
      bg: 'palettes.green.20.100',
      color: 'palettes.green.80.100',
    },
    magenta: {
      bg: 'palettes.magenta.20.100',
      color: 'palettes.magenta.80.100',
    },
    neutral: {
      bg: 'palettes.neutral.20.100',
      color: 'text.primary',
    },
    orange: {
      bg: 'palettes.orange.20.100',
      color: 'palettes.orange.80.100',
    },
    purple: {
      bg: 'palettes.purple.20.100',
      color: 'palettes.purple.80.100',
    },
    red: {
      bg: 'palettes.red.20.100',
      color: 'palettes.red.80.100',
    },
    teal: {
      bg: 'palettes.teal.20.100',
      color: 'palettes.teal.80.100',
    },
    yellow: {
      bg: 'palettes.neutral.20.100',
      color: 'text.primary',
    },
  },
};
