import {getSize, Text, TextProps} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {MouseEventHandler, ReactElement, ReactNode} from 'react';

import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  PortalManager,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {MENU_MAX_HEIGHT} from '../../constants/menuMaxHeight';
import {Placement} from '../../types/Placement';
import {Button, ButtonProps} from '../Button/Button';

export type DropdownSizeType = 'default';
export type DropdownVariantType = 'default' | 'dark';

export interface DropdownProps extends TestIdProps {
  dropdownControl: ReactElement<ButtonProps, typeof Button> | ReactElement<TextProps, typeof Text>;
  children: ReactNode;
  placement?: Placement;
  isOpen?: boolean;
  defaultIsOpen?: boolean;
  autoSelect?: boolean;
  closeOnSelect?: boolean;
  closeOnBlur?: boolean;
  isLazy?: boolean;
  onOpen?(): void;
  onClose?(): void;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  strategy?: 'fixed' | 'absolute';
  boundary?: 'clippingParents' | 'scrollParent' | HTMLElement;
  variant?: DropdownVariantType;
  lazyBehavior?: 'unmount' | 'keepMounted';
  size?: DropdownSizeType;
  isHeightLimited?: boolean;
}

export function Dropdown({children, dropdownControl, ...props}: DropdownProps) {
  const {button: buttonStyles} = useMultiStyleConfig('Menu', props);
  const theme = useTheme();

  return (
    <Menu {...props} data-testid={suffixTestId('dropdownMenu', props)}>
      <MenuButton
        onClick={props.onButtonClick}
        as={Box}
        sx={buttonStyles}
        data-testid={suffixTestId('dropdownMenuButton', props)}
      >
        {dropdownControl}
      </MenuButton>
      <PortalManager zIndex={theme.zIndices.DROPDOWN_COMPONENT}>
        <Portal>
          <MenuList
            maxHeight={props.isHeightLimited ? getSize(MENU_MAX_HEIGHT) : undefined}
            overflowY="auto"
            data-testid={suffixTestId('dropdownMenuList', props)}
          >
            {children}
          </MenuList>
        </Portal>
      </PortalManager>
    </Menu>
  );
}
