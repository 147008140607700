import flagsmith from 'flagsmith';
import {ITraits} from 'flagsmith/types';

import {useState, useCallback, useEffect} from 'react';

interface UseFlagSmithProps {
  environmentID: string;
  identity?: string;
  apiUrl: string;
  skip?: boolean;
}

export function useFlagsmith({environmentID, identity, apiUrl, skip}: UseFlagSmithProps) {
  const [isFlagSmithInitialized, setIsInitialized] = useState<boolean>(false);

  const handleInitFlagsmith = useCallback(
    (userIdentity?: string, traits?: ITraits) => {
      flagsmith
        .init({
          environmentID,
          api: apiUrl,
          identity: userIdentity || identity,
          cacheFlags: true,
          traits,
        })
        .then(() => setIsInitialized(true))
        .catch(() => setIsInitialized(false));
    },
    [environmentID, identity, apiUrl]
  );

  useEffect(() => {
    !skip && handleInitFlagsmith();

    return () => {
      flagsmith.logout();
    };
  }, []);

  return {isFlagSmithInitialized, handleInitFlagsmith};
}
