/**
 * Constructs an object type by mapping each property key `K` from a union `U` to its own value.
 * This utility type is useful when you want to create an object where the property names are
 * the same as the property values.
 *
 * @template U - A union of `PropertyKey`s, which include `string`, `number`, or `symbol` types.
 *
 * @example
 * // Given a union of literal types:
 * type ActionTypes = "CREATE" | "READ" | "UPDATE" | "DELETE";
 *
 * // The resulting type would be:
 * type SameValueActionType = UnionToSameValueObject<ActionTypes>;
 * // Equivalent to:
 * // {
 * //   CREATE: "CREATE";
 * //   READ: "READ";
 * //   UPDATE: "UPDATE";
 * //   DELETE: "DELETE";
 * // }
 *
 * @returns An object type with properties `K` from union `U`, where the value of each property is the name of the key itself.
 */
export type UnionToSameValueObject<U extends PropertyKey> = {
  [K in U]: K;
};
