/**
 * @description
 * Returns round value by step or nearest step in array by value
 *
 * @param {number} value - value for rounding
 * @param {number | number[]} step - step value or array
 *
 * @example
 * // return 1
 * roundToStep(0.9, 1);
 *
 * // return 1
 * roundToStep(1.9, [1, 5, 10]);
 */

export const roundToStep = (value: number, step: number | number[]) => {
  if (Array.isArray(step))
    return step.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );

  return Math.round(value / step) * step;
};
