import {AnimatePresence, AnimatePresenceProps, motion, Variants} from 'framer-motion';

import {FC, PropsWithChildren, ReactNode, RefObject} from 'react';

import {UsePopperReturn} from '@chakra-ui/popper';

import {suffixTestId, TestIdProps} from 'shared';

type PopperProps = ReturnType<UsePopperReturn['getPopperProps']>;

const transition = {
  duration: 0.15,
  easings: 'easeInOut',
};

export interface AnimatedPopperProps extends TestIdProps {
  isOpen: boolean;
  popperProps: PopperProps;
  transformOrigin: string;
  variants: Variants;
  clickRef: RefObject<HTMLDivElement>;
  children?: ReactNode;
}

export function AnimatedPopper(props: AnimatedPopperProps) {
  // workaround https://github.com/framer/motion/issues/1509
  const AnimatePresenceWrapper: FC<PropsWithChildren<AnimatePresenceProps>> = AnimatePresence;

  /* There is style relying on this class */
  /* eslint-disable react/forbid-dom-props */
  return (
    <div
      {...props.popperProps}
      data-testid={suffixTestId('animatedPopperWrapper', props)}
      className="ref-div"
      style={{zIndex: 999}}
    >
      <AnimatePresenceWrapper>
        {props.isOpen && (
          <motion.div
            data-testid={suffixTestId('animatedPopperContent', props)}
            transition={transition}
            variants={props.variants}
            initial="exit"
            animate="enter"
            exit="exit"
            style={{
              transformOrigin: props.transformOrigin,
            }}
            ref={props.clickRef}
          >
            {props.children}
          </motion.div>
        )}
      </AnimatePresenceWrapper>
    </div>
  );
}
