import {match, Pattern} from 'ts-pattern';

import {ReactNode} from 'react';

import {always, has, not} from 'ramda';
import {isFalse, isNull, isTruthy, isUndefined} from 'ramda-adjunct';

import {isFeatureEnabled, Permission, hasPermission} from 'shared';

import {useDevice} from '../DeviceProvider/DeviceProvider';

interface ShowProps {
  when?: unknown;
  whenFeatureEnabled?: string;
  whenFeatureDisabled?: string;
  whenHasPermission?: Permission;
  whenWithoutPermission?: Permission;
  children: ReactNode | ReactNode[];
  onMobile?: boolean;
  onTablet?: boolean;
  onNotebook?: boolean;
  onDesktop?: boolean;
  inPrint?: boolean;
}

export function Show(props: ShowProps) {
  const hasWhen = has('when', props);
  const showOnMobile = isTruthy(props.onMobile);
  const showOnTablet = isTruthy(props.onTablet);
  const showOnNotebook = isTruthy(props.onNotebook);
  const showOnDesktop = isTruthy(props.onDesktop);
  const showInPrint = isTruthy(props.inPrint);
  const isResponsive =
    showOnMobile || showOnTablet || showOnNotebook || showOnDesktop || showInPrint;

  const device = useDevice();

  const isVisible = match([isResponsive, device, props])
    .with([false, Pattern.any, Pattern.any], always(true))
    .with([true, 'mobile', {onMobile: true}], always(true))
    .with([true, 'tablet', {onTablet: true}], always(true))
    .with([true, 'notebook', {onNotebook: true}], always(true))
    .with([true, 'desktop', {onDesktop: true}], always(true))
    .with([true, 'print', {inPrint: true}], always(true))
    .otherwise(always(false));

  if (not(isVisible)) return null;

  if (isNull(props.when) || (hasWhen && isUndefined(props.when)) || isFalse(props.when))
    return null;

  if (props.whenFeatureEnabled && !isFeatureEnabled(props.whenFeatureEnabled)) return null;
  if (props.whenFeatureDisabled && isFeatureEnabled(props.whenFeatureDisabled)) return null;
  if (props.whenHasPermission && !hasPermission(props.whenHasPermission)) return null;
  if (props.whenWithoutPermission && hasPermission(props.whenWithoutPermission)) return null;

  return <>{props.children}</>;
}
