import {Box, ThemeColorPath} from 'platform/foundation';

export const levelColors: ThemeColorPath[] = [
  'palettes.neutral.40.100',
  'palettes.red.60.100',
  'palettes.orange.60.100',
  'palettes.yellow.70.100',
  'palettes.green.60.100',
  'palettes.blue.60.100',
];

interface LevelProps {
  value?: number;
}

export function Level(props: LevelProps) {
  const bgColor = levelColors[props.value ?? 0];

  return <Box width={1} height={4} borderRadius="xSmall" backgroundColor={bgColor} />;
}
