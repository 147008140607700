import {
  LicenseResponseBody,
  ListProjectsApiResponse,
  ListTenantLicensesApiResponse,
  ModuleResponseBody,
  ProjectResponseBody,
} from '../../types';

type Option = {
  label: string;
  value: string;
};

export const getActiveOptions = <T extends Pick<ProjectResponseBody, 'name' | 'id' | 'archived'>>(
  items?: T[],
  lisenses?: ListTenantLicensesApiResponse,
  editedLicense?: string
) =>
  items?.reduce((filteredItems: Option[], item) => {
    const isAlreadyAssigned = lisenses?.some((lisense) => {
      if (editedLicense === item.id) return false;
      return lisense.licenseId === item.id;
    });
    if (!item.archived && !isAlreadyAssigned) {
      const option = {
        label: item.name,
        value: item.id,
      };
      filteredItems.push(option);
    }
    return filteredItems;
  }, []);

const getModules = (projects?: ListProjectsApiResponse, projectId?: string) => {
  const projectById = projects?.find((project) => project.id === projectId);
  return projectById?.modules;
};

const getLicenses = (projects?: ListProjectsApiResponse, projectId?: string, moduleId?: string) => {
  const moduleById = getModules(projects, projectId)?.find((module) => module.id === moduleId);
  return moduleById?.licenses;
};

type ModuleOptionsArgs = {
  projects?: ListProjectsApiResponse;
  projectId?: string;
};

export const getModuleOptions = (args: ModuleOptionsArgs) => {
  const modules = getModules(args.projects, args.projectId);
  return getActiveOptions<ModuleResponseBody>(modules);
};

type LicenseOptionsArgs = {
  projects?: ListProjectsApiResponse;
  tenantLisenses?: ListTenantLicensesApiResponse;
  projectId?: string;
  moduleId?: string;
  editedLicense?: string;
};

export const getLicenseOptions = (args: LicenseOptionsArgs) => {
  const lisenses = getLicenses(args.projects, args.projectId, args.moduleId);
  return getActiveOptions<LicenseResponseBody>(lisenses, args.tenantLisenses, args.editedLicense);
};
