import {getPixelsFromRem, Show, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {Ref, useMemo, useRef} from 'react';
import ReactSelect, {OnChangeValue} from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

import {defaultTo, isNil, reject} from 'ramda';
import {isNotArray} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {MENU_MAX_HEIGHT} from '../../constants/menuMaxHeight';
import {FormControlProps} from '../../types/FormControlProps';
import {OptionTypeBase} from '../../types/OptionTypeBase';
import {ChoiceProvider} from '../Choice/hooks/choiceContext';
import {ChoiceCommonProps} from '../Choice/types';
import {selectComponents} from '../Choice/utils/components';
import {formatOptionFieldLabel} from '../Choice/utils/formatOptionFieldLabel';
import {getChoiceCSS} from '../Choice/utils/getChoiceCSS';
import {getFlattenedOptions} from '../Choice/utils/getFlattenedOptions';
import {reactSelectStyles} from '../Choice/utils/reactSelectStyles';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {useTranslationContext} from '../TranslationProvider/TranslationProvider';

export interface MultiChoiceProps<ValueType>
  extends FormControlProps<ValueType[] | null>,
    TestIdProps,
    ChoiceCommonProps<ValueType[], ValueType> {}

const rejectNil = reject(isNil);

function selectOption<ValueType>(select: Select<OptionTypeBase<ValueType | Nullish>, true>) {
  // eslint-disable-next-line no-restricted-syntax
  const menuRef: Ref<HTMLDivElement> = select?.menuListRef as unknown as Ref<HTMLDivElement>;
  if (!menuRef) return;
  if (typeof menuRef !== 'object') return;

  const menu = menuRef.current;
  if (!menu) return;
  if (!menu.querySelector) return;

  const selectedOption: HTMLElement | null = menu.querySelector('*[data-selected="true"]');
  if (!selectedOption) return;

  selectedOption.focus();
}

export function MultiChoice<ValueType>(props: MultiChoiceProps<ValueType>) {
  const selectRef = useRef<Select<OptionTypeBase<ValueType | Nullish>, true> | null>(null);
  const t = useTranslationContext();
  const theme = useTheme();

  const isInvalid = props.isInvalid || !!props.errorMessage;

  const onSelectMount = (select: Select<OptionTypeBase<ValueType | Nullish>, true> | null) => {
    if (select) {
      selectOption(select);
    }

    selectRef.current = select;
  };

  const onMenuOpen = () => {
    if (selectRef.current) {
      selectOption(selectRef.current);
    }
  };

  const handleChange = (options: OnChangeValue<OptionTypeBase<ValueType | Nullish>, true>) => {
    if (!options) {
      props.onChange?.(null);
      return;
    }

    if (options.length === 0) selectRef.current?.blur();

    props.onChange?.(rejectNil(options.map((v) => v.value)));
  };

  const getOptionsByValue = (value: ValueType[] | Nullish) => {
    if (isNotArray(value) || isNil(value)) return null;

    const flatOptions = getFlattenedOptions(props.options);

    return rejectNil(value.map((v) => flatOptions?.find((o) => o.value === v)));
  };

  // This is a workaround for an issue in react-select, when using multiselect with custom components
  // https://github.com/JedWatson/react-select/issues/5489
  const components = useMemo(() => selectComponents<ValueType, true>(), []);

  return (
    <VStack>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        isRecommended={props.isRecommended}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <ChoiceProvider
        options={{
          'data-testid': props['data-testid'],
          hasOptionCheckbox: props.hasOptionCheckbox,
        }}
      >
        <ReactSelect
          isMulti
          formatOptionLabel={props.formatOptionLabel ?? formatOptionFieldLabel}
          defaultValue={getOptionsByValue(props.defaultValue)}
          ref={onSelectMount}
          menuPortalTarget={props.menuInPortal ? document.body : undefined}
          value={getOptionsByValue(props.value)}
          name={props.name}
          placeholder={defaultTo(t('general.labels.select'), props.placeholder)}
          onInputChange={() => {
            /**
             * workaround
             * https://github.com/JedWatson/react-select/issues/3832
             */
            if (props.menuIsOpen) selectRef?.current?.inputRef?.focus();
          }}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onChange={handleChange}
          isDisabled={props.isDisabled}
          options={props.options}
          isClearable={!props.isNotClearable}
          isSearchable={props.isSearchable ?? true}
          components={components}
          hideSelectedOptions={false}
          menuPlacement={props.menuPlacement ?? 'auto'}
          maxMenuHeight={getPixelsFromRem(props.maxMenuHeight ?? MENU_MAX_HEIGHT)}
          styles={reactSelectStyles<ValueType, true>(theme)}
          onMenuOpen={onMenuOpen}
          noOptionsMessage={props.noOptionsMessage}
          closeMenuOnScroll={props.closeMenuOnScroll}
          closeMenuOnSelect={props.closeMenuOnSelect}
          isLoading={props.isLoading}
          filterOption={props.filterOption}
          // Styling ReactSelect is problematic so we need to use the css prop
          // eslint-disable-next-line react/forbid-component-props
          css={getChoiceCSS(isInvalid, props.isDisabled, props.isRecommended)}
        />
      </ChoiceProvider>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
