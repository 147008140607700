import {HStack, Text} from 'platform/foundation';

import {Children, ComponentType, FC} from 'react';
import {components as reactSelectComponents, GroupBase, SelectComponentsConfig} from 'react-select';

import {
  Box,
  chakra,
  Flex,
  Tag,
  TagLabel,
  SystemProps,
  TagCloseButton,
  TagCloseButtonProps,
} from '@chakra-ui/react';
import {cx} from '@chakra-ui/utils';

import {Nullish, suffixTestId, noop} from 'shared';

import {OptionTypeBase} from '../../../types/OptionTypeBase';
import {Checkbox} from '../../Checkbox/Checkbox';
import {IconButton} from '../../IconButton/IconButton';
import {useChoice} from '../hooks/choiceContext';

export const selectComponents = <ValueType, IsMulti extends boolean>(): SelectComponentsConfig<
  OptionTypeBase<ValueType | Nullish>,
  IsMulti,
  GroupBase<OptionTypeBase<ValueType | Nullish>>
> => ({
  Control: function Control({children, innerRef, innerProps, isDisabled}) {
    const context = useChoice();
    const {css, ...otherProps} = innerProps;

    return (
      <Flex
        className="eag--choice__control"
        data-testid={suffixTestId('choiceControl', context)}
        ref={innerRef}
        sx={{
          maxHeight: '32px',
          bg: 'palettes.white.10.100',
          color: 'text.primary',
          fontSize: 'text.small',
          lineHeight: 'text.small',
          fontWeight: 'text.default',
          padding: '5px 8px',
          boxSizing: 'border-box',
          px: '8px',
          _autofill: {
            bg: 'palettes.yellow.20.100',
          },
          '*[data-focused], *:focus': {
            boxShadow: 'none',
          },
          '.eag--choice__placeholder': {
            fontSize: 'text.small',
            lineHeight: 'text.small',
            fontWeight: 'text.default',
          },
          '.eag--choice__control': {
            border: '1px solid transparent',
            background: 'none',
            cursor: 'pointer',
            boxShadow: 'none',
            borderColor: 'transparent',
            p: 0,
            _hover: {
              boxShadow: 'none',
              borderColor: 'transparent',
            },
            _focus: {
              boxShadow: 'none',
              borderColor: 'transparent',
            },
            _active: {
              boxShadow: 'none',
              borderColor: 'transparent',
            },
            _disabled: {
              borderColor: 'transparent',
            },
            '.eag--choice__value-container': {
              flexDir: 'row',
              pe: 0,
              '> *': {
                position: 'relative',
                maxW: 'none',
                minW: 'min-content',
                m: 0,
                mt: '2px',
                mb: '2px',
                top: 'auto',
                transform: 'none',
              },
              '> input': {
                position: 'absolute',
                cursor: 'pointer',
              },
            },
          },
          _placeholder: {
            color: 'text.tertiary',
          },
          _hover: {
            borderColor: 'severity.informational',
            _disabled: {
              borderColor: 'palettes.neutral.70.40',
            },
          },
          _focus: {
            borderColor: 'severity.informational',
            boxShadow: 'active',

            _disabled: {
              boxShadow: 'none',
              borderColor: 'palettes.neutral.70.40',
            },
          },
          _disabled: {
            borderColor: 'palettes.neutral.70.40',
            bg: 'palettes.white.10.40',
            cursor: 'not-allowed',
            boxShadow: 'none',
            _focus: {
              borderColor: 'palettes.neutral.70.40',
            },
          },
          maxH: 'none',
          minHeight: 0,
          border: '1px solid',
          borderRadius: 'small',
          borderColor: 'palettes.neutral.70.100',
          pl: '8px',
          '.eag--choice__value-container': {
            gap: '4px',
          },
          '.eag--choice__indicators-container': {
            height: '18px',
            alignSelf: 'center',
          },
          '.eag--choice__menu': {
            maxW: '560px',
            minW: '216px',
          },
          h: 'auto',
          minH: '32px',
          justifyContent: 'space-between',
        }}
        {...otherProps}
        {...(isDisabled && {disabled: true})}
      >
        {children}
      </Flex>
    );
  },
  Placeholder: function Placeholder({children, ...props}) {
    const context = useChoice();

    return (
      <reactSelectComponents.Placeholder
        {...props}
        className={cx('eag--choice__placeholder', props.className)}
        data-testid={suffixTestId('choicePlaceholder', context)}
      >
        <chakra.span
          __css={{
            color: 'text.tertiary',
          }}
          className={cx('eag--choice__placeholder--inner', props.className)}
          data-testid={suffixTestId('choicePlaceholderInner', context)}
        >
          {children}
        </chakra.span>
      </reactSelectComponents.Placeholder>
    );
  },
  NoOptionsMessage: ({children, ...props}) => {
    const context = useChoice();

    return (
      <reactSelectComponents.NoOptionsMessage
        {...props}
        className={cx('eag--choice__no-options', props.className)}
        data-testid={suffixTestId('choiceNoOptions', context)}
      >
        <chakra.span
          __css={{
            color: 'text.tertiary',
          }}
          className={cx('eag--choice__no-options-inner', props.className)}
          data-testid={suffixTestId('choiceNoOptionsInner', context)}
        >
          {children}
        </chakra.span>
      </reactSelectComponents.NoOptionsMessage>
    );
  },
  ValueContainer: function ValueContainer({children, ...props}) {
    const context = useChoice();
    const className = cx(props.className, 'eag--choice__value-container');

    return (
      <reactSelectComponents.ValueContainer
        data-testid={suffixTestId('choiceValueContainer', context)}
        {...props}
        className={className}
      >
        {children}
      </reactSelectComponents.ValueContainer>
    );
  },
  MultiValueContainer: function MultiValueContainer({children, innerProps}) {
    const context = useChoice();
    const {valueTagVariant, tagSize, choiceTagStyles} = context;

    return (
      <Box
        data-testid={suffixTestId('choiceMultiValueContainer', context)}
        className="eag--choice__multi-value-container"
        maxWidth="100%"
      >
        <Tag {...innerProps} sx={choiceTagStyles} variant={valueTagVariant} size={tagSize}>
          {children}
        </Tag>
      </Box>
    );
  },
  MultiValueLabel: function MultiValueLabel({children, innerProps}) {
    const context = useChoice();
    return (
      <TagLabel
        whiteSpace="normal"
        {...innerProps}
        data-testid={suffixTestId('choiceMultiValueLabel', context)}
        className={cx('eag--choice__multi-value-label', innerProps?.className)}
      >
        {children}
      </TagLabel>
    );
  },
  MultiValueRemove: function MultiValueRemove({children, innerProps}) {
    const context = useChoice();

    const {css, ...otherProps} = innerProps;

    return (
      <TagCloseButton
        {...(otherProps as TagCloseButtonProps)}
        data-testid={suffixTestId('choiceMultiValueRemove', context)}
        className={cx('eag--choice__multi-value-remove', 'eag--tag-close', innerProps?.className)}
      >
        {children}
      </TagCloseButton>
    );
  },
  IndicatorsContainer: function IndicatorsContainer({children, ...props}) {
    const context = useChoice();
    const childArray = Children.toArray(children);

    return (
      <reactSelectComponents.IndicatorsContainer
        {...props}
        data-testid={suffixTestId('choiceIndicatorContainer', context)}
        className={cx('eag--choice__indicators-container', props.className)}
      >
        {childArray}
      </reactSelectComponents.IndicatorsContainer>
    );
  },
  IndicatorSeparator: function IndicatorSeparator() {
    return null;
  },
  ClearIndicator: function ClearIndicator({innerProps}) {
    const context = useChoice();

    return (
      <div
        {...innerProps}
        data-testid={suffixTestId('choiceClearIndicatorClickableWrapper', context)}
      >
        <IconButton
          size="small"
          icon="navigation/close"
          data-testid={suffixTestId('choiceClearIndicatorIconButton', context)}
        />
      </div>
    );
  },
  DropdownIndicator: function DropdownIndicator({innerProps, isDisabled}) {
    const context = useChoice();

    return (
      <div
        {...innerProps}
        data-testid={suffixTestId('choiceDropdownIndicatorClickableWrapper', context)}
      >
        <IconButton
          size="small"
          icon="navigation/expand_more"
          isDisabled={isDisabled}
          data-testid={suffixTestId('choiceDropdownIndicatorIconButton', context)}
        />
      </div>
    );
  },
  // Menu components
  Menu: function Menu({children, ...props}) {
    const context = useChoice();
    return (
      <reactSelectComponents.Menu
        {...props}
        data-testid={suffixTestId('choiceMenu', context)}
        className={cx('eag--choice__menu', props.className)}
      >
        {children}
      </reactSelectComponents.Menu>
    );
  },
  MenuList: function MenuList({innerRef, children, maxHeight}) {
    const context = useChoice();
    const {
      menuStyles: {list},
    } = context;
    return (
      <Box
        sx={{
          ...list,
          maxH: `${maxHeight}px`,
          overflowY: 'auto',
          width: '100%',
          zIndex: 999,
        }}
        ref={innerRef}
        data-testid={suffixTestId('choiceMenuList', context)}
        className="eag--choice__menu-list"
      >
        {children}
      </Box>
    );
  },
  GroupHeading: function GroupHeading({children}) {
    const context = useChoice();
    const {
      menuStyles: {groupTitle},
    } = context;

    return (
      <Box
        sx={groupTitle}
        data-testid={suffixTestId('choiceGroupHeading', context)}
        className={cx('eag--choice__group-heading')}
      >
        {children}
      </Box>
    );
  },
  Option: function Option({innerRef, innerProps, children, isFocused, isDisabled, isSelected}) {
    const context = useChoice();
    const {menuStyles, hasOptionCheckbox} = context;
    const item = menuStyles.item as SystemProps;
    const {css, ...otherProps} = innerProps;

    return (
      <Box
        sx={{
          ...item,
          w: '100%',
          textAlign: 'left',
          bg: isFocused ? item?._focus?.bg : isSelected ? item?._active?.bg : 'transparent',
          ...(isDisabled && item?._disabled),
        }}
        ref={innerRef}
        {...otherProps}
        {...(isDisabled && {isDisabled: true})}
        data-testid={suffixTestId('choiceOption', context)}
        className="eag--choice__option"
        data-selected={isSelected ? 'true' : 'false'}
        data-focused={isFocused ? 'true' : 'false'}
      >
        {/* Checkbox native onFocus and onBlur events messing up react-select value selection [T20-22340] */}
        <Box pointerEvents="none">
          {hasOptionCheckbox ? (
            <HStack spacing={3}>
              <Checkbox onChange={noop} value={isSelected} isDisabled={isDisabled} />
              {/* @ts-ignore Children is typed as node by react-select - cant change that */}
              <Text size="small">{children}</Text>
            </HStack>
          ) : (
            children
          )}
        </Box>
      </Box>
    );
  },
  Input: function Input(props) {
    const context = useChoice();
    const className = cx(props.className, 'choice-component-input');
    return (
      <reactSelectComponents.Input
        data-testid={suffixTestId('choiceInput', context)}
        {...props}
        className={className}
      />
    );
  },
  SelectContainer: function SelectContainer(props) {
    const context = useChoice();
    const className = cx(props.className, 'choice-component-container');
    return (
      <reactSelectComponents.SelectContainer
        data-testid={suffixTestId('choiceComponentContainer', context)}
        {...props}
        className={className}
      />
    );
  },
  SingleValue: wrapWithClassName(reactSelectComponents.SingleValue, 'eag--choice__single-value'),
  MultiValue: wrapWithClassName(reactSelectComponents.MultiValue, 'eag--choice__multi-value'),
});

function wrapWithClassName<Props extends {className?: string}>(
  Base: ComponentType<Props>,
  className: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ComponentType<any> {
  const Wrapped: FC<Props> = (props) => (
    <Base {...props} className={cx(props.className, className)} />
  );
  Wrapped.displayName = `Wrapped(${Base.displayName})`;

  return Wrapped;
}
