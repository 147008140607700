import {Nullish} from '../types/Nullish';
import {isDefined} from './isDefined';

/**
 * Function: isDefinedNonZeroValue
 *
 * This utility function checks if a given variable is defined (i.e., not null or undefined) and not zero.
 * It is a TypeScript type guard function that refines the type within its scope when the function returns true.
 *
 * Parameters:
 * x: The input variable of type 'number' or Nullish (i.e., 'undefined' or 'null').
 *
 * Returns:
 * The function returns a boolean value, 'true' if 'x' is a defined non-zero number,
 * otherwise it returns 'false'.
 * The returned value also serves as a type guard, refining 'x' to 'number' when the function returns 'true'.
 */
export function isDefinedNonZeroValue(x: number | Nullish): x is number {
  return isDefined(x) && x !== 0;
}
