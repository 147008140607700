import {css, useTheme} from 'styled-components';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {ValueByDevice} from '../../types/ValueByDevice';

export type DisplayAlign = 'left' | 'center' | 'right';
export type DisplayColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'link'
  | 'white'
  | 'success'
  | 'warning'
  | 'informational';

export interface DisplayProps extends TestIdProps {
  size: 1;
  alternative?: true;
  color?: DisplayColor;
  children?: Nullish | string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  align?: DisplayAlign | ValueByDevice<DisplayAlign>;
}

export function Display(props: DisplayProps) {
  const theme = useTheme();

  const align = useResponsivePropValue(props.align);

  return (
    <div
      role="heading"
      aria-level={props.headingLevel ?? props.size}
      data-testid={suffixTestId('display', props)}
      css={css`
        color: ${theme.colors.text[props.color ?? 'primary']};
        font-family: ${theme.fonts.heading};
        font-size: ${theme.fontSizes.display[props.size]};
        line-height: ${theme.lineHeights.display[props.size]};
        font-weight: ${theme.fontWeights.display[props.alternative ? 'alternative' : 'default']};
        letter-spacing: 0;
        text-align: ${align};
      `}
    >
      {props.children}
    </div>
  );
}
