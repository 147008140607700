import {createApi} from '@reduxjs/toolkit/query/react';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  GetAllApiEnumsApiArg,
  GetAllApiEnumsApiResponse,
  GetApiMakeModelWithMakeApiArg,
  GetApiMakeModelWithMakeApiResponse,
  GetBillingInformationListApiResponse,
  GetBranchListApiResponse,
} from '@omnetic-dms/shared';

import {
  AddLicenseApiArg,
  AddLicenseApiResponse,
  AddModuleApiArg,
  AddModuleApiResponse,
  ArchiveLicenseApiArg,
  ArchiveLicenseApiResponse,
  ArchiveModuleApiArg,
  ArchiveModuleApiResponse,
  ArchiveProjectApiArg,
  ArchiveProjectApiResponse,
  AttachUseCaseApiArg,
  AttachUseCaseApiResponse,
  CreateProjectApiArg,
  CreateProjectApiResponse,
  CreateTenantApiArg,
  CreateTenantApiResponse,
  DeleteTenantLicenseApiArg,
  DeleteTenantLicenseApiResponse,
  DetachUseCaseApiArg,
  DetachUseCaseApiResponse,
  DuplicateLicenseApiArg,
  DuplicateLicenseApiResponse,
  ExportLicenseApiArg,
  ExportLicenseApiResponse,
  GetAuthorizationApiArg,
  GetAuthorizationApiResponse,
  GetAuthorizationProfileApiArg,
  GetAuthorizationProfileApiResponse,
  GetAuthorizationProfilesApiArg,
  GetAuthorizationProfilesApiResponse,
  GetAuthorizationsApiArg,
  GetAuthorizationsApiResponse,
  GetCorrespondingAddressListApiArg,
  GetCorrespondingAddressListApiResponse,
  GetLicenseTenantListApiArg,
  GetLicenseTenantListApiResponse,
  GetNationalSalesCompaniesApiResponse,
  GetNotAttachedUseCaseListApiArg,
  GetNotAttachedUseCaseListApiResponse,
  GetProjectApiArg,
  GetProjectApiResponse,
  GetTenantApiArg,
  GetTenantApiResponse,
  GetTenantKeyPairApiArg,
  GetTenantKeyPairApiResponse,
  GetTenantLicensingLogApiArg,
  GetTenantLicensingLogApiResponse,
  GetTenantListApiArg,
  GetTenantListApiResponse,
  GetUseCaseListApiArg,
  GetUseCaseListApiResponse,
  GetUseCaseListOption,
  ImportLicenseApiArg,
  ImportLicenseApiResponse,
  KeysApiArg,
  KeysApiResponse,
  ListProjectsApiArg,
  ListProjectsApiResponse,
  ListTenantLicensesApiArg,
  ListTenantLicensesApiResponse,
  MarkTenantApiArg,
  MarkTenantApiResponse,
  PatchAuthorizationApiArg,
  PatchAuthorizationApiResponse,
  PatchAuthorizationProfileApiArg,
  PatchAuthorizationProfileApiResponse,
  PostAuthorizationApiArg,
  PostAuthorizationApiResponse,
  PostAuthorizationProfileApiArg,
  PostAuthorizationProfileApiResponse,
  PostTenantLicenseApiArg,
  PostTenantLicenseApiResponse,
  QuickInfoApiArg,
  QuickInfoApiResponse,
  ResetHelpDeskPasswordApiArg,
  ResetHelpDeskPasswordApiResponse,
  UnmarkTenantApiArg,
  UnmarkTenantApiResponse,
  UpdateLicenseApiArg,
  UpdateLicenseApiResponse,
  UpdateModuleApiArg,
  UpdateModuleApiResponse,
  UpdateProjectApiArg,
  UpdateProjectApiResponse,
  UpdateTenantApiArg,
  UpdateTenantApiResponse,
  UpdateTenantLicenseApiArg,
  UpdateTenantLicenseApiResponse,
  ValidateImportLicenseApiArg,
  ValidateImportLicenseApiResponse,
} from '../types';
import {getWordsFromLowerCase} from '../utils/getWordsFromLowerCase';
import {adminBaseQuery} from './baseQuery/baseQuery';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: adminBaseQuery,
  tagTypes: [
    'tenantList',
    'tenant',
    'tenantLicenses',
    'projectList',
    'quickInfo',
    'project',
    'authorizations',
    'authorizationProfiles',
    'enums',
    'makes',
    'branches',
    'companies',
    'addresses',
    'billingInformation',
    'resetHelpDeskPassword',
  ],
  endpoints: (build) => ({
    quickInfo: build.query<QuickInfoApiResponse, QuickInfoApiArg>({
      query: () => ({url: `/admin/v1/dashboard/quick-info`}),
      providesTags: ['quickInfo'],
    }),
    addLicense: build.mutation<AddLicenseApiResponse, AddLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license`,
        method: 'POST',
        body: queryArg.addLicenseRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    addModule: build.mutation<AddModuleApiResponse, AddModuleApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module`,
        method: 'POST',
        body: queryArg.addModuleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    archiveLicense: build.mutation<ArchiveLicenseApiResponse, ArchiveLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/${queryArg.licenseId}/archive`,
        method: 'POST',
        invalidatesTags: ['projectList'],
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    archiveModule: build.mutation<ArchiveModuleApiResponse, ArchiveModuleApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/archive`,
        method: 'POST',
        invalidatesTags: ['projectList'],
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    archiveProject: build.mutation<ArchiveProjectApiResponse, ArchiveProjectApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/archive`,
        method: 'POST',
        invalidatesTags: ['projectList'],
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    attachUseCase: build.mutation<AttachUseCaseApiResponse, AttachUseCaseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/${queryArg.licenseId}/use-case`,
        method: 'POST',
        body: queryArg.attachUseCaseRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    createProject: build.mutation<CreateProjectApiResponse, CreateProjectApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project`,
        method: 'POST',
        body: queryArg.createProjectRequestBody,
      }),
      invalidatesTags: ['projectList'],
    }),
    listProjects: build.query<ListProjectsApiResponse, ListProjectsApiArg>({
      query: () => ({url: `/admin/v1/project`}),
      providesTags: ['projectList'],
    }),
    detachUseCase: build.mutation<DetachUseCaseApiResponse, DetachUseCaseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/${queryArg.licenseId}/use-case/${queryArg.useCaseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    duplicateLicense: build.mutation<DuplicateLicenseApiResponse, DuplicateLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/${queryArg.licenseId}/duplicate`,
        method: 'POST',
        body: queryArg.duplicateLicenseRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    exportLicense: build.query<ExportLicenseApiResponse, ExportLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/${queryArg.licenseId}/export`,
      }),
    }),
    getLicenseTenantList: build.query<GetLicenseTenantListApiResponse, GetLicenseTenantListApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/${queryArg.licenseId}/tenants`,
      }),
    }),
    getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
      query: (queryArg) => ({url: `/admin/v1/project/${queryArg.projectId}`}),
      providesTags: (result, error, queryArg) => [{type: 'project', projectId: queryArg.projectId}],
    }),
    updateProject: build.mutation<UpdateProjectApiResponse, UpdateProjectApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}`,
        method: 'POST',
        body: queryArg.updateProjectRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    importLicense: build.mutation<ImportLicenseApiResponse, ImportLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/import`,
        method: 'POST',
        body: queryArg.importLicenseRequestBody,
      }),
    }),
    updateLicense: build.mutation<UpdateLicenseApiResponse, UpdateLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/${queryArg.licenseId}`,
        method: 'POST',
        body: queryArg.updateLicenseRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    updateModule: build.mutation<UpdateModuleApiResponse, UpdateModuleApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}`,
        method: 'POST',
        body: queryArg.updateModuleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'project', projectId: queryArg.projectId},
      ],
    }),
    getNotAttachedUseCaseList: build.query<
      GetNotAttachedUseCaseListApiResponse,
      GetNotAttachedUseCaseListApiArg
    >({
      query: () => ({url: `/admin/v1/licensing/use-case/not-attached`}),
    }),
    getUseCaseList: build.query<GetUseCaseListOption[], GetUseCaseListApiArg>({
      query: () => ({url: `/admin/v1/licensing/use-case`}),
      transformResponse: (response: GetUseCaseListApiResponse) =>
        response.map((i) => ({label: getWordsFromLowerCase(i.id), value: i.id})),
    }),
    validateImportLicense: build.mutation<
      ValidateImportLicenseApiResponse,
      ValidateImportLicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/v1/project/${queryArg.projectId}/module/${queryArg.moduleId}/license/import/validate`,
        method: 'POST',
        body: queryArg.importLicenseRequestBody,
      }),
    }),
    createTenant: build.mutation<CreateTenantApiResponse, CreateTenantApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/tenant`,
        method: 'POST',
        body: queryArg.createTenantRequestBody,
      }),
      invalidatesTags: ['quickInfo'],
    }),
    getTenantList: build.query<GetTenantListApiResponse, GetTenantListApiArg>({
      query: () => ({url: `/admin/v1/tenant`}),
      providesTags: ['tenantList'],
    }),
    deleteTenantLicense: build.mutation<DeleteTenantLicenseApiResponse, DeleteTenantLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/tenant/${queryArg.tenantId}/license/${queryArg.licenseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tenantLicenses', tenantId: queryArg.tenantId},
      ],
    }),
    updateTenantLicense: build.mutation<UpdateTenantLicenseApiResponse, UpdateTenantLicenseApiArg>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBaseQuery) {
        const deleteResponse = await fetchWithBaseQuery({
          url: `/admin/v1/tenant/${_arg.tenantId}/license/${_arg.licenseId}`,
          method: 'DELETE',
        });

        if (deleteResponse.error) {
          throw deleteResponse.error;
        }

        const addResponse = await fetchWithBaseQuery({
          url: `/admin/v1/tenant/${_arg.tenantId}/license`,
          method: 'POST',
          body: _arg.tenantAddLicenseRequestBody,
        });

        if (addResponse.error) {
          throw addResponse.error;
        }

        return {data: true, error: undefined};
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tenantLicenses', tenantId: queryArg.tenantId},
      ],
    }),
    getTenant: build.query<GetTenantApiResponse, GetTenantApiArg>({
      query: (queryArg) => ({url: `/admin/v1/tenant/${queryArg.tenantId}`}),
      providesTags: (result, error, queryArg) => [{type: 'tenant', tenantId: queryArg.tenantId}],
    }),
    updateTenant: build.mutation<UpdateTenantApiResponse, UpdateTenantApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/tenant/${queryArg.tenantId}`,
        method: 'PUT',
        body: queryArg.updateTenantRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tenant', tenantId: queryArg.tenantId},
        {type: 'tenantLicenses', tenantId: queryArg.tenantId},
      ],
    }),
    getTenantKeyPair: build.query<GetTenantKeyPairApiResponse, GetTenantKeyPairApiArg>({
      query: (queryArg) => ({url: `/admin/v1/tenant/${queryArg.tenantId}/key-pair`}),
    }),
    keys: build.query<KeysApiResponse, KeysApiArg>({
      query: () => ({url: `/admin/v1/tenants/keys`}),
    }),
    getTenantLicensingLog: build.query<
      GetTenantLicensingLogApiResponse,
      GetTenantLicensingLogApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/v1/tenant/${queryArg.tenantId}/license/log`,
        params: {from: queryArg.from, to: queryArg.to},
      }),
    }),
    listTenantLicenses: build.query<ListTenantLicensesApiResponse, ListTenantLicensesApiArg>({
      query: (queryArg) => ({url: `/admin/v1/tenant/${queryArg.tenantId}/license`}),
      providesTags: (result, error, queryArg) => [
        {type: 'tenantLicenses', tenantId: queryArg.tenantId},
      ],
    }),
    postTenantLicense: build.mutation<PostTenantLicenseApiResponse, PostTenantLicenseApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/tenant/${queryArg.tenantId}/license`,
        method: 'POST',
        body: queryArg.tenantAddLicenseRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tenantLicenses', tenantId: queryArg.tenantId},
      ],
    }),
    markTenant: build.mutation<MarkTenantApiResponse, MarkTenantApiArg>({
      query: (queryArg) => ({url: `/admin/v1/tenant/${queryArg.tenantId}/mark`, method: 'POST'}),
      invalidatesTags: ['tenantList'],
    }),
    unmarkTenant: build.mutation<UnmarkTenantApiResponse, UnmarkTenantApiArg>({
      query: (queryArg) => ({url: `/admin/v1/tenant/${queryArg.tenantId}/unmark`, method: 'POST'}),
      invalidatesTags: ['tenantList'],
    }),
    postAuthorization: build.mutation<PostAuthorizationApiResponse, PostAuthorizationApiArg>({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorization`,
        method: 'POST',
        body: queryArg.body,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'authorizations', id: queryArg['x-tenant']},
      ],
    }),
    postAuthorizationProfile: build.mutation<
      PostAuthorizationProfileApiResponse,
      PostAuthorizationProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorization-profile`,
        method: 'POST',
        body: queryArg.body,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'authorizationProfiles', id: queryArg['x-tenant']},
      ],
    }),
    getAuthorizationProfile: build.query<
      GetAuthorizationProfileApiResponse,
      GetAuthorizationProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorization-profile/${queryArg.authorizationProfileId}`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'authorizationProfiles', id: queryArg['x-tenant']},
      ],
    }),
    patchAuthorizationProfile: build.mutation<
      PatchAuthorizationProfileApiResponse,
      PatchAuthorizationProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorization-profile/${queryArg.authorizationProfileId}`,
        method: 'PATCH',
        body: queryArg.body,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'authorizationProfiles', id: queryArg['x-tenant']},
      ],
    }),
    getAuthorizationProfiles: build.query<
      GetAuthorizationProfilesApiResponse,
      GetAuthorizationProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorization-profiles`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'authorizationProfiles', id: queryArg['x-tenant']},
      ],
    }),
    getAuthorization: build.query<GetAuthorizationApiResponse, GetAuthorizationApiArg>({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorization/${queryArg.authorizationId}`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
    }),
    patchAuthorization: build.mutation<PatchAuthorizationApiResponse, PatchAuthorizationApiArg>({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorization/${queryArg.authorizationId}`,
        method: 'PATCH',
        body: queryArg.body,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'authorizations', id: queryArg['x-tenant']},
      ],
    }),
    getAuthorizations: build.query<GetAuthorizationsApiResponse, GetAuthorizationsApiArg>({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/authorizations`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'authorizations', id: queryArg['x-tenant']},
      ],
    }),
    getBranchList: build.query<GetBranchListApiResponse, {'x-tenant': string}>({
      query: (queryArg) => ({
        url: `/admin/proxy/dms/v1/tenant/branch`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [{type: 'branches', id: queryArg['x-tenant']}],
    }),
    getNationalSalesCompanies: build.query<
      GetNationalSalesCompaniesApiResponse,
      {'x-tenant': string}
    >({
      query: (queryArg) => ({
        url: `/admin/proxy/esb/teas/api/core/v1/codelist/national-sales-companies`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [{type: 'companies', id: queryArg['x-tenant']}],
    }),
    getCorrespondingAddressList: build.query<
      GetCorrespondingAddressListApiResponse,
      GetCorrespondingAddressListApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/proxy/dms/v1/tenant/branch/${queryArg.branchId}/corresponding-address`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [{type: 'addresses', id: queryArg.branchId}],
    }),
    getAllApiEnums: build.query<GetAllApiEnumsApiResponse, GetAllApiEnumsApiArg>({
      query: (queryArg) => ({url: `/vehicle-catalogue/api/enum`, params: {lang: queryArg.lang}}),
      providesTags: ['enums'],
    }),
    getApiMakeModelWithMake: build.query<
      GetApiMakeModelWithMakeApiResponse,
      GetApiMakeModelWithMakeApiArg
    >({
      query: (queryArg) => {
        const URL_PREFIX = '/vehicle-catalogue/api/make-model';

        const url = match([isNotNilOrEmpty(queryArg.vehicleType), isNotNilOrEmpty(queryArg.make)])
          .with([true, true], always(`${URL_PREFIX}/${queryArg.vehicleType}/${queryArg.make}`))
          .with([true, false], always(`${URL_PREFIX}/${queryArg.vehicleType}`))
          .otherwise(always(`${URL_PREFIX}`));

        return {
          url,
          params: {lang: queryArg.lang},
        };
      },
      providesTags: (result, error, queryArg) => [{type: 'makes', id: queryArg.vehicleType}],
    }),
    getBillingInformationList: build.query<
      GetBillingInformationListApiResponse,
      {'x-tenant': string}
    >({
      query: (queryArg) => ({
        url: `/admin/proxy/dms/v1/tenant/billing-information`,
        headers: {'x-tenant': queryArg['x-tenant']},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'billingInformation', id: queryArg['x-tenant']},
      ],
    }),
    resetHelpDeskPassword: build.mutation<
      ResetHelpDeskPasswordApiResponse,
      ResetHelpDeskPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/v1/tenant/${queryArg.tenantId}/help-desk-password-reset`,
        method: 'POST',
      }),
    }),
  }),
});

export const selectQuickInfo = adminApi.endpoints.quickInfo.select();
export const selectUseCaseList = adminApi.endpoints.getUseCaseList.select();

export const {
  useQuickInfoQuery,
  useAddLicenseMutation,
  useAddModuleMutation,
  useArchiveLicenseMutation,
  useArchiveModuleMutation,
  useArchiveProjectMutation,
  useAttachUseCaseMutation,
  useCreateProjectMutation,
  useListProjectsQuery,
  useDetachUseCaseMutation,
  useDuplicateLicenseMutation,
  useExportLicenseQuery,
  useGetLicenseTenantListQuery,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useImportLicenseMutation,
  useUpdateLicenseMutation,
  useUpdateModuleMutation,
  useGetNotAttachedUseCaseListQuery,
  useGetUseCaseListQuery,
  useValidateImportLicenseMutation,
  useCreateTenantMutation,
  useGetTenantListQuery,
  useDeleteTenantLicenseMutation,
  useGetTenantQuery,
  useUpdateTenantMutation,
  useGetTenantKeyPairQuery,
  useKeysQuery,
  useGetTenantLicensingLogQuery,
  useListTenantLicensesQuery,
  usePostTenantLicenseMutation,
  useMarkTenantMutation,
  useUnmarkTenantMutation,
  useUpdateTenantLicenseMutation,
  useGetAuthorizationProfilesQuery,
  usePostAuthorizationProfileMutation,
  usePatchAuthorizationProfileMutation,
  useGetAuthorizationsQuery,
  usePostAuthorizationMutation,
  usePatchAuthorizationMutation,
  useGetBranchListQuery,
  useGetNationalSalesCompaniesQuery,
  useLazyGetCorrespondingAddressListQuery,
  useGetAllApiEnumsQuery,
  useGetApiMakeModelWithMakeQuery,
  useLazyGetApiMakeModelWithMakeQuery,
  useGetBillingInformationListQuery,
  useResetHelpDeskPasswordMutation,
} = adminApi;
