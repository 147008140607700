import {browserStorageKey} from '@omnetic-admin-service/config';
import {environment} from '@omnetic-admin-service/environment';
import i18n, {ReadCallback} from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import {setLocale} from 'yup';

import {curry, keys, reduce} from 'ramda';

import * as Locales from './locales';

const mapKeys = curry((fn: (val: string) => string, obj: Record<string, any>) =>
  reduce<string, Record<string, unknown>>(
    (acc, key) => {
      acc[fn(key)] = obj[key];
      return acc;
    },
    {},
    keys<Record<string, unknown>>(obj)
  )
);

const translations = mapKeys((key) => key.slice(0, 2), Locales);

type DetectCallback = (language: string | null) => void;

const importFn = (language: string, namespace: string, callback: ReadCallback) => {
  const newResource = translations[language];

  if (newResource) callback(null, newResource);
  else callback(new Error(`resource with key "${language}" not found`), null);
};

i18n
  .use(resourcesToBackend(importFn))
  .use({
    type: 'languageDetector',
    async: true,
    async detect(callback: DetectCallback): Promise<void> {
      await callback(localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE));
    },
    init: () => undefined,
    cacheUserLanguage: () => undefined,
  })
  .init(
    {
      fallbackLng: 'en',
      defaultNS: 'translation',
      resources: {
        en: {
          translation: Locales.en_US,
        },
      },
      partialBundledLanguages: true,
      debug: environment.ENV_TYPE !== 'prod',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        prefix: '{',
        suffix: '}',
      },
      react: {
        useSuspense: true,
      },
      pluralSeparator: '.',
    },
    (error, t) => {
      setLocale({
        mixed: {
          default: ({label}) => t('general.errors.mixed.invalid', {label}),
          required: ({label}) => t('general.errors.mixed.required', {label}),
        },
        string: {
          email: ({label}) => t('general.errors.string.email', {label}),
          length: ({label, length}) => t('general.errors.string.length', {label, length}),
          min: ({label, min}) => t('general.errors.string.min', {label, min}),
          max: ({label, max}) => t('general.errors.string.max', {label, max}),
          lowercase: ({label}) => t('general.errors.string.lowercase', {label}),
          uppercase: ({label}) => t('general.errors.string.uppercase', {label}),
          matches: ({label, regex}) => t('general.errors.string.matches', {label, regex}),
          url: ({label}) => t('general.errors.string.url', {label}),
        },
        number: {
          min: ({label, min}) => t('general.errors.number.min', {label, min}),
          max: ({label, max}) => t('general.errors.number.max', {label, max}),
          negative: ({label}) => t('general.errors.number.negative', {label}),
          positive: ({label}) => t('general.errors.number.positive', {label}),
          integer: ({label}) => t('general.errors.number.integer', {label}),
        },
      });
    }
  );

export {i18n};
