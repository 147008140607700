import {environment} from '@omnetic-admin-service/environment';
import {fetchBaseQuery, createApi} from '@reduxjs/toolkit/dist/query/react';

import {LoginApiArg, LoginApiResponse, RefreshTokenApiArg, RefreshTokenApiResponse} from '../types';

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: localStorage.getItem('BE_URL') || environment.API_URL,
  }),
  endpoints: (build) => ({
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/login`,
        method: 'POST',
        body: queryArg.loginRequestBody,
      }),
    }),
    refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
      query: (queryArg) => ({
        url: `/admin/v1/auth/token/refresh`,
        method: 'POST',
        body: queryArg.refreshTokenRequestBody,
      }),
    }),
  }),
});

export const {useLoginMutation, useRefreshTokenMutation} = publicApi;
