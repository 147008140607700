import {FC, ReactNode} from 'react';

import {useMultiStyleConfig} from '@chakra-ui/system';

import {createContext, TestIdProps} from 'shared';

import {TagSizeType} from '../../Tag/Tag';
import {MultiStyleConfig, StyleConfig} from '../types';

type TagVariantType = 'bold';

type ChoiceContext = TestIdProps & {
  hasOptionCheckbox?: boolean;
  valueTagVariant: string;
  tagSize: TagSizeType;
  moreTagVariant: TagVariantType;
  buttonSize: string;
  choiceTagStyles: StyleConfig | undefined;
  menuStyles: MultiStyleConfig;
};

type ChoiceProviderProps = {
  options: TestIdProps & {hasOptionCheckbox?: boolean};
  children: ReactNode;
};

const [Provider, useChoice] = createContext<ChoiceContext>({
  strict: false,
  name: 'ChoiceContext',
});

const ChoiceProvider: FC<ChoiceProviderProps> = ({children, options}) => {
  const {hasOptionCheckbox} = options;

  const choiceStyles = useMultiStyleConfig('Choice', options);

  const valueTagVariant = choiceStyles.valueTagVariant as string;
  const tagSize = choiceStyles.tagSize as TagSizeType;

  const moreTagVariant = choiceStyles.moreTagVariant as TagVariantType;

  const buttonSize = choiceStyles.buttonSize as string;

  const menuVariant = choiceStyles.menuVariant;

  const choiceTagStyles = choiceStyles.choiceTag;

  const menuStyles = useMultiStyleConfig('Menu', {variant: menuVariant as string});

  return (
    <Provider
      value={{
        'data-testid': options['data-testid'],
        valueTagVariant,
        moreTagVariant,
        tagSize,
        choiceTagStyles,
        menuStyles,
        hasOptionCheckbox,
        buttonSize,
      }}
    >
      {children}
    </Provider>
  );
};
export {useChoice, ChoiceProvider};
