import {FlexboxJustify, HStack, Show, Text, ValueByDevice} from 'platform/foundation';

import {Fragment} from 'react';

import {reject} from 'ramda';
import {isNilOrEmpty, isPlainObj, isPositive} from 'ramda-adjunct';

import {DOT_CHARACTER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {getRandomId} from '../../utils/getRandomId';
import {Tooltip} from '../Tooltip/Tooltip';

export type ParameterWithTooltip = {title: string | Nullish; tooltip: string | Nullish};
export type Parameter = string | ParameterWithTooltip | Nullish;

export interface ParametersProps extends TestIdProps {
  parameters: Parameter[] | Nullish;
  color?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'white';
  size?: 'base' | 'small' | 'xSmall';
  alternative?: boolean;
  justify?: FlexboxJustify | ValueByDevice<FlexboxJustify>;
}

export function Parameters(props: ParametersProps) {
  const size = props.size ?? 'xSmall';
  const filteredParameters = reject(isNilOrEmpty, props.parameters ?? []);
  if (!filteredParameters?.length) return null;

  return (
    <HStack spacing={1} data-testid={props['data-testid']} wrap justify={props.justify}>
      {filteredParameters.map((parameter, index) => {
        const hasTooltip = isParameterWithTooltip(parameter);
        const title = hasTooltip ? parameter.title : parameter;
        const tooltip = hasTooltip ? parameter.tooltip : null;

        return (
          <Fragment key={`${title}_${getRandomId()}`}>
            <Show when={isPositive(index)}>
              <Text color="tertiary" size={size}>
                {DOT_CHARACTER}
              </Text>
            </Show>
            <Tooltip label={tooltip}>
              <Text
                data-testid={suffixTestId(`parameter-[${index.toString()}]`, props)}
                color={props.color}
                size={size}
                noWrap
                alternative={props.alternative}
              >
                {title}
              </Text>
            </Tooltip>
          </Fragment>
        );
      })}
    </HStack>
  );
}

export function isParameterWithTooltip(parameter: Parameter): parameter is ParameterWithTooltip {
  return isPlainObj(parameter);
}
