import {AdaptiveImage, AdaptiveImageProps, getCssSize} from 'platform/foundation';

import {ReactNode, useState} from 'react';

import {Box} from '@chakra-ui/layout';
import {useMultiStyleConfig} from '@chakra-ui/system';

import {suffixTestId} from 'shared';

import {ButtonGroup, ButtonGroupProps} from '../ButtonGroup/ButtonGroup';

export type PreviewProps = Omit<
  ButtonGroupProps,
  'variant' | 'size' | 'children' | 'label' | 'buttons' | 'ref'
> &
  AdaptiveImageProps & {
    children: ReactNode;
  };

export function Preview(props: PreviewProps) {
  const {children, maxHeight, maxWidth, ...rest} = props;

  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const styles = useMultiStyleConfig('Preview', rest);

  return (
    <Box
      data-testid={suffixTestId('preview', props)}
      position="relative"
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
      display="inline-block"
    >
      <AdaptiveImage data-testid={suffixTestId('previewImage', props)} {...rest} />
      <Box
        sx={styles.overlay}
        display={isMouseOver ? 'flex' : 'none'}
        maxHeight={getCssSize(maxHeight)}
        maxWidth={getCssSize(maxWidth)}
      >
        <ButtonGroup data-testid={suffixTestId('previewButtonGroup', props)}>
          {children}
        </ButtonGroup>
      </Box>
    </Box>
  );
}
