import {match} from 'ts-pattern';

import {useContext} from 'react';

import {always} from 'ramda';

import {HStack, useMultiStyleConfig} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {PaginationContext} from '../utils/context';
import {ControlButton} from './ControlButton';
import {InputSegment} from './InputSegment';
import {PaginationPageGroup} from './PaginationPageGroup';
import {TextOnlySegment} from './TextOnlySegment';

interface PaginationBodyProps extends TestIdProps {}

export function PaginationBody(props: PaginationBodyProps) {
  const {state, actions} = useContext(PaginationContext);

  const {setFirstPage, setPrevPage, setNextPage, setLastPage} = actions;

  const {showFirstButton, showPrevButton, showNextButton, showLastButton, variant, size} = state;

  const {body: bodyStyles} = useMultiStyleConfig('Pagination', {
    size,
  });

  return (
    <HStack spacing={2} sx={bodyStyles} data-testid={suffixTestId('paginationWrapper', props)}>
      {showFirstButton && (
        <ControlButton
          action={setFirstPage}
          icon="navigation/first_page"
          data-testid={suffixTestId('paginationFirstButton', props)}
        />
      )}
      {showPrevButton && (
        <ControlButton
          action={setPrevPage}
          icon="hardware/keyboard_arrow_left"
          data-testid={suffixTestId('paginationPrevButton', props)}
        />
      )}
      {match(variant)
        .with(
          'input',
          always(<InputSegment data-testid={suffixTestId('paginationInput', props)} />)
        )
        .with(
          'text-only',
          always(<TextOnlySegment data-testid={suffixTestId('paginationTextOnly', props)} />)
        )
        .otherwise(
          always(<PaginationPageGroup data-testid={suffixTestId('paginationPageGroup', props)} />)
        )}
      {showNextButton && (
        <ControlButton
          action={setNextPage}
          icon="hardware/keyboard_arrow_right"
          data-testid={suffixTestId('paginationNextButton', props)}
        />
      )}
      {showLastButton && (
        <ControlButton
          action={setLastPage}
          icon="navigation/last_page"
          data-testid={suffixTestId('paginationLastButton', props)}
        />
      )}
    </HStack>
  );
}
