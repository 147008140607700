import {APPLICATION_NAME, browserStorageKey} from '@omnetic-admin-service/config';
import {testIds} from '@omnetic-admin-service/shared';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {FormSubmitHandler, FormField, FormButton, Form, Card} from 'platform/components';
import {HStack, Box, Center, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {useQuickInfoQuery} from '../../api/adminApi';
import {useLoginMutation} from '../../api/publicApi';
import {i18n} from '../../i18n/config';
import {LoginApiArg} from '../../types';
import {handleApiError} from '../../utils/handleApiError';

type LoginForm = {
  userName: string;
  password: string;
};

export function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [login, {isLoading}] = useLoginMutation();
  const {data} = useQuickInfoQuery();

  useEffect(() => {
    const beUrl = searchParams?.get('be');

    if (beUrl) {
      localStorage.setItem('BE_URL', decodeURIComponent(beUrl));
    }
  }, [searchParams]);

  useEffect(() => {
    if (data) {
      navigate('/');
    }
  }, [data, navigate]);

  const onSubmit: FormSubmitHandler<LoginForm> = async ({userName, password}, setErrors) => {
    const arg: LoginApiArg = {
      loginRequestBody: {
        username: userName,
        password,
      },
    };
    await login(arg)
      .unwrap()
      .then((payload) => {
        if (!payload.refreshToken || !payload.token) return;
        sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, payload.token);
        localStorage.setItem(browserStorageKey.REFRESH_TOKEN, payload.refreshToken);
        localStorage.setItem('user', userName);

        const redirectTo = sessionStorage.getItem(browserStorageKey.REDIRECT_LINK);

        if (redirectTo) {
          sessionStorage.removeItem(browserStorageKey.REDIRECT_LINK);
        }
        navigate(redirectTo || '/');
      })
      .catch((error: FetchBaseQueryError) =>
        handleApiError(error, {
          callback: () => setErrors([{name: 'password', message: 'Invalid login'}]),
        })
      );
  };

  return (
    <>
      <Helmet>Login</Helmet>
      <Box width="100vw" height="100vh" backgroundColor="palettes.blue.90.100">
        <Center width="100%" height="100%">
          <VStack align="center" justify="center">
            <Form onSubmit={onSubmit} schema={schema}>
              {(control) => (
                <Card title={APPLICATION_NAME}>
                  <Box width={80}>
                    <VStack spacing={4}>
                      <FormField
                        data-testid={testIds.login('userName')}
                        name="userName"
                        type="text"
                        control={control}
                        label={i18n.t('login.userName')}
                        maxLength={100}
                      />
                      <FormField
                        data-testid={testIds.login('password')}
                        name="password"
                        type="password"
                        control={control}
                        label={i18n.t('login.password')}
                      />

                      <HStack width="100%" justify="flex-end">
                        <FormButton
                          data-testid={testIds.login('submit')}
                          control={control}
                          type="submit"
                          variant="primary"
                          isDisabled={isLoading}
                          title={i18n.t('login.submit')}
                        />
                      </HStack>
                    </VStack>
                  </Box>
                </Card>
              )}
            </Form>
          </VStack>
        </Center>
      </Box>
    </>
  );
}

const schema = Yup.object().shape({
  userName: Yup.string().required().trim(),
  password: Yup.string().required().trim(),
});
