import {useContext} from 'react';

import {HStack, useMultiStyleConfig} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {PaginationContext} from '../utils/context';
import {useGeneratePages} from '../utils/useGeneratePages';
import {PaginationPage} from './PaginationPage';

interface PaginationPageGroupProps extends TestIdProps {}

export function PaginationPageGroup(props: PaginationPageGroupProps) {
  const {pages} = useGeneratePages();
  const {
    state: {size, styleVariant},
  } = useContext(PaginationContext);
  const {pageGroup} = useMultiStyleConfig('Pagination', {size, variant: styleVariant});

  return (
    <HStack isInline as="ol" spacing={2} sx={pageGroup} data-testid={props['data-testid']}>
      {pages.map((item, index) => (
        <PaginationPage
          key={`pagination_page_${item}_${index}`}
          page={item}
          data-testid={suffixTestId(`page-${item}`, props)}
        />
      ))}
    </HStack>
  );
}
