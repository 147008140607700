import {
  Button,
  ButtonGroup,
  Checkboxes,
  closeCurrentDialog,
  DataStatus,
  showNotification,
} from 'platform/components';
import {Space, Text} from 'platform/foundation';

import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {difference} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {
  adminApi,
  selectUseCaseList,
  useAttachUseCaseMutation,
  useDetachUseCaseMutation,
  useGetUseCaseListQuery,
} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {AttachUseCaseApiArg, DetachUseCaseApiArg, LicenseResponseBody} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';

interface EditUseCaseDialogProps extends TestIdProps {
  moduleId: string;
  projectId: string;
  license: LicenseResponseBody | null;
}

export function EditUseCaseDialog(props: EditUseCaseDialogProps) {
  const {
    data: useCaseList,
    isLoading: isUseCaseListFetchloading,
    isError: isUseCaseListFetchErrored,
  } = useGetUseCaseListQuery();
  const [attachUseCase, {isLoading: isAttachLoading}] = useAttachUseCaseMutation();
  const [detechMutation, {isLoading: isDetechLoading}] = useDetachUseCaseMutation();

  const {data: getUseCaseList} = useSelector(selectUseCaseList);

  const selectProjectData = useMemo(
    () => adminApi.endpoints.getProject.select({projectId: props.projectId}),
    [props.projectId]
  );
  const {data: projectData} = useSelector(selectProjectData);

  const getUseCaseIds = useMemo(
    () =>
      projectData?.modules
        ?.find((m) => m.id === props.moduleId)
        ?.licenses?.find((l) => l.id === props.license?.id)?.useCaseIds,
    [projectData?.modules, props.license, props.moduleId]
  );

  const onChange = useCallback(
    (values: string[] | null) => {
      if (!getUseCaseList?.length || typeof props.license?.id !== 'string') {
        showNotification.error(i18n.t('general.message.generalError'));
        return;
      }

      const attachStack = difference(values ?? [], getUseCaseIds ?? []);
      const detechStack = difference(getUseCaseIds ?? [], values ?? []);

      const getAttachArg = (id: string): AttachUseCaseApiArg => ({
        projectId: props.projectId,
        moduleId: props.moduleId,
        licenseId: props.license?.id as string,
        attachUseCaseRequestBody: {
          useCaseId: id,
        },
      });

      const getDetechArg = (id: string): DetachUseCaseApiArg => ({
        projectId: props.projectId,
        moduleId: props.moduleId,
        licenseId: props.license?.id as string,
        useCaseId: id,
      });

      Promise.all([
        ...attachStack.map((a) => attachUseCase(getAttachArg(a)).unwrap()),
        ...detechStack.map((d) => detechMutation(getDetechArg(d)).unwrap()),
      ]).catch(handleApiError);
    },
    [
      attachUseCase,
      detechMutation,
      getUseCaseIds,
      getUseCaseList?.length,
      props.license?.id,
      props.moduleId,
      props.projectId,
    ]
  );

  const isLoading = isUseCaseListFetchloading || isAttachLoading || isDetechLoading;

  return (
    <>
      <DataStatus
        isLoading={isLoading}
        isError={isUseCaseListFetchErrored}
        isEmpty={!useCaseList?.length}
        minHeight={30}
      >
        <>
          <Text size="small">{i18n.t('projects.editUseCaseDescription')}</Text>
          <Space vertical={4} />
          <Checkboxes
            direction="vertical"
            data-testid={suffixTestId('useCases', props)}
            value={getUseCaseIds ?? null}
            options={getUseCaseList ?? []}
            isDisabled={isLoading}
            onChange={onChange}
          />
        </>
      </DataStatus>

      <Space vertical={4} />
      <ButtonGroup align="right" data-testid={suffixTestId('actions', props)}>
        <Button
          data-testid={suffixTestId('close', props)}
          isDisabled={isLoading}
          variant="secondary"
          onClick={closeCurrentDialog}
          title={i18n.t('common.close')}
        />
      </ButtonGroup>
    </>
  );
}
