import {environment} from 'environments/platform';
import {Box, Heading, HStack, Show} from 'platform/foundation';

import {ReactNode} from 'react';

import {not} from 'ramda';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay as ChakraModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {ButtonProps} from '../Button/Button';
import {ButtonGroup} from '../ButtonGroup/ButtonGroup';
import {DialogCloseButton} from './components/DialogCloseButton';

export interface DialogProps extends TestIdProps {
  isOpen: boolean;
  children: ReactNode;
  title?: string;
  scrollBehavior?: ModalProps['scrollBehavior'];
  size?: 'small' | 'default' | 'large';
  withAdditionalFooter?: boolean;
  id?: string;
  disableBodyPadding?: boolean;
  /**
   * @about Displays sticky footer with buttons inside
   */
  buttons?: ButtonProps[];
  onClose?: () => void;
  onCloseComplete?: () => void;
}

const DIALOG_HEIGHT_BREAKPOINT_PX = 1000;

export function Dialog(props: DialogProps) {
  const isEnlarged = window.innerHeight <= DIALOG_HEIGHT_BREAKPOINT_PX;

  return (
    <Modal
      id={props.id}
      size={props.size}
      isOpen={props.isOpen}
      onClose={() => props.onClose?.()}
      onCloseComplete={props.onCloseComplete}
      scrollBehavior={props.scrollBehavior ?? 'inside'}
      closeOnOverlayClick={!environment.testMode}
      data-testid={suffixTestId('dialog', props)}
    >
      <ChakraModalOverlay />
      <ModalContent
        data-testid={suffixTestId('dialogContent', props)}
        maxHeight={`calc(100% - ${isEnlarged ? 2 : 7.5}rem)`}
      >
        <Show when={props.title}>
          <Box
            padding={4}
            borderBottom="1px solid"
            borderColor="general.separator"
            data-testid={suffixTestId('dialogHeader', props)}
          >
            <HStack spacing={4} justify="space-between" align="center">
              <Heading size={4}>{props.title}</Heading>
              <DialogCloseButton data-testid={props['data-testid']} />
            </HStack>
          </Box>
        </Show>

        <ModalBody
          data-testid={suffixTestId('dialogBody', props)}
          marginBottom={props.withAdditionalFooter ? 16 : 0}
          padding={props.disableBodyPadding ? 0 : undefined}
        >
          {props.children}
        </ModalBody>

        <Show when={props.buttons && not(props.withAdditionalFooter)}>
          <ModalFooter data-testid={suffixTestId('dialogFooter', props)}>
            <Box padding={4}>
              <ButtonGroup buttons={props.buttons} />
            </Box>
          </ModalFooter>
        </Show>
      </ModalContent>
    </Modal>
  );
}
