import {add, complement, findIndex, inc, takeLast, whereEq} from 'ramda';

import {WizardStepConfig} from '../WizardStepConfig';

const isStepAvailable = complement(whereEq({isDisabled: true}));

export const getNextStep = (steps: WizardStepConfig[], currentStep: number): number | null => {
  if (currentStep >= steps.length) return null;
  const previousSteps = takeLast(steps.length - inc(currentStep), steps);
  const foundIndex = findIndex<WizardStepConfig>(isStepAvailable, previousSteps);
  if (foundIndex < 0) return null;
  return add(inc(currentStep), foundIndex);
};
