import {Box, CSSDimension, Integer, Text, ValueByDevice} from 'platform/foundation';
import styled from 'styled-components';
import {Pattern, match} from 'ts-pattern';

import {ReactNode} from 'react';

import {always} from 'ramda';

import {TestIdProps} from 'shared';

import {OptionType} from '../../types/FormControlProps';

export type SegmentItemBaseProps<T> = {
  variant?: string;
  isDisabled?: boolean;
  minWidth?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
} & OptionType<T, string | ReactNode>;

export interface SegmentItemProps<T> extends SegmentItemBaseProps<T>, TestIdProps {
  isSelected: boolean;
  onClick?: (value: T) => void;
}

export function SegmentItem<T extends string>(props: SegmentItemProps<T>) {
  function handleClick() {
    props.onClick?.(props.value);
  }

  return (
    <StyledSegmentItem
      isSelected={props.isSelected}
      onClick={!props.isDisabled ? handleClick : undefined}
      data-testid={props['data-testid']}
      key={props.value}
      isDisabled={props.isDisabled}
    >
      <Box
        paddingVertical={1}
        paddingHorizontal={2}
        width="100%"
        minWidth={props.minWidth ?? undefined}
      >
        <Text size="xSmall" align="center">
          {props.label}
        </Text>
      </Box>
    </StyledSegmentItem>
  );
}

type StyledSegmentItemProps = {
  isSelected?: boolean;
  isDisabled?: boolean;
};
const StyledSegmentItem = styled.div<StyledSegmentItemProps>`
  display: flex;
  flex-grow: 1;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.palettes.white['10']['100']
      : props.theme.colors.palettes.neutral['20']['100']};
  cursor: ${(props) =>
    match([props.isDisabled, props.isSelected])
      .with([true, Pattern.any], always('not-allowed'))
      .otherwise(always('pointer'))};
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  box-shadow: ${(props) => (props.isSelected ? props.theme.shadows.elevation_2 : 'unset')};
  border-radius: ${(props) => props.theme.radii.small};
  border-width: 1px;
  border-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.palettes.neutral['40']['100']
      : props.theme.colors.palettes.neutral['20']['100']};
  text-align: center;
  &:hover {
    background: ${(props) =>
      props.isSelected
        ? props.theme.colors.palettes.white['10']['100']
        : props.theme.colors.palettes.neutral['40']['100']};
  }
`;
