import {ComponentStyleConfig} from '@chakra-ui/theme';

export const dialogTheme: ComponentStyleConfig = {
  defaultProps: {
    size: 'default',
  },
  baseStyle: {
    dialogContainer: {
      alignItems: 'center',
    },
    dialog: {
      backgroundColor: 'palettes.white.10.100',
      shadow: 'elevation_4',
      border: '1px solid',
      borderColor: 'palettes.neutral.40.100',
      borderRadius: 'medium',
      width: '100%',
    },
    footer: {
      padding: 0,
    },
    body: {
      padding: '16px',
    },
    overlay: {
      background: 'palettes.neutral.900.20',
    },
  },
  sizes: {
    small: {
      dialog: {
        maxWidth: '400px',
      },
    },
    default: {
      dialog: {
        maxWidth: '800px',
      },
    },
    large: {
      dialog: {
        maxWidth: '1200px',
      },
    },
  },
  parts: ['overlay', 'dialogContainer', 'dialog', 'closeButton', 'body', 'footer'],
};
