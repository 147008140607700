import styled, {css, keyframes} from 'styled-components';

import {AnimationEvent, ReactNode} from 'react';

import {LightboxApi} from '../hooks/useLightbox';
import {LightboxState} from '../types/LightboxState';

type LightboxBodyProps = {
  api: LightboxApi;
  children?: ReactNode;
};

export function LightboxBody({api, children}: LightboxBodyProps) {
  const onAnimationEnd = (e: AnimationEvent) => {
    if (e.animationName === onClose.getName()) {
      // FIXME: T20-17234 Close animation cause random close on first open attempt
      // api.setState({...api.state, isClosing: false, isOpen: false});
    }
  };

  return (
    <StyledLightboxBody onAnimationEnd={onAnimationEnd} {...api.state}>
      {children}
    </StyledLightboxBody>
  );
}

const onOpen = keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
`;

const onClose = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const StyledLightboxBody = styled.div<LightboxState>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  outline: none;
  overflow: hidden;
  padding-top: 65px;
  padding-bottom: 150px;
  display: none;

  ${({isOpen}) =>
    isOpen &&
    css`
      display: block;
      animation: ${onOpen} 0.3s;
    `}

  ${({isClosing}) =>
    isClosing &&
    css`
      animation: ${onClose} 0.2s;
    `}
`;
