import {routes, testIds} from '@omnetic-admin-service/shared';
import {
  closeCurrentDialog,
  DataStatus,
  Flag,
  FormSubmitHandler,
  openDialog,
  Table,
  TableRow,
  TableRowActionsData,
  Tooltip,
  copyToClipboard,
} from 'platform/components';
import {Clickable, Text} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {composePath} from 'shared';

import {useCreateProjectMutation, useListProjectsQuery} from '../../api/adminApi';
import {PageHeader} from '../../components/PageHeader/PageHeader';
import {ProjectFormDialog, ProjectForm} from '../../components/ProjectFormDialog/ProjectFormDialog';
import {i18n} from '../../i18n/config';
import {CreateProjectApiArg} from '../../types';
import {handleApiError} from '../../utils/handleApiError';

export function ProjectOverview() {
  const navigate = useNavigate();

  const {data, isLoading, isError} = useListProjectsQuery();
  const [createProject, {isLoading: isCreateProjectLoading}] = useCreateProjectMutation();

  const onCreateProject: FormSubmitHandler<ProjectForm> = async (values) => {
    const arg: CreateProjectApiArg = {
      createProjectRequestBody: {
        name: values.name,
        description: values.description,
      },
    };
    await createProject(arg)
      .unwrap()
      .then(({id}) => {
        navigate(composePath(routes.projectDetail, {params: {id}}));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onCreateProjectDialog = () =>
    openDialog(
      <ProjectFormDialog
        data-testid={testIds.projectOverview('createProject')}
        isLoading={isCreateProjectLoading}
        onSubmit={onCreateProject}
      />,
      {
        title: 'Create Project',
        'data-testid': testIds.projectOverview('createProject'),
      }
    );

  const getTableAction = (id: string) => {
    const actions: TableRowActionsData = {
      primary: [
        {
          title: i18n.t('common.openDetail'),
          icon: 'action/open_in_new',
          onClick: () => navigate(composePath(routes.projectDetail, {params: {id}})),
        },
      ],
    };
    return actions;
  };

  return (
    <>
      <PageHeader
        title={i18n.t('projects.pageTitle')}
        description={i18n.t('projects.pageDescription')}
        isLoading={isLoading}
        actions={[
          {
            'data-testid': testIds.projectOverview('createProject'),
            type: 'button',
            title: i18n.t('projects.createProject'),
            variant: 'primary',
            onClick: () => onCreateProjectDialog(),
          },
        ]}
      />

      <DataStatus isLoading={isLoading} isError={isError} isEmpty={!data?.length} minHeight={50}>
        <>
          {data?.length ? (
            <Table tableLayout="fixed" data-testid={testIds.projectOverview('overview')}>
              <TableRow actionsWidth={13}>
                <Text alternative size="small">
                  {i18n.t('common.id')}
                </Text>
                <Text alternative size="small">
                  {i18n.t('common.name')}
                </Text>
                <Text alternative size="small">
                  {i18n.t('common.description')}
                </Text>
                <Text alternative size="small">
                  {i18n.t('common.status')}
                </Text>
              </TableRow>
              {data.map((project) => (
                <TableRow
                  key={project.id}
                  data-testid={testIds.projectOverview(`row-${project.id}`)}
                  actions={getTableAction(project.id)}
                >
                  <Tooltip
                    label={i18n.t('common.clickMeToCopy')}
                    description={project.id}
                    placement="top-start"
                  >
                    <Clickable
                      data-testid={testIds.projectOverview(`${project.id}-id`)}
                      onClick={() => copyToClipboard(project.id)}
                    >
                      <Text
                        data-testid={testIds.projectOverview(`${project.id}-id`)}
                        size="small"
                        noWrap
                      >
                        {project.id}
                      </Text>
                    </Clickable>
                  </Tooltip>
                  <Tooltip
                    label={i18n.t('common.name')}
                    description={project.name}
                    placement="top-start"
                  >
                    <Text
                      alternative
                      data-testid={testIds.projectOverview(`${project.id}-name`)}
                      size="small"
                      noWrap
                    >
                      {project.name}
                    </Text>
                  </Tooltip>
                  <Tooltip
                    label={i18n.t('common.description')}
                    description={project.description}
                    placement="top-start"
                  >
                    <Text
                      data-testid={testIds.projectOverview(`${project.id}-description`)}
                      size="small"
                      noWrap
                    >
                      {project.description}
                    </Text>
                  </Tooltip>
                  {project.archived ? (
                    <Flag
                      colorScheme="orange"
                      data-testid={testIds.projectOverview(`${project.id}-status`)}
                      label={i18n.t('common.archived')}
                    />
                  ) : (
                    <Flag
                      colorScheme="green"
                      data-testid={testIds.projectOverview(`${project.id}-status`)}
                      label={i18n.t('common.active')}
                    />
                  )}
                </TableRow>
              ))}
            </Table>
          ) : null}
        </>
      </DataStatus>
    </>
  );
}
