import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useEffect} from 'react';

import {isNil, join} from 'ramda';
import {isNilOrEmpty, isString} from 'ramda-adjunct';

import {Nullish, yupString} from 'shared';

import {
  useGetAuthorizationsQuery,
  useGetBillingInformationListQuery,
  useGetBranchListQuery,
  useLazyGetCorrespondingAddressListQuery,
  usePatchAuthorizationProfileMutation,
  usePostAuthorizationProfileMutation,
} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {handleApiError} from '../../../utils/handleApiError';
import {AuthorizationProfileType} from '../types';

interface AuthorizationProfileFormProps {
  tenantId: string;
  defaultValues: AuthorizationProfileType | Nullish;
}

export function AuthorizationProfileForm(props: AuthorizationProfileFormProps) {
  const {data: authorizations, isLoading: isAuthorizationsLoading} = useGetAuthorizationsQuery(
    {'x-tenant': props.tenantId},
    {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.map((authorization) => ({
          label: authorization?.name,
          value: authorization?.id,
        })),
      }),
    }
  );
  const {data: branches, isLoading: isBranchesLoading} = useGetBranchListQuery({
    'x-tenant': props.tenantId,
  });
  const {data: companies, isLoading: isCompaniesLoading} = useGetBillingInformationListQuery({
    'x-tenant': props.tenantId,
  });
  const [getAddressList, {isLoading: isAddressListLoading, data: addressList}] =
    useLazyGetCorrespondingAddressListQuery({
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.map((address) => ({
          label: join(', ', [
            address?.addressLine1,
            address?.addressLine2,
            address?.city,
            address?.postalCode,
          ]),
          value: address?.id,
        })),
      }),
    });

  const [patchAuthorizationProfile] = usePatchAuthorizationProfileMutation();
  const [postAuthorizationProfile] = usePostAuthorizationProfileMutation();

  useEffect(() => {
    if (isNil(props.defaultValues) || isNil(props.defaultValues?.branchId)) return;

    getAddressList({
      'x-tenant': props.tenantId,
      branchId: props.defaultValues.branchId,
    });
  }, [getAddressList, props]);

  const handleFormSubmit: FormSubmitHandler<AuthorizationProfileType> = async (data) => {
    const body = {
      name: data.name ?? '',
      authorizationId: data.authorizationId ?? '',
      branchId: data.branchId ?? '',
      companyAddressId: data.companyAddressId ?? '',
      companyId: data.companyId ?? '',
      isDefault: data.isDefault ?? false,
      dealerNo: data.dealerNo,
    };

    if (isNil(data.id))
      return await postAuthorizationProfile({
        'x-tenant': props.tenantId,
        body,
      })
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);

    await patchAuthorizationProfile({
      'x-tenant': props.tenantId,
      authorizationProfileId: data.id,
      body,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const getBranchOptions = (selectedCompany: string | Nullish) => {
    if (isNilOrEmpty(selectedCompany)) return;

    const companyName = companies?.billingInformationListItems?.find(
      (company) => company.id === selectedCompany
    )?.companyName;

    return branches?.branchListItems
      ?.filter((branch) => branch.companyName === companyName)
      .map((branch) => ({label: branch.marketingName, value: branch.id}));
  };

  const companiesOptions = companies?.billingInformationListItems.map((company) => ({
    label: company.companyName,
    value: company.id,
  }));

  return (
    <Form<AuthorizationProfileType>
      defaultValues={props.defaultValues}
      schema={FormSchema}
      onSubmit={handleFormSubmit}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            type="text"
            name="name"
            isRequired
            label={i18n.t('common.name')}
          />
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="authorizationId"
                isRequired
                label={i18n.t('authorization.labels.authorization')}
                options={authorizations}
                isLoading={isAuthorizationsLoading}
                menuInPortal
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="dealerNo"
                label={i18n.t('authorization.labels.dealerNumber')}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="companyId"
                isRequired
                label={i18n.t('authorization.labels.company')}
                options={companiesOptions}
                isLoading={isCompaniesLoading}
                onChange={() => {
                  formApi.setValue('branchId', null);
                  formApi.setValue('companyAddressId', null);
                }}
                menuInPortal
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="branchId"
                isRequired
                label={i18n.t('authorization.labels.branch')}
                options={getBranchOptions(formApi.watch('companyId'))}
                isLoading={isBranchesLoading}
                isDisabled={isNil(formApi.watch('companyId'))}
                onChange={(value) => {
                  isString(value) && getAddressList({branchId: value, 'x-tenant': props.tenantId});
                  formApi.setValue('companyAddressId', null);
                }}
                menuInPortal
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="companyAddressId"
                isRequired
                label={i18n.t('authorization.labels.address')}
                options={addressList}
                isLoading={isAddressListLoading}
                isDisabled={isNil(formApi.watch('branchId'))}
                menuInPortal
              />
            </Box>
            <Box flex={1}>
              <Space vertical={6} />
              <FormField
                control={control}
                type="switch"
                name="isDefault"
                label={i18n.t('common.isDefault')}
              />
            </Box>
          </HStack>
          <ButtonGroup align="right">
            <FormButton
              control={control}
              title={i18n.t('general.actions.discard')}
              onClick={closeCurrentDialog}
              variant="secondary"
            />
            <FormButton control={control} title={i18n.t('general.actions.save')} type="submit" />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const FormSchema = object({
  name: yupString.required(),
  authorizationId: yupString.required(),
  dealerNo: yupString.nullable(),
  branchId: yupString.required(),
  companyId: yupString.required(),
  companyAddressId: yupString.required(),
});
