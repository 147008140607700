import {ReactElement} from 'react';

import {TestIdProps} from 'shared';

import {PaginationBody} from './components/PaginationBody';
import {PaginationProvider} from './components/PaginationProvider';
import {defaultProps} from './constants/defaultProps';
import {PaginationVariant} from './types/PaginationVariant';

export interface PaginationProps extends TestIdProps {
  /**
   * @about
   * The pagesQuantity is number value, which says total count pages.
   */
  pagesQuantity: number;
  /**
   * @about
   * The onPageChange is function, which is calling every time on change activePage.
   * This function afford one parameter - a new activePage number.
   */
  onPageChange: (page: number) => void;
  /**
   * @about
   * The showFirstButton is boolean value, which says if this button will be shown (true) or not (false).
   * This button set activePage to the first after click.
   */
  showFirstButton?: boolean;
  /**
   * @about
   * The showPrevButton is boolean value, which says if this button will be shown (true) or not (false).
   * This button set activePage to the previous (activePage - 1) page after click.
   */
  showPrevButton?: boolean;
  /**
   * @about
   * The showNextButton is boolean value, which says if this button will be shown (true) or not (false).
   * This button set activePage to the next (activePage + 1) page after click.
   */
  showNextButton?: boolean;
  /**
   * @about
   * The showLastButton is boolean value, which says if this button will be shown (true) or not (false).
   * This button set activePage to the last (activePage - 1) page after click.
   */
  showLastButton?: boolean;
  /**
   * @about
   * The disabled is boolean value, which says if Pagination will be clickable (true) or not (false).
   */
  isDisabled?: boolean;
  /**
   * @about
   * The defaultPage is number value, which says what page will be set as activePage in initial set.
   */
  defaultPage?: number;
  /**
   * @about
   * Number of current page. Passing this property will change Pagination component to be controlled.
   * If you need Pagination component to handle its internal state, use defaultPage property instead.
   */
  page?: number;
  /**
   * @about
   * The showFirstPage is boolean value, which says if firstPage will be shown hard anchorage (true) or not (false).
   */
  showFirstPage?: boolean;
  /**
   * @about
   * The showLastPage is boolean value, which says if lastPage will be shown hard anchorage (true) or not (false).
   */
  showLastPage?: boolean;
  /**
   * @about
   * The showPageSeparator is string/ReactElement value, which get look of separator.
   */
  showPageSeparator?: string | ReactElement;
  /**
   * @about
   * The siblingCount is number value, which says how many pages will be shown around activePage.
   */
  siblingCount?: number;
  /**
   * @about
   * The boundaryCount is number value, which says how many pages will be shown near first and last page.
   */
  boundaryCount?: number;
  /**
   * @about
   * The type is string value, which says what type of input will be shown – ButtonGroup, InputSegment or TextOnlySegment.
   */
  variant?: PaginationVariant;
  /**
   * @about
   * The inputSegmentSeparator is string/ReactElement value, which define look of separator in InputSegment view.
   */
  inputSegmentSeparator?: string | ReactElement;
  /**
   * @about
   * The readonlySegmentSeparator is string/ReactElement value, which define look of separator in ReadonlySegment view.
   */
  readonlySegmentSeparator?: string;
}

export function Pagination({
  pagesQuantity,
  onPageChange,
  showFirstButton = defaultProps.showFirstButton,
  showPrevButton = defaultProps.showPrevButton,
  showNextButton = defaultProps.showNextButton,
  showLastButton = defaultProps.showLastButton,
  showPageSeparator = defaultProps.showPageSeparator,
  variant = defaultProps.variant,
  inputSegmentSeparator = defaultProps.inputSegmentSeparator,
  showFirstPage = defaultProps.showFirstPage,
  showLastPage = defaultProps.showLastPage,
  siblingCount = defaultProps.siblingCount,
  boundaryCount = defaultProps.boundaryCount,
  defaultPage = defaultProps.activePage,
  page,
  isDisabled = defaultProps.isDisabled,
  ...rest
}: PaginationProps) {
  return (
    <PaginationProvider
      showFirstButton={showFirstButton}
      showPrevButton={showPrevButton}
      showNextButton={showNextButton}
      showLastButton={showLastButton}
      showPageSeparator={showPageSeparator}
      variant={variant}
      inputSegmentSeparator={inputSegmentSeparator}
      defaultPage={defaultPage}
      page={page}
      pagesQuantity={pagesQuantity}
      isDisabled={isDisabled}
      siblingCount={siblingCount}
      boundaryCount={boundaryCount}
      showFirstPage={showFirstPage}
      showLastPage={showLastPage}
      onPageChange={onPageChange}
    >
      <PaginationBody data-testid={rest['data-testid']} />
    </PaginationProvider>
  );
}
