import {testIds} from '@omnetic-admin-service/shared';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Flag,
  FormSubmitHandler,
  openDialog,
  showNotification,
  Table,
  TableRow,
  TableRowActionsData,
  Tooltip,
  copyToClipboard,
} from 'platform/components';
import {Clickable, Text} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {
  useArchiveLicenseMutation,
  useDuplicateLicenseMutation,
  useUpdateLicenseMutation,
} from '../../../api/adminApi';
import {
  ProjectFormDialog,
  ProjectForm,
} from '../../../components/ProjectFormDialog/ProjectFormDialog';
import {i18n} from '../../../i18n/config';
import {
  ArchiveLicenseApiArg,
  DuplicateLicenseApiArg,
  LicenseResponseBody,
  ModuleResponseBody,
  UpdateLicenseApiArg,
} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';
import {EditUseCaseDialog} from './EditUseCaseDialog';
import {UseCaseTableCell} from './UseCaseTableCell';

interface ArchivedModuleProps extends TestIdProps {
  projectId: string;
  module: ModuleResponseBody;
}

export function ModuleLicenses(props: ArchivedModuleProps) {
  const [updateLicense, {isLoading}] = useUpdateLicenseMutation();
  const [duplicateLicense, {isLoading: isDuplicateLicenseLoading}] = useDuplicateLicenseMutation();
  const [archiveLicense, {isLoading: isArchiveLicenseLoading}] = useArchiveLicenseMutation();

  const onEditLicense: FormSubmitHandler<ProjectForm> = async (values) => {
    const arg: UpdateLicenseApiArg = {
      projectId: props.projectId,
      moduleId: props.module.id,
      licenseId: values.id,
      updateLicenseRequestBody: {
        name: values.name,
        description: values.description ?? null,
      },
    };
    await updateLicense(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.editLicenseSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onDuplicateLicense: FormSubmitHandler<ProjectForm> = async (values) => {
    const arg: DuplicateLicenseApiArg = {
      projectId: props.projectId,
      moduleId: props.module.id,
      licenseId: values.id,
      duplicateLicenseRequestBody: {
        name: values.name,
      },
    };
    await duplicateLicense(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.duplicateLicenseSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onArchiveLicense = async (licenseId?: string) => {
    if (!licenseId) {
      showNotification.error(i18n.t('general.message.generalError'));
      return;
    }
    const arg: ArchiveLicenseApiArg = {
      projectId: props.projectId,
      moduleId: props.module.id,
      licenseId,
    };
    await archiveLicense(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.archiveLicenseSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onDuplicateLicenseDialogOpen = (license: ProjectForm) =>
    openDialog(
      <ProjectFormDialog
        readOnlyDescription
        description={i18n.t('projects.duplicateLicenseDescription')}
        isLoading={isDuplicateLicenseLoading}
        defaultValues={license}
        onSubmit={onDuplicateLicense}
        data-testid={suffixTestId('duplicateLicense', props)}
      />,
      {
        id: 'duplicateLicenseDialog',
        title: i18n.t('projects.duplicateLicenseTitle', {name: license.name}),
        'data-testid': suffixTestId('duplicateLicense', props),
      }
    );

  const onEditLicenseDialogOpen = (license: ProjectForm) =>
    openDialog(
      <ProjectFormDialog
        readOnlyDescription
        description={i18n.t('projects.duplicateLicenseDescription')}
        isLoading={isLoading}
        defaultValues={license}
        onSubmit={onEditLicense}
        data-testid={suffixTestId('editLicense', props)}
      />,
      {
        id: 'editLicenseDialog',
        title: i18n.t('projects.duplicateLicenseTitle', {name: license.name}),
        'data-testid': suffixTestId('editLicense', props),
      }
    );

  const onArchiveLicenseDialogOpen = (license: ProjectForm) =>
    openDialog(
      <ButtonGroup align="right" data-testid={suffixTestId('archiveLicense-confirmDialog', props)}>
        <Button
          data-testid={suffixTestId('archiveLicense-confirmDialog-cancel', props)}
          title={i18n.t('general.actions.cancel')}
          isDisabled={isArchiveLicenseLoading}
          variant="secondary"
          onClick={closeCurrentDialog}
        />
        <Button
          data-testid={suffixTestId('archiveLicense-confirmDialog-confirm', props)}
          title={i18n.t('general.actions.confirm')}
          isLoading={isArchiveLicenseLoading}
          variant="primary"
          onClick={() => onArchiveLicense(license.id)}
        />
      </ButtonGroup>,
      {
        id: 'archiveLicenseDialog',
        title: i18n.t('projects.archiveLicenseTitle', {name: license.name}),
        'data-testid': suffixTestId('archiveLicense', props),
      }
    );

  const onEditUseCaseDialogOpen = (license: LicenseResponseBody) =>
    openDialog(
      <EditUseCaseDialog
        projectId={props.projectId}
        moduleId={props.module.id}
        license={license}
        data-testid={suffixTestId('editUseCase', props)}
      />,
      {
        id: 'editUseCaseDialog',
        title: i18n.t('projects.editUseCaseTitle', {name: license.name}),
        'data-testid': suffixTestId('editUseCase', props),
      }
    );

  const getTableActions = (license: LicenseResponseBody) => {
    const dataToStorage: ProjectForm = {
      id: license.id,
      name: license.name,
      description: license.description,
    };

    const actions: TableRowActionsData = {
      primary: [
        {
          title: i18n.t('projects.editLicense'),
          description: license.archived
            ? i18n.t('projects.licenseActionIsNotAvailable')
            : undefined,
          icon: 'image/edit',
          isDisabled: license.archived,
          onClick: () => onEditLicenseDialogOpen(dataToStorage),
        },
        {
          title: i18n.t('projects.archiveLicense'),
          description: license.archived
            ? i18n.t('projects.licenseActionIsNotAvailable')
            : undefined,
          icon: 'content/archive',
          isDisabled: license.archived,
          onClick: () => onArchiveLicenseDialogOpen(dataToStorage),
        },
        {
          title: i18n.t('projects.editUseCase'),
          description: license.archived
            ? i18n.t('projects.licenseActionIsNotAvailable')
            : undefined,
          icon: 'action/build',
          isDisabled: license.archived,
          onClick: () => onEditUseCaseDialogOpen(license),
        },
        {
          title: i18n.t('projects.duplicateLicense'),
          description: license.archived
            ? i18n.t('projects.licenseActionIsNotAvailable')
            : undefined,
          icon: 'image/filter_2',
          isDisabled: license.archived,
          onClick: () => onDuplicateLicenseDialogOpen(license),
        },
      ],
    };
    return actions;
  };

  return (
    <Table tableLayout="fixed" data-testid={props['data-testid']}>
      <TableRow actionsWidth={37}>
        <Text alternative size="small">
          {i18n.t('common.id')}
        </Text>
        <Text alternative size="small">
          {i18n.t('common.name')}
        </Text>
        <Text alternative size="small">
          {i18n.t('common.status')}
        </Text>
        <Text alternative size="small">
          {i18n.t('common.description')}
        </Text>
        <Text alternative size="small">
          {i18n.t('common.useCases')}
        </Text>
      </TableRow>
      {props.module.licenses.map((license) => (
        <TableRow
          key={`${module.id}_${license.id}`}
          data-testid={suffixTestId(`row-${license.id}`, props)}
          actions={getTableActions(license)}
        >
          <Tooltip
            label={i18n.t('common.clickMeToCopy')}
            description={license.id}
            placement="top-start"
          >
            <Clickable onClick={() => copyToClipboard(license.id)}>
              <Text
                data-testid={testIds.tenantOverview(`tenantsRow-${license.id}-id`)}
                size="small"
                noWrap
              >
                {license.id}
              </Text>
            </Clickable>
          </Tooltip>
          <Tooltip label={i18n.t('common.name')} description={license.name} placement="top-start">
            <Text
              data-testid={suffixTestId(`${license.id}-name`, props)}
              size="small"
              alternative
              noWrap
            >
              {license.name}
            </Text>
          </Tooltip>
          {license.archived ? (
            <Flag
              colorScheme="orange"
              data-testid={suffixTestId(`${license.id}-status`, props)}
              label={i18n.t('common.archived')}
            />
          ) : (
            <Flag
              colorScheme="green"
              data-testid={suffixTestId(`${license.id}-status`, props)}
              label={i18n.t('common.active')}
            />
          )}
          <Tooltip
            label={i18n.t('common.description')}
            description={license.description ?? '-'}
            placement="top-start"
          >
            <Text
              data-testid={suffixTestId(`${license.id}-description`, props)}
              size="small"
              noWrap
            >
              {license.description ?? '-'}
            </Text>
          </Tooltip>
          <UseCaseTableCell useCaseIds={license?.useCaseIds} />
        </TableRow>
      ))}
    </Table>
  );
}
