import {pipe, replace} from 'ramda';

export const EMAIL_REGEX = /(([a-zA-Z0-9_.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}))/g;
export const URL_REGEX =
  /((https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+~#?&/=]*)))/g;

/**
 * Wraps email and url values in their markup syntax
 */
export const getFormattedValue = pipe(
  replace(/(email|url)\[(.*?)\]/g, '$2'),
  replace(EMAIL_REGEX, 'email[$1]'),
  replace(URL_REGEX, 'url[$1]')
);
