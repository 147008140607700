import {
  Action,
  Attributes,
  AttributesRow,
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  closeDialog,
  EmptyStatus,
  Flag,
  FormSubmitHandler,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Heading, HStack, Right, Show, Space, VStack} from 'platform/foundation';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {
  useAddLicenseMutation,
  useArchiveModuleMutation,
  useUpdateModuleMutation,
} from '../../../api/adminApi';
import {
  ProjectFormDialog,
  ProjectForm,
} from '../../../components/ProjectFormDialog/ProjectFormDialog';
import {i18n} from '../../../i18n/config';
import {
  AddLicenseApiArg,
  ArchiveModuleApiArg,
  ModuleResponseBody,
  UpdateModuleApiArg,
} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';
import {ModuleLicenses} from './ModuleLicenses';

interface ModuleSectionProps extends TestIdProps {
  projectId: string;
  archived?: boolean;
  data?: ModuleResponseBody[];
}

export function ModuleSection(props: ModuleSectionProps) {
  const [updateModule, {isLoading: isEditModuleLoading}] = useUpdateModuleMutation();
  const [addLicense, {isLoading: isCreateLicenseLoading}] = useAddLicenseMutation();
  const [archiveModule, {isLoading: isArchiveModuleLoading}] = useArchiveModuleMutation();

  const onEditDialogOpen = (module: ProjectForm) =>
    openDialog(
      <ProjectFormDialog
        description={i18n.t('projects.editModuleDescription')}
        isLoading={isEditModuleLoading}
        defaultValues={{
          id: module.id,
          name: module.name,
          description: module.description,
        }}
        onSubmit={onEditModule}
        data-testid={suffixTestId('editModule', props)}
      />,
      {
        title: i18n.t('projects.editModuleTitle', {name: module.name}),
        'data-testid': suffixTestId('editModule', props),
      }
    );

  const onCreateLicenseDialog = (module: ProjectForm) =>
    openDialog(
      <ProjectFormDialog
        isLoading={isCreateLicenseLoading}
        defaultValues={{
          id: module.id,
          name: '',
        }}
        onSubmit={onCreateLicense}
        data-testid={suffixTestId('createLicense', props)}
      />,
      {
        title: i18n.t('projects.createLicenseTitle', {name: module.name}),
        'data-testid': suffixTestId('createLicense', props),
      }
    );

  const onArchiveModuleDialogOpen = (module: ProjectForm) =>
    openDialog(
      <ButtonGroup align="right" data-testid={suffixTestId('archiveModule', props)}>
        <Button
          data-testid={suffixTestId('archiveModule-confirmDialog-cancel', props)}
          variant="secondary"
          onClick={closeCurrentDialog}
          isDisabled={isArchiveModuleLoading}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          data-testid={suffixTestId('archiveModule-confirmDialog-confirm', props)}
          variant="primary"
          onClick={() => onArchiveModule(module.id)}
          isLoading={isArchiveModuleLoading}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>,
      {
        title: i18n.t('projects.createLicenseTitle', {name: module.name}),
        'data-testid': suffixTestId('archiveModule', props),
      }
    );

  const onEditModule: FormSubmitHandler<ProjectForm> = async (values) => {
    const arg: UpdateModuleApiArg = {
      projectId: props.projectId,
      moduleId: values.id,
      updateModuleRequestBody: {
        name: values.name,
        description: values.description ?? null,
      },
    };

    await updateModule(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.editModuleSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onCreateLicense: FormSubmitHandler<ProjectForm> = async (values) => {
    const arg: AddLicenseApiArg = {
      projectId: props.projectId,
      moduleId: values.id,
      addLicenseRequestBody: {
        name: values.name,
        description: values.description ?? null,
      },
    };

    await addLicense(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.createLicenseSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onArchiveModule = (moduleId?: string) => {
    if (!moduleId) {
      showNotification.error(i18n.t('general.message.generalError'));
      return;
    }
    const arg: ArchiveModuleApiArg = {
      projectId: props.projectId,
      moduleId,
    };
    archiveModule(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('projects.archiveModuleSuccessMessage'));
        closeCurrentDialog();
      })
      .catch((err) => handleApiError(err, {callback: () => closeDialog('archiveModuleDialog')}));
  };

  const getTitle = (title: string, length?: number) => `${title} (${length ?? 0})`;

  return (
    <Card
      variant="inlineWhite"
      isClosedByDefault
      isExpandable
      data-testid={props['data-testid']}
      title={getTitle(
        props.archived ? i18n.t('projects.archivedModules') : i18n.t('projects.activeModules'),
        props.data?.length
      )}
    >
      <VStack spacing={4}>
        {props.data?.map((module) => (
          <Card
            key={module.id}
            data-testid={suffixTestId(module.id, props)}
            isExpandable
            variant="inlineGrey"
            isClosedByDefault
            title={getTitle(module.name, module.licenses?.length)}
            actions={buildArray<Action>()
              .whenNot(module.archived, {
                type: 'button',
                variant: 'secondary',
                title: i18n.t('common.edit'),
                onClick: () => onEditDialogOpen(module),
                'data-testid': suffixTestId(`${module.id}-edit`, props),
              })
              .whenNot(module.archived, {
                type: 'button',
                variant: 'secondary',
                title: i18n.t('general.actions.archive'),
                onClick: () => onArchiveModuleDialogOpen(module),
                'data-testid': suffixTestId(`${module.id}-archive`, props),
              })}
          >
            <Box width={177}>
              <Card variant="inlineWhite">
                <Attributes
                  size="third"
                  data-testid={suffixTestId(`${module.id}-info`, props)}
                  rows={buildArray<AttributesRow>()
                    .add({label: i18n.t('common.id'), value: module?.id})
                    .add({label: i18n.t('common.name'), value: module?.name})
                    .add({label: i18n.t('common.description'), value: module?.description})}
                />
                <Show when={module.archived}>
                  <Right>
                    <Flag colorScheme="orange" label={i18n.t('common.archived')} />
                  </Right>
                </Show>
              </Card>
            </Box>
            <Separator />
            <Show when={!module.archived}>
              <HStack width="100%" justify="space-between">
                <Heading size={4}>{i18n.t('common.licenses')}</Heading>
                <Button
                  data-testid={suffixTestId(`${module.id}-openCreateLicenseDialog`, props)}
                  key="create-project"
                  variant="ghostLink"
                  leftIcon="content/add_circle"
                  onClick={() => onCreateLicenseDialog(module)}
                  title={i18n.t('projects.createLicense')}
                />
              </HStack>
            </Show>
            <Space vertical={4} />
            <Show when={module.licenses?.length}>
              <ModuleLicenses
                data-testid={suffixTestId(module.id, props)}
                projectId={props.projectId}
                module={module}
              />
            </Show>
            <Show when={!module.licenses?.length}>
              <EmptyStatus
                data-testid={suffixTestId(`${module.id}-emptyLicenses`, props)}
                headline={i18n.t('projects.noAssignedLicenses')}
              />
            </Show>
          </Card>
        ))}
      </VStack>
    </Card>
  );
}
