import {append} from 'ramda';

import {MenuItem} from '../types';
import {MenuFlatList, menuItemsTreeToFlatList} from '../utils';

const emptyArrayConst: string[] = [];
function findActiveItemsIds(index: MenuFlatList, activeItemId: string): string[] {
  const indexedActiveLink = index.find((item) => item.id === activeItemId);
  if (indexedActiveLink === undefined) return emptyArrayConst;

  return append(activeItemId, indexedActiveLink.parentGroupsIds);
}

export function useActiveItems(items: MenuItem[], activeItemId?: string) {
  if (!activeItemId) return emptyArrayConst;

  const itemsFlatList = menuItemsTreeToFlatList(items);
  return findActiveItemsIds(itemsFlatList, activeItemId);
}
