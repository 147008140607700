import {Show, Spinner} from 'platform/foundation';
import styled, {keyframes} from 'styled-components';

import {useLayoutEffect, useRef} from 'react';

import {useBoolean} from 'shared';

import {LightboxApi} from '../hooks/useLightbox';
import {useSlideEvents} from '../hooks/useSlideEvents';
import {LightboxState} from '../types/LightboxState';

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOutLeft = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(-50%);
  }
`;

const fadeOutRight = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(50%);
  }
`;

const LightboxContentWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  &.isReturning {
    transition: transform 0.2s ease-out;
  }
`;

export const LightboxSlideStyled = styled.div<LightboxState>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 100%;
  transition: transform 0.2s ease-out;
  position: absolute;
  z-index: 0;
  opacity: 0;
  user-select: none;
  will-change: translate;
  cursor: zoom-in;

  &.isMoving {
    cursor: grabbing;
  }

  &.isZoomed {
    cursor: zoom-out;

    &.isMoving {
      cursor: grabbing;
    }
  }

  &.isActive {
    opacity: 1;
    z-index: 99999;
    position: relative;

    &.fadeInRight {
      animation: ${fadeInRight} 0.5s;
    }

    &.fadeInLeft {
      animation: ${fadeInLeft} 0.5s;
    }
  }

  &.fadeOutLeft {
    animation: ${fadeOutLeft} 0.5s;
  }

  &.fadeOutRight {
    animation: ${fadeOutRight} 0.5s;
  }

  .visual {
    position: relative;
  }

  .visual {
    position: relative;
    will-change: translate;
    height: 100%;
  }

  .image {
    height: 100%;
    user-select: none;
    object-fit: contain;
  }

  .original {
    position: absolute;
    display: block;
    object-fit: contain;
    user-select: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export interface LightboxItemProps {
  src: string;
  title?: string;
  isActive: boolean;
  api: LightboxApi;
  original?: string;
}

export function LightboxSlide({src, original, title, api, isActive}: LightboxItemProps) {
  const slide = useRef(null);
  const content = useRef(null);
  const visual = useRef(null);
  const originalSrc = useRef('');
  const [loading, startLoading, stopLoading] = useBoolean();

  useLayoutEffect(() => {
    if (isActive && api.state.isZoomed && original) {
      startLoading();
      originalSrc.current = original;
    } else originalSrc.current = '';
  }, [isActive, api.state.isZoomed, original, startLoading]);

  //const loading = !api.slides?.[api.activeSlide].loaded;

  const mouseEvents = useSlideEvents(api, slide, content, visual);

  const handleError = () => {
    stopLoading();
    api.resetZoom();
  };

  return (
    <LightboxSlideStyled ref={slide} {...mouseEvents}>
      <LightboxContentWrapper className="content" ref={content}>
        {/*loading ? (
          <Spinner color="palettes.neutral.10.100" />
        ) : (
          <img draggable="false" src={src} alt={title} />
        )*/}
        {/* There is style relying on this class */}
        {/* eslint-disable-next-line react/forbid-dom-props */}
        <div className="visual" ref={visual}>
          {/* There is style relying on this class */}
          {/* eslint-disable-next-line react/forbid-dom-props */}
          <img className="image" draggable="false" src={src} alt={title} />
          <Show when={isActive && api.state.isZoomed && original}>
            {/* eslint-disable react/forbid-dom-props */}
            <img
              className="original"
              onLoad={stopLoading}
              onError={handleError}
              draggable="false"
              src={originalSrc.current}
              alt={title}
            />
            <Show when={loading}>
              <Spinner variant="overlay" size="small" />
            </Show>
          </Show>
        </div>
      </LightboxContentWrapper>
    </LightboxSlideStyled>
  );
}
