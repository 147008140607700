import {useContext, useMemo} from 'react';

import {PaginationContext} from './context';
import {getPageNumbers} from './getPageNumbers';

type Pages = {
  pages: number[];
};

export const useGeneratePages = (): Pages => {
  const {state} = useContext(PaginationContext);
  const {pagesQuantity, activePage, siblingCount, boundaryCount, showFirstPage, showLastPage} =
    state;

  const pages = useMemo(
    () =>
      getPageNumbers(
        pagesQuantity,
        activePage,
        siblingCount,
        boundaryCount,
        showFirstPage,
        showLastPage
      ),
    [pagesQuantity, activePage, siblingCount, boundaryCount, showFirstPage, showLastPage]
  );

  return {pages};
};
