import {ComponentStyleConfig} from '@chakra-ui/react';

export const previewTheme: ComponentStyleConfig = {
  parts: ['imageVariant', 'imageSize', 'overlay'],
  baseStyle: {
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  },
};
