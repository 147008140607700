import {Nullish} from 'shared';

import {formatPhoneNumber} from './formatPhoneNumber';

export type PhoneNumberFormatter = (
  phoneNumber: string | Nullish,
  defaultCountry?: string | Nullish
) => string | null;

export function usePhoneNumberFormatter(): PhoneNumberFormatter {
  return formatPhoneNumber;
}
