import {css} from 'styled-components';

export const getChoiceCSS = (
  isInvalid?: boolean,
  isDisabled?: boolean,
  isRecommended?: boolean
) => {
  const shouldApplyRecommendedStyle = isRecommended && !isInvalid && !isDisabled;

  return css`
    .eag--choice__control {
      border-color: ${({theme}) =>
        (isInvalid && theme.colors.severity.danger) ||
        (isDisabled && theme.colors.palettes.neutral[70][40]) ||
        undefined};
      background-color: ${({theme}) =>
        (shouldApplyRecommendedStyle && theme.colors.palettes.orange[10][100]) || undefined};

      :hover,
      :focus-within {
        background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
      }
    }
  `;
};
