import {Blocker, History, Transition, Action} from 'history';

import {ContextType, useContext, useEffect} from 'react';
import 'react-dom';
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';

import {isFeatureEnabled} from 'shared';

interface Navigator extends BaseNavigator {
  block: History['block'];
  location: History['location'];
  action: Action;
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator;
};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */

const UNSAVED_FORMS_FF = 'core_unsaved_forms';

export function useBlocker(blocker: Blocker) {
  const navigationContext = useContext(NavigationContext) as NavigationContextWithBlock;

  useEffect(() => {
    if (!isFeatureEnabled(UNSAVED_FORMS_FF)) return;

    const unblock = navigationContext?.navigator.block((tx: Transition) => {
      const unblockTransitionAfterRetry = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it.
          unblock();
          tx.retry();
        },
      };

      blocker(unblockTransitionAfterRetry);
    });

    return unblock;
  }, [navigator, blocker]);
}
