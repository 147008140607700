import {Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
  MouseEvent,
  KeyboardEventHandler,
} from 'react';

import {isNil} from 'ramda';

import {Box, CSSObject, useMultiStyleConfig} from '@chakra-ui/react';
import {cx} from '@chakra-ui/utils';

import {suffixTestId, Nullish, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {rangeArray} from '../../utils/rangeArray';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {DisplayValue, SliderTick, SliderValue} from './types';
import {getNonlinearValue} from './utils/getNonlinearValue';
import {getStep} from './utils/getStep';
import {getThumbLabelDisplay} from './utils/getThumbLabelDisplay';
import {roundToStep} from './utils/roundToStep';
import {toPercentage} from './utils/toPercentage';
import {withHalfTicks} from './utils/withHalfTicks';

type AllowMoveTrack<IsRange extends boolean = false> = IsRange extends true ? boolean : false;

export interface SliderProps<IsRange extends boolean = false>
  extends FormControlProps<SliderValue<IsRange>>,
    TestIdProps {
  min: number;
  max: number;
  onlyEdges?: boolean;
  step: number | number[];
  longStepCount?: number;
  isRange?: IsRange | Nullish;
  ticks?: boolean | number | SliderTick[];
  nonlinearTicks?: SliderTick[];
  allowMoveStart?: boolean;
  isAllowInverted?: boolean;
  displayValue?: DisplayValue;
  halfTicks?: boolean;
  allowMoveTrack?: AllowMoveTrack<IsRange>;
  formatValue?: (value: number) => string;
}

export function Slider<IsRange extends boolean = false>(props: SliderProps<IsRange>) {
  const {
    onChange,
    value,
    isRange,
    onlyEdges,
    allowMoveStart = true,
    min = 0,
    max = 100,
    step,
    longStepCount = 10,
    isAllowInverted,
    isDisabled,
    ticks: ticksProp = true,
    displayValue = 'off',
    halfTicks = false,
    formatValue = (x: number) => `${x}`,
    nonlinearTicks,
    allowMoveTrack,
  } = props;

  const trackRef = useRef<HTMLDivElement>(null);
  const startThumbRef = useRef<HTMLDivElement>(null);
  const endThumbRef = useRef<HTMLDivElement>(null);
  const [currentDraggedThumb, setCurrentDraggedThumb] = useState<null | 'start' | 'end'>(null);

  const {
    wrapper: wrapperStyles,
    track: trackStyles,
    filledTrack: filledTrackStyles,
    unfilledTrack: unfilledTrackStyles,
    thumb: thumbStyles,
    thumbWrapper: thumbWrapperStyles,
    thumbSingle: thumbSingleStyles,
    thumbSingleWrapper: thumbSingleWrapperStyles,
    thumbStartWrapper: thumbStartWrapperStyles,
    thumbEndWrapper: thumbEndWrapperStyles,
    tick: tickStyles,
    ticks: ticksStyles,
    tickLabel: tickLabelStyles,
    tickIndicator: tickIndicatorStyles,
    thumbLabel: thumbLabelStyles,
  } = useMultiStyleConfig('Slider', {...props, value});

  const dragAnchor = match(currentDraggedThumb)
    .with('start', () => startThumbRef.current)
    .with('end', () => endThumbRef.current)
    .otherwise(() => null);

  const [sliderMoveStartPosition, setSliderMoveStartPosition] = useState<number | null>(null);

  const calculateValue = useCallback(
    (clientX: number) => {
      if (!trackRef.current) return;

      const trackRect = trackRef.current.getBoundingClientRect();

      const clickOffset =
        Math.max(trackRect.x, Math.min(trackRect.x + trackRect.width, clientX)) - trackRect.x;

      let newValue = 0;
      if (nonlinearTicks) {
        const nonlinearValue = getNonlinearValue({
          min,
          max,
          clickOffset,
          nonlinearTicks,
          trackWidth: trackRect.width,
        });

        newValue = roundToStep(nonlinearValue, step);
      } else {
        const value = (max - min) * (clickOffset / trackRect.width);

        newValue = roundToStep(min + value, step);
      }

      return newValue;
    },
    [max, min, nonlinearTicks, step]
  );

  const updateValue = useCallback(
    (clientX: number, end: boolean) => {
      if (isDisabled) {
        return;
      }

      const newValue = calculateValue(clientX);

      if (isNil(newValue)) return;

      const x = (old: any): number | [number, number] => {
        if (Array.isArray(old)) {
          const newPair: [number, number] = end ? [old[0], newValue] : [newValue, old[1]];

          if (!isAllowInverted) {
            if (end && newValue < old[0]) {
              setCurrentDraggedThumb('start');
              return [newValue, old[0]];
            }

            if (!end && newValue > old[1]) {
              setCurrentDraggedThumb('end');
              return [old[1], newValue];
            }
          }

          return newPair;
        }

        return newValue;
      };

      onChange?.(x(value) as SliderValue<IsRange>);
    },
    [calculateValue, onChange, value, isAllowInverted, isDisabled]
  );

  const onTrackClick = (event: MouseEvent) => {
    if (dragAnchor) {
      return;
    }

    if (event.currentTarget.getAttribute('data-eagsliderdragged') === 'true') {
      event.currentTarget.setAttribute('data-eagsliderdragged', 'false');
      return;
    }

    if (!isRange) {
      return updateValue(event.clientX, false);
    }

    const thumbs = [...event.currentTarget.querySelectorAll('.eag--slider__thumb')] as [
      Element,
      Element | undefined,
    ];

    const endThumb = thumbs[1];
    if (!endThumb) {
      // actually missing start thumb because of allowMoveStart
      return updateValue(event.clientX, true);
    }

    const startRect = thumbs[0].getBoundingClientRect();
    const startCentre = startRect.left + (startRect.right - startRect.left) / 2;
    const startDiff = Math.abs(event.clientX - startCentre);

    const endRect = endThumb.getBoundingClientRect();
    const endCentre = endRect.left + (endRect.right - endRect.left) / 2;
    const endDiff = Math.abs(event.clientX - endCentre);

    updateValue(event.clientX, endDiff <= startDiff);
  };

  const onDragStart = (thumb: 'start' | 'end') => {
    if (dragAnchor) {
      return;
    }
    trackRef.current?.setAttribute('data-eagsliderdragged', 'true');
    setCurrentDraggedThumb(thumb);
  };

  useEffect(() => {
    if (!dragAnchor || isDisabled) {
      return;
    }

    const onDrag = (event: globalThis.MouseEvent) => {
      if (!trackRef.current) {
        return;
      }

      event.preventDefault();
      if (dragAnchor.classList.contains('eag--slider__filled-track')) {
        const trackSizes = dragAnchor.getBoundingClientRect();
        const move = event.clientX - (sliderMoveStartPosition ?? 0);

        const value1 = calculateValue(trackSizes.left + move);
        const value2 = calculateValue(trackSizes.left + trackSizes.width + move);

        setSliderMoveStartPosition(event.clientX);
        onChange?.([value1, value2] as SliderValue<IsRange>);
      } else {
        updateValue(event.clientX, currentDraggedThumb === 'end');
      }
    };

    const onDragStop = () => setCurrentDraggedThumb(null);

    const disableGlobalSelect = (event: Event) => {
      event.preventDefault();
    };

    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', onDragStop);
    window.addEventListener('selectstart', disableGlobalSelect);

    return () => {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', onDragStop);
      window.removeEventListener('selectstart', disableGlobalSelect);
    };
  }, [
    dragAnchor,
    updateValue,
    isDisabled,
    sliderMoveStartPosition,
    setSliderMoveStartPosition,
    calculateValue,
    onChange,
  ]);

  const onKey: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (isDisabled) {
      return;
    }

    const targetElement = event.target instanceof Element ? event.target : null;
    const valueIndex = targetElement?.id === 'thumb-end' ? 1 : 0;

    const selectedValue = Array.isArray(value) ? value[valueIndex] : Number(value);

    let delta = 0;
    switch (event.code) {
      case 'Home':
        event.stopPropagation();
        return onChange?.(min as SliderValue<IsRange>);
      case 'End':
        event.stopPropagation();
        return onChange?.(max as SliderValue<IsRange>);
      case 'ArrowRight':
      case 'ArrowUp':
        event.stopPropagation();
        delta = getStep(selectedValue, step);
        break;
      case 'ArrowLeft':
      case 'ArrowDown':
        event.stopPropagation();
        delta = -getStep(selectedValue, step, 'prev');
        break;
      case 'PageUp':
        event.stopPropagation();
        delta = Array.isArray(step) ? getStep(selectedValue, step) : step * longStepCount;
        break;
      case 'PageDown':
        event.stopPropagation();
        delta = -(Array.isArray(step)
          ? getStep(selectedValue, step, 'prev')
          : step * longStepCount);
        break;
    }
    if (isNil(delta) || delta === 0) {
      return;
    }

    if (isRange) {
      const isEnd = valueIndex === 1;

      const nextValue: [number, number] = isEnd
        ? [
            (value as [number, number])[0],
            Math.max(min, Math.min(max, (value as [number, number])[1] + delta)),
          ]
        : [
            Math.max(min, Math.min(max, (value as [number, number])[0] + delta)),
            (value as [number, number])[1],
          ];

      if (!isAllowInverted && nextValue[0] > nextValue[1]) {
        return;
      }

      onChange?.(nextValue as SliderValue<IsRange>);
    } else {
      const nextValue = Math.max(min, Math.min(max, (value as number) + delta));
      onChange?.(nextValue as SliderValue<IsRange>);
    }
  };

  const valueStyles = isRange
    ? ({
        '--slider-offset-start': String(
          toPercentage(min, max, (value as [number, number])[0], nonlinearTicks)
        ),
        '--slider-offset-end': String(
          toPercentage(min, max, (value as [number, number])[1], nonlinearTicks)
        ),
        '--slider-fill-size': String(
          Math.abs(
            toPercentage(min, max, (value as [number, number])[1], nonlinearTicks) -
              toPercentage(min, max, (value as [number, number])[0], nonlinearTicks)
          )
        ),
      } as CSSObject)
    : ({
        '--slider-offset': String(toPercentage(min, max, value as number, nonlinearTicks)),
      } as CSSObject);

  const labelStyles = {
    '--thumb-label-display': getThumbLabelDisplay(displayValue),
    '--thumb-label-display-hover': getThumbLabelDisplay(displayValue, true),
  } as CSSObject;

  const disabledProps = isDisabled
    ? ({
        'data-disabled': '',
        'aria-disabled': 'true',
      } as const)
    : {};

  const ticks = useMemo<SliderTick[]>(() => {
    if (typeof ticksProp === 'number' && ticksProp < 0) {
      throw new Error('Cannot have negative count of ticks');
    }

    if (isNil(ticksProp) || ticksProp === 0) return [];

    let ticksToReturn: SliderTick[] =
      nonlinearTicks ?? (Array.isArray(ticksProp) ? (ticksProp as SliderTick[]) : []);

    if (!nonlinearTicks && (ticksProp === true || typeof ticksProp === 'number')) {
      const ticksCount = ticksProp === true ? 3 : ticksProp;
      const step = ticksCount === 1 ? 0 : (max - min) / (ticksCount - 1);

      ticksToReturn = rangeArray(0, ticksCount).map((i) => ({
        value: min + step * i,
      }));
    }

    return halfTicks ? withHalfTicks(ticksToReturn) : ticksToReturn;
  }, [ticksProp, max, min, halfTicks, nonlinearTicks]);

  const onTrackDragStart = (event: MouseEvent) => {
    if (dragAnchor || !allowMoveTrack) {
      return;
    }
    trackRef.current?.setAttribute('data-eagsliderdragged', 'true');
    setSliderMoveStartPosition(event.clientX);
    setCurrentDraggedThumb('start');
  };

  return (
    <VStack>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <Box
        data-testid={suffixTestId('sliderWrapper', props)}
        onKeyDown={onKey}
        role="slider"
        {...disabledProps}
        sx={{...valueStyles, ...labelStyles, ...wrapperStyles}}
      >
        <Box
          ref={trackRef}
          data-testid={suffixTestId('sliderTrack', props)}
          sx={trackStyles}
          onClick={onTrackClick}
          {...disabledProps}
        >
          <Box
            data-testid={suffixTestId('sliderUnfilledTrack', props)}
            sx={unfilledTrackStyles}
            {...disabledProps}
          />
          <Box
            data-testid={suffixTestId('sliderFilledTrack', props)}
            onMouseDown={onTrackDragStart}
            sx={filledTrackStyles}
            {...disabledProps}
          />

          {isRange ? (
            <>
              <Show when={allowMoveStart}>
                <Box
                  data-testid={suffixTestId('sliderThumbStartWrapper', props)}
                  sx={{
                    ...thumbWrapperStyles,
                    ...thumbStartWrapperStyles,
                  }}
                  {...disabledProps}
                >
                  <Box
                    className="eag--slider__thumb"
                    ref={startThumbRef}
                    data-testid={suffixTestId('sliderThumbStart', props)}
                    onMouseDown={() => onDragStart('start')}
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={(value as number[])[0]}
                    aria-valuetext={formatValue((value as [number, number])[0])}
                    tabIndex={isDisabled ? -1 : 0}
                    sx={thumbStyles}
                    {...disabledProps}
                  >
                    <Box className="eag--slider__thumb-label" sx={thumbLabelStyles}>
                      {formatValue((value as number[])[0])}
                    </Box>
                  </Box>
                </Box>
              </Show>
              <Box
                data-testid={suffixTestId('sliderThumbEndWrapper', props)}
                sx={{
                  ...thumbWrapperStyles,
                  ...thumbEndWrapperStyles,
                }}
                {...disabledProps}
              >
                <Box
                  id="thumb-end"
                  ref={endThumbRef}
                  className="eag--slider__thumb"
                  data-testid={suffixTestId('sliderThumbEnd', props)}
                  onMouseDown={() => onDragStart('end')}
                  aria-valuemin={min}
                  aria-valuemax={max}
                  aria-valuenow={(value as number[])[1]}
                  aria-valuetext={formatValue((value as [number, number])[1])}
                  tabIndex={isDisabled ? -1 : 0}
                  sx={thumbStyles}
                  {...disabledProps}
                >
                  <Box className="eag--slider__thumb-label" sx={thumbLabelStyles}>
                    {formatValue((value as number[])[1])}
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              data-testid={suffixTestId('sliderThumbSingleWrapper', props)}
              sx={{
                ...thumbWrapperStyles,
                ...thumbSingleWrapperStyles,
              }}
              {...disabledProps}
            >
              <Box
                ref={startThumbRef}
                className="eag--slider__thumb"
                data-testid={suffixTestId('sliderThumbSingle', props)}
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value as number}
                aria-valuetext={formatValue(value as number)}
                tabIndex={isDisabled ? -1 : 0}
                sx={{
                  ...thumbStyles,
                  ...thumbSingleStyles,
                }}
                onMouseDown={() => onDragStart('start')}
                {...disabledProps}
              >
                <Box className="eag--slider__thumb-label" sx={thumbLabelStyles}>
                  {formatValue(value as number)}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {ticks.length > 0 && (
          <Box data-testid={suffixTestId('sliderTicks', props)} sx={ticksStyles} {...disabledProps}>
            {ticks.map(({value, label, small}, index) => {
              const isFrist = index === 0;
              const isLast = index === ticks.length - 1;

              return (
                <Box
                  key={value}
                  className={cx(
                    'eag--slider__tick',
                    value === min ? 'eag--slider__tick-min' : undefined,
                    value === max ? 'eag--slider__tick-max' : undefined
                  )}
                  data-testid={suffixTestId('sliderTick', props)}
                  sx={{
                    '--tick-offset': String(toPercentage(min, max, value, nonlinearTicks)),
                    ...tickStyles,
                  }}
                  {...disabledProps}
                >
                  <Box
                    className={cx(
                      'eag--slider__tick-indicator',
                      small ? 'eag--slider__tick-indicator-small' : undefined
                    )}
                    data-testid={suffixTestId('sliderTickIndicator', props)}
                    sx={tickIndicatorStyles}
                    {...disabledProps}
                  />
                  <Box
                    data-testid={suffixTestId('sliderTickLabel', props)}
                    sx={tickLabelStyles}
                    {...disabledProps}
                  >
                    {onlyEdges
                      ? isFrist || isLast
                        ? formatValue(value)
                        : null
                      : label ?? formatValue(value)}
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
