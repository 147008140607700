import {css} from 'styled-components';

import {ReactElement, useEffect} from 'react';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {useElementSize} from '../../hooks/useElementSize';
import {CSSDimension} from '../../types/CSSDimension';
import {Integer} from '../../types/Integer';
import {getCssSize} from '../../utils/getCssSize';
import {Box} from '../Box/Box';
import {Center} from '../Center/Center';
import {Hide} from '../Hide/Hide';
import {Icon} from '../Icon/Icon';
import {ImageFit} from '../Image/Image';

export type RatioImageProps = {
  ratio: number;
  width?: undefined;
  height?: undefined;
  maxWidth?: CSSDimension | Integer;
  maxHeight?: CSSDimension | Integer;
};

export type SizedImageProps = {
  width: Integer | CSSDimension;
  height: Integer | CSSDimension;
  maxWidth?: Integer | CSSDimension;
  maxHeight?: Integer | CSSDimension;
  ratio?: undefined;
};

export type CommonImageProps = {
  url?: string;
  fit: ImageFit;
  makeUrl: (url: string, width: number, height: number, fit: ImageFit) => string;
  shouldUseOriginal?: boolean;
  alt?: string;
  placeholder?: ReactElement;
} & TestIdProps;

export type AdaptiveImageProps = (RatioImageProps | SizedImageProps) & CommonImageProps;

export function AdaptiveImage(props: AdaptiveImageProps) {
  const [ref, width, height] = useElementSize<HTMLImageElement>({debounce: 1000});
  const [isImageError, setImageErrorTrue, setImageErrorFalse] = useBoolean(false);

  useEffect(() => {
    setImageErrorFalse();
  }, [setImageErrorFalse, props.url]);

  const fit = props.fit ?? 'contain';

  const src =
    isNotNil(props.url) && isNotNil(width) && width > 0 && isNotNil(height) && height > 0
      ? props.makeUrl(props.url, normalizeSize(width), normalizeSize(height), fit)
      : undefined;
  const shouldShowImage = isNotNil(props.url) && !isImageError;

  return (
    <>
      <img
        ref={ref}
        alt={props.alt}
        src={props.shouldUseOriginal ? props.url : src}
        css={css`
          overflow: hidden;
          object-fit: ${fit};
          aspect-ratio: ${props.ratio};
          width: ${getCssSize(props.width ?? '100%')};
          height: ${getCssSize(props.height ?? '100%')};
          max-width: ${getCssSize(props.maxWidth) ?? 'initial'};
          max-height: ${getCssSize(props.maxHeight) ?? 'initial'};

          /* Hide image using CSS because we need to keep ref to get size */
          ${shouldShowImage ? '' : 'display: none;'};
        `}
        onError={() => setImageErrorTrue()}
        data-testid={suffixTestId('image', props)}
      />
      <Hide when={shouldShowImage}>
        <Box
          width={props.width}
          height={props.height}
          maxWidth={props.maxWidth}
          maxHeight={props.maxHeight}
        >
          <Center width="100%" height="100%">
            {isNotNil(props.placeholder) ? (
              props.placeholder
            ) : (
              <Icon color="palettes.neutral.100.100" value="image/photo" size={11} />
            )}
          </Center>
        </Box>
      </Hide>
    </>
  );
}

const normalizeSize = (size: number | null) =>
  Math.round((size ?? 0) * (window?.devicePixelRatio ?? 1));
