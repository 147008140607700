import {useCallback, useRef} from 'react';

type BaseFunction = (...args: any[]) => any;

/**
 * Custom hook for creating a debounced callback function.
 *
 * @param {BaseFunction} callback - The callback function to be debounced.
 * @param {number} delay - The delay in milliseconds for the debounce.
 *
 * @returns {BaseFunction} - The debounced callback function.
 *
 * @example
 * // Import the hook
 * import { useDebouncedCallback } from 'path-to-file';
 *
 * // Usage inside a functional component
 * const MyComponent: React.FC = () => {
 *   const click = useCallback((param) => {
 *     // Your callback logic here
 *     console.log(param);
 *   }, []);
 *
 *   // Set the desired debounce delay in milliseconds
 *   const debouncedClick = useDebouncedCallback(click, 300);
 *
 *   return (
 *     <div>
 *       <button onClick={debouncedClick}>Click me (Debounced)</button>
 *     </div>
 *   );
 * };
 */
export function useDebouncedCallback<T extends BaseFunction>(callback: T, delay: number): T {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // The debounced callback function created by useDebouncedCallback.
  // This function utilizes a setTimeout mechanism to debounce the original callback.
  // It prevents the original callback from being invoked immediately on every call,
  // instead, it waits for the specified delay between calls before triggering.
  const debouncedCallback = useCallback(
    (...args: Parameters<T>) => {
      const execute = () => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        callback(...args);
      };
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(execute, delay);
    },
    [callback, delay]
  ) as T;

  return debouncedCallback;
}
