import {UseFormGetValues, UseFormSetValue} from 'react-hook-form';

import {FieldValues} from './FieldValues';

export type DynamicUiRefProps = {
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  reset: (values?: FieldValues) => void;
  submit: () => void;
};
