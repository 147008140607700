import {match, Pattern} from 'ts-pattern';

import {ReactNode} from 'react';

import {always, not} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {isFeatureEnabled, Permission, hasPermission} from 'shared';

import {useDevice} from '../DeviceProvider/DeviceProvider';

export interface HideProps {
  when?: unknown;
  whenFeatureEnabled?: string;
  whenFeatureDisabled?: string;
  whenHasPermission?: Permission;
  whenWithoutPermission?: Permission;
  children: ReactNode | ReactNode[];
  onMobile?: boolean;
  onTablet?: boolean;
  onNotebook?: boolean;
  onDesktop?: boolean;
  inPrint?: boolean;
}

export function Hide(props: HideProps) {
  const hideOnMobile = isTrue(props.onMobile);
  const hideOnTablet = isTrue(props.onTablet);
  const hideOnNotebook = isTrue(props.onNotebook);
  const hideOnDesktop = isTrue(props.onDesktop);
  const hideInPrint = isTrue(props.inPrint);
  const isResponsive =
    hideOnMobile || hideOnTablet || hideOnNotebook || hideOnDesktop || hideInPrint;

  const device = useDevice();

  const isHidden = match([isResponsive, device, props])
    .with([false, Pattern.any, Pattern.any], always(false))
    .with([true, 'mobile', {onMobile: true}], always(true))
    .with([true, 'tablet', {onTablet: true}], always(true))
    .with([true, 'notebook', {onNotebook: true}], always(true))
    .with([true, 'desktop', {onDesktop: true}], always(true))
    .with([true, 'print', {inPrint: true}], always(true))
    .otherwise(always(false));

  if (isHidden) return null;

  if (!!props.when) return null;

  if (props.whenFeatureEnabled && isFeatureEnabled(props.whenFeatureEnabled)) return null;
  if (props.whenFeatureDisabled && not(isFeatureEnabled(props.whenFeatureDisabled))) return null;
  if (props.whenHasPermission && hasPermission(props.whenHasPermission)) return null;
  if (props.whenWithoutPermission && not(hasPermission(props.whenWithoutPermission))) return null;

  return <>{props.children}</>;
}
