import {Transition} from 'history';
import {Space, Text} from 'platform/foundation';

import {useState, useCallback} from 'react';

import {isPositive} from 'ramda-adjunct';

import {noop} from 'shared';

import {Button} from '../../Button/Button';
import {ButtonGroup} from '../../ButtonGroup/ButtonGroup';
import {Dialog} from '../../Dialog/Dialog';
import {useTranslationContext} from '../../TranslationProvider/TranslationProvider';
import {useBlocker} from '../hooks/useBlocker';
import {useFormSaverContext} from '../hooks/useFormSaverContext';

export function FormSaver() {
  const [handleContinue, setHandleContinue] = useState(() => noop);
  const {formsData, isDialogOpen, closeDialog, openDialog, resetForm, storage} =
    useFormSaverContext();
  const t = useTranslationContext();

  // Change route blocker. Listening on all router changes. Please keep the useCallback otherwise it will not work
  const blocker = useCallback(
    (transition: Transition) => {
      // Check if any form is changed
      if (
        !isPositive(formsData.size) ||
        ![...formsData.values()].some((form) => form.isDirtyGetter()) ||
        (transition.location?.state as {ignoreUnmountEvents?: boolean})?.ignoreUnmountEvents ||
        isDialogOpen
      ) {
        return transition.retry();
      }

      // Save transition instance for the Continue button in the Dialog
      setHandleContinue(() => transition.retry);

      openDialog();
    },
    [formsData, openDialog, isDialogOpen]
  );

  useBlocker(blocker);

  const handleContinueOnClick = () => {
    closeDialog();
    resetForm();

    if (storage?.onContinue) storage.onContinue();
    else handleContinue();
  };

  const handleCloseOnClick = () => {
    closeDialog();
    storage?.onCancel?.();
  };

  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={handleCloseOnClick}
      size="small"
      data-testid="formSaver-dialog"
      title={t('general.notifications.unsavedChanges.title')}
    >
      <Text size="small">{t('general.notifications.unsavedChanges.description')}</Text>
      <Space vertical={4} />
      <ButtonGroup align="right">
        <Button
          onClick={handleContinueOnClick}
          variant="secondary"
          data-testid="formSaverModal-button-yes"
          title={t(`general.labels.leavePage`)}
        />
        <Button
          onClick={handleCloseOnClick}
          data-testid="formSaverModal-button-no"
          title={t(`general.labels.stayOnPage`)}
        />
      </ButtonGroup>
    </Dialog>
  );
}
