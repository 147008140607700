import {createContext, useContext} from 'react';

import {TableVariant} from './types/TableVariant';

interface TableContextType {
  variant: TableVariant;
}

const Context = createContext<TableContextType>({variant: 'bordered'});

export const TableContextProvider = Context.Provider;
export const useTableContext = () => useContext(Context);
