import {useModalContext} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {IconButton} from '../../IconButton/IconButton';

interface DialogCloseButtonProps extends TestIdProps {}

export function DialogCloseButton(props: DialogCloseButtonProps) {
  const {onClose} = useModalContext();

  return (
    <IconButton
      data-testid={suffixTestId('dialogCloseButton', props)}
      icon="navigation/close"
      onClick={onClose}
    />
  );
}
