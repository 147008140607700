import {startsWith} from 'ramda';

import {Country} from '../types/Country';
import {Alpha3CountryCode} from './countryCodes';

/**
 * @about Returns country code from given phone number.
 * @param phoneNumber
 * @param countriesData
 */
export const getCountryCodeFromNumber = (
  phoneNumber: string,
  countriesData: Country[] | undefined
): Alpha3CountryCode | undefined => {
  const country = countriesData?.find((country) => startsWith(country.dialCode, phoneNumber));
  if (!country) return undefined;

  return country.countryCode;
};
