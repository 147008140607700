import {Box, HStack, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useRef} from 'react';

import {LightboxApi} from '../hooks/useLightbox';
import {useThumbnailEvents} from '../hooks/useThumbnailEvents';
import {LightboxThumbnail} from './LightboxThumbnail';

interface LightboxSliderComponent {
  api: LightboxApi;
}

const LightboxThumbnailsStyled = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  cursor: grab;

  &.isMoving {
    cursor: grabbing;
  }
`;

export function LightboxThumbnails({api}: LightboxSliderComponent) {
  const ref = useRef<HTMLDivElement>(null);
  const {onThumbnailClick, ...mouseEvents} = useThumbnailEvents(ref, api);
  const padding = api.config.loop ? 0 : 2;

  return (
    <LightboxThumbnailsStyled ref={ref} {...mouseEvents}>
      <Box paddingTop={3} paddingBottom={4} paddingLeft={padding} paddingRight={padding}>
        <VStack align="center">
          <HStack align="center" spacing={2}>
            {api.slides?.map((slide, i) => (
              <LightboxThumbnail
                key={`${i}_${slide.src}`}
                goToSlide={onThumbnailClick(i)}
                current={i === api.activeSlide}
                {...slide}
              />
            ))}
          </HStack>
        </VStack>
      </Box>
    </LightboxThumbnailsStyled>
  );
}
