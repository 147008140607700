import {useAnimatedPopper} from 'platform/components';
import {ThemeIconKey, Icon, Box, Center, Clickable, Text} from 'platform/foundation';
import styled from 'styled-components';

import {Link} from 'react-router-dom';

import {useActiveMenuItem, TestIdProps} from 'shared';

export interface MenuItemProps extends TestIdProps {
  id: string;
  icon: ThemeIconKey;
  title: string;
  path: string;
}

export function MenuItem(props: MenuItemProps) {
  const {openPopper, referenceRef, popperProps, Popper, closePopper, isOpen} = useAnimatedPopper({
    placement: 'right-start',
  });

  const [isActive] = useActiveMenuItem(props.path);

  return (
    <Clickable
      onMouseEnter={openPopper}
      onMouseLeave={closePopper}
      data-testid={props['data-testid']}
    >
      <StyledMenuItem
        ref={referenceRef}
        isActive={isActive || isOpen}
        data-testid={props['data-testid']}
      >
        <Link to={props.path}>
          <Center height={12} width={12}>
            <Icon
              data-testid={props['data-testid']}
              value={props.icon}
              color="palettes.white.10.100"
            />
          </Center>
        </Link>
      </StyledMenuItem>
      <Popper {...popperProps}>
        <StyledMenuItem isActive={isActive} data-testid={props['data-testid']}>
          <Link to={props.path}>
            <Box height={12} width={80} paddingLeft={4} paddingRight={4}>
              <Center width="100%" height="100%" justify="flex-start">
                <Text size="small" color="white" data-testid={props['data-testid']}>
                  {props.title}
                </Text>
              </Center>
            </Box>
          </Link>
        </StyledMenuItem>
      </Popper>
    </Clickable>
  );
}

type StyledMenuItemProps = {
  isActive?: boolean;
};

const StyledMenuItem = styled.div<StyledMenuItemProps>`
  background-color: ${(props) =>
    props.isActive
      ? props.theme.colors.palettes.blue['70']['100']
      : props.theme.colors.palettes.neutral['900']['100']};
  box-shadow: ${(props) => props.theme.shadows.elevation_2};
  &:hover {
    background: ${(props) => props.theme.colors.palettes.blue['70']['100']};
  }
`;
