import styled from 'styled-components';

export const LightboxOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.7;
  background: ${({theme}) => theme.colors.palettes.neutral[900][100]};
`;

export const LightboxNextButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const LightboxPrevButton = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

export const LightboxButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translateY(calc(-50% - 50px));
  z-index: 99999;
`;

export const LightboxTitle = styled.div`
  position: absolute;
  bottom: 95px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 14px;
  padding: 16px;
`;
