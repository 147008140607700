import {routes} from '@omnetic-admin-service/shared';
import {Breadcrumbs} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {LogoutIconButton} from './LogoutIconButton';
import {StatsIconButton} from './StatsIconButton';

export function Header() {
  const params = useParams();
  const location = useLocation();

  const getBreadcrumbs = useMemo(() => {
    if (location.pathname.includes(routes.tenantBase) && params.id) {
      return [
        {
          label: 'Tenants',
          href: routes.tenantOverview,
        },
        {
          label: params.id,
          isCurrentPage: true,
        },
      ];
    }
    if (location.pathname.includes(routes.projectBase) && params.id) {
      return [
        {
          label: 'Projects',
          href: routes.projectOverview,
        },
        {
          label: params.id,
          isCurrentPage: true,
        },
      ];
    }
    if (location.pathname.includes(routes.tenantBase)) {
      return [
        {
          label: 'Tenants',
          isCurrentPage: true,
        },
      ];
    }
    if (location.pathname.includes(routes.projectBase)) {
      return [
        {
          label: 'Projects',
          isCurrentPage: true,
        },
      ];
    }
    return [
      {
        label: 'Omnetic Admin Service',
        isCurrentPage: true,
      },
    ];
  }, [location.pathname, params.id]);

  return (
    <Box padding={4}>
      <HStack width="100%" height="100%" align="center" justify="space-between">
        <Breadcrumbs data-testid="layoutHeader" breadcrumbs={getBreadcrumbs} />
        <HStack align="center" spacing={2}>
          <StatsIconButton />
          <LogoutIconButton />
        </HStack>
      </HStack>
    </Box>
  );
}
