import {Icon, Link, LinkProps, Space, ThemeIconKey} from 'platform/foundation';

import {ReactElement, useMemo} from 'react';

import {map} from 'ramda';

import {chakra} from '@chakra-ui/react';
import {useMultiStyleConfig} from '@chakra-ui/system';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {IconButton} from '../IconButton/IconButton';

export type Hyperlink = {title: string} & Omit<LinkProps, 'children'>;

export const ALERT_VARIANTS_TMP = ['info', 'success', 'warning', 'error'] as const;
export type AlertVariants = (typeof ALERT_VARIANTS_TMP)[number];
export const ALERT_TYPES = ['banner', 'inline'] as const;
export type AlertTypes = (typeof ALERT_TYPES)[number];

export interface AlertProps extends TestIdProps {
  type?: AlertTypes;
  variant?: AlertVariants;
  title?: string;
  message?: Nullish | string | string[];
  hyperlinks?: Hyperlink[];
  onClose?: () => void;
}

/** Get default variant icon */
export const alertVariantIconMapping: Record<AlertVariants, ThemeIconKey> = {
  info: 'action/info',
  warning: 'alert/warning',
  success: 'action/check_circle',
  error: 'alert/error',
};

export function Alert(props: AlertProps) {
  const {title, message, hyperlinks, onClose, variant, type} = props;

  const icon = alertVariantIconMapping[variant ?? 'warning'];

  const styles = useMultiStyleConfig('Alert', {...props, colorScheme: variant, variant: type});

  const titleSection = useMemo(() => {
    if (!title) return null;

    return (
      <chakra.div __css={styles.title} data-testid={suffixTestId('alertTitle', props)}>
        {title}
      </chakra.div>
    );
  }, [title, styles, props]);

  const descriptionSection = useMemo(() => {
    if (!message) return null;

    return (
      <chakra.div __css={styles.description} data-testid={suffixTestId('alertDescription', props)}>
        {Array.isArray(message) ? getMultilineMessage(message) : message}
      </chakra.div>
    );
  }, [message, styles, props]);

  const hyperlinksSection = useMemo(() => {
    if (!hyperlinks) return null;

    return (
      <chakra.div
        __css={styles.hyperLinks}
        data-testid={suffixTestId('alertHyperLinksWrapper', props)}
      >
        {hyperlinks.map((hyperlink) => (
          <Link
            key={hyperlink.title}
            data-testid={suffixTestId('alertHyperlink', props)}
            {...hyperlink}
          />
        ))}
      </chakra.div>
    );
  }, [hyperlinks, styles, props]);

  const closeButtonSection = useMemo(() => {
    if (!onClose) return null;

    return (
      <chakra.div
        className="eag--alert__close-button-wrapper"
        __css={styles.closeButton}
        data-testid={suffixTestId('alertCloseButtonWrapper', props)}
      >
        <IconButton
          data-testid={suffixTestId('alertCloseButton', props)}
          size="small"
          onClick={onClose}
          icon="navigation/close"
        />
      </chakra.div>
    );
  }, [onClose, styles, props]);

  return (
    <chakra.div __css={styles.container} data-testid={suffixTestId('alertWrapper', props)}>
      {icon && (
        <chakra.span data-testid={suffixTestId('alertIcon', props)} __css={styles.icon}>
          <Icon value={icon} size={5} />
        </chakra.span>
      )}
      {titleSection}
      {descriptionSection}
      <Space fillAvailable />
      {hyperlinksSection}
      {closeButtonSection}
    </chakra.div>
  );
}

const getMultilineMessage = map<string, ReactElement>((line) => <div key={line}>{line}</div>);
