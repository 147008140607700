import {getCssSize, CSSDimension, Integer, Show, Text} from 'platform/foundation';
import {css} from 'styled-components';

import {CSSProperties, ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {TableContextProvider} from './context';
import {TableVariant} from './types/TableVariant';

type Column = {
  element?: ReactNode;
  width?: CSSDimension | Integer;
  colspan?: number;
};

export interface TableProps extends TestIdProps {
  tableLayout?: CSSProperties['tableLayout'];
  variant?: TableVariant;
  children?: ReactNode;
  columns?: Column[];
}

export function Table(props: TableProps) {
  return (
    <TableContextProvider value={{variant: props.variant ?? 'bordered'}}>
      <table
        data-testid={suffixTestId('table', props)}
        css={css`
          table-layout: ${props.tableLayout};
          width: 100%;
        `}
        cellPadding="0"
        cellSpacing="0"
      >
        <Show when={props.columns}>
          <thead
            css={css`
              border-bottom: 1px solid ${({theme}) => theme.colors.general.separator};
            `}
          >
            <tr>
              {props.columns?.map((column, index) => (
                <th
                  colSpan={column.colspan}
                  key={`${column.width ?? ''}-${index}`}
                  css={css`
                    width: ${getCssSize(column.width) ?? 'auto'};
                  `}
                >
                  <Text>{column.element}</Text>
                </th>
              ))}
            </tr>
          </thead>
        </Show>
        <tbody>
          <Show when={props.children}>{props.children}</Show>
        </tbody>
      </table>
    </TableContextProvider>
  );
}
