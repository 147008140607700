import {foundationTheme, ThemeRadiusPath, ThemeShadowsPath} from 'platform/foundation';

import {alertTheme} from '../components/Alert/Alert.theme';
import {badgeTheme} from '../components/Badge/Badge.theme';
import {breadcrumbTheme} from '../components/Breadcrumb/Breadcrumb.theme';
import {choiceTheme} from '../components/Choice/theme';
import {dialogTheme} from '../components/Dialog/Dialog.theme';
import {dropdownTheme} from '../components/Dropdown/Dropdown.theme';
import {paginationTheme} from '../components/Pagination/Pagination.theme';
import {phoneTheme} from '../components/PhoneInput/PhoneInput.theme';
import {previewTheme} from '../components/Preview/Preview.theme';
import {segmentTheme} from '../components/Segment/Segment.theme';
import {segmentItemTheme} from '../components/Segment/SegmentItem.theme';
import {sliderTheme} from '../components/Slider/theme';
import {uploadTheme} from '../components/Upload/Upload.theme';
import {dataGridTheme} from '../styles/DataGrid/DataGrid.theme';
import {dataGridGlobalTheme} from '../styles/DataGrid/Global.theme';

type CardTheme = {
  elevation: ThemeShadowsPath;
  borderRadius: ThemeRadiusPath;
};

const Card: CardTheme = {
  elevation: 'elevation_1',
  borderRadius: 'small',
};

type SwitchTheme = {
  trackStyle: {backgroundColor: string};
};

const Switch: SwitchTheme = {
  trackStyle: {backgroundColor: foundationTheme.colors.palettes.neutral[200][100]},
};

type ButtonTheme = {
  variants: {
    ghost: {hover: {backgroundColor: string}; active: {backgroundColor: string}};
    link: {color: string};
  };
};

const Button: ButtonTheme = {
  variants: {
    ghost: {
      hover: {backgroundColor: foundationTheme.colors.palettes.neutral[30][100]},
      active: {backgroundColor: foundationTheme.colors.palettes.neutral[40][100]},
    },
    link: {color: foundationTheme.colors.text.link},
  },
};

export const componentsTheme = {
  ...foundationTheme,
  config: {
    cssVarPrefix: 'eag',
  },
  styles: {
    global: {
      ...dataGridGlobalTheme,
      html: {
        overflowX: 'hidden',
      },
    },
  },
  components: {
    ...foundationTheme.components,
    Alert: alertTheme,
    Badge: badgeTheme,
    Breadcrumb: breadcrumbTheme,
    Card,
    DataGrid: dataGridTheme,
    Segment: segmentTheme,
    SegmentItem: segmentItemTheme,
    Button,
    Choice: choiceTheme,
    Pagination: paginationTheme,
    Dialog: dialogTheme,
    Upload: uploadTheme,
    Phone: phoneTheme,
    Slider: sliderTheme,
    Switch,
    Dropdown: dropdownTheme,
    Preview: previewTheme,
    Table: {},
    Status: {},
    Password: {},
    Form: {},
  },
} as const;

export type DefaultComponentsTheme = typeof componentsTheme;
