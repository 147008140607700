// eslint-disable-next-line no-restricted-imports
import {createSearchParams, generatePath} from 'react-router-dom';

import {defaultTo, mapObjIndexed, mergeRight, pipe, reject, toPairs} from 'ramda';
import {isNotEmpty, isNotString} from 'ramda-adjunct';

import {Nullish} from '../types/Nullish';

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}`
  ? Record<Param, string | Nullish> & NextPart
  : NextPart;

export type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : // could be replaced with Record<never, never>, but is makes the intellisense 10x harder to read
    // eslint-disable-next-line @typescript-eslint/ban-types
    ExtractParam<Path, {}>;

type BaseOptions = {
  queryParams?: Record<string, string | Nullish>;
  isSearchParamsPreserved?: boolean;
};

type OptionsType<Path> =
  ExtractParams<Path> extends Record<string, never>
    ? BaseOptions
    : BaseOptions & {params: ExtractParams<Path>};

/**
 * @about Composes a strictly typed URL path based on the provided route and options.
 * @params path: string, options: {params, queryParams}, isSearchParamsPreserved: boolean
 * @returns {string} The composed URL path.
 * @example composePath("/users/:userId", {params: {userId: "123"}, queryParams: {query: "param"}})
 * // returns "/users/123?query=param"
 */
export const composePath = <Path extends string>(
  path: Path,
  options?: OptionsType<Path>
): string => {
  const params = isWithParams(options)
    ? mapObjIndexed(defaultTo(undefined), options.params)
    : undefined;
  const isSearchParamsPreserved = options?.isSearchParamsPreserved ?? true;
  const url = generatePath(path, params);
  const currentSearchParams = new URL(window.location.href).searchParams;
  const currentSearchParamsRecord = Object.fromEntries(currentSearchParams.entries());
  //  Overwrite the duplicate current search params with the new ones
  const mergedSearchParams = mergeRight(
    currentSearchParamsRecord,
    options?.queryParams as Record<string, string>
  );
  const newSearchParams = isSearchParamsPreserved ? mergedSearchParams : options?.queryParams;
  const validSearchParams = getValidSearchParams(newSearchParams);
  const urlSearchParams = createSearchParams(validSearchParams);
  const isSearchNotEmpty = isNotEmpty([...urlSearchParams.entries()]);
  const searchParamsString = isSearchNotEmpty ? `?${urlSearchParams.toString()}` : '';

  return `${url}${searchParamsString}`;
};

const isWithParams = (
  val: Record<string, unknown> | undefined
): val is {params: Record<string, string | Nullish>} => 'params' in (val ?? {});

const getValidSearchParams = pipe(
  defaultTo({}),
  toPairs<Record<string, string>>,
  reject(([_, val]) => isNotString(val))
);
