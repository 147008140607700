import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import {App} from './App';
import {OfflineDetector} from './components/OfflineDetector/OfflineDetector';
import {GlobalStyles} from './GlobalStyles';
import './i18n/config.ts';
import {store} from './store/store';
import {ComponentsProviderWrapper} from './utils/ComponentsProviderWrapper';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ComponentsProviderWrapper>
          <App />
          <OfflineDetector />
          <GlobalStyles />
        </ComponentsProviderWrapper>
      </Provider>
    </BrowserRouter>
  </StrictMode>
);
