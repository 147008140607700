import {testIds} from '@omnetic-admin-service/shared';
import {Button, ButtonGroup, closeCurrentDialog, showNotification} from 'platform/components';
import {Space, Text} from 'platform/foundation';

import {useDeleteTenantLicenseMutation} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {DeleteTenantLicenseApiArg, TenantLicenseResponseBody} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';

interface RemoveLicenseDialogProps {
  tenantId: string;
  license: TenantLicenseResponseBody | null;
}

export function RemoveLicenseDialog(props: RemoveLicenseDialogProps) {
  const [deleteLicense, {isLoading}] = useDeleteTenantLicenseMutation();

  const onSubmit = async () => {
    const args: DeleteTenantLicenseApiArg = {
      tenantId: props.tenantId,
      licenseId: props.license!.licenseId,
    };

    await deleteLicense(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('tenants.removeLicenseSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  return (
    <>
      <Text size="small">{i18n.t('tenants.removeLicenseDescription')}</Text>

      <Space vertical={4} />

      <ButtonGroup align="right" data-testid={testIds.tenantDetail('removeLicense-actions')}>
        <Button
          data-testid={testIds.tenantDetail('removeLicense-cancel')}
          isDisabled={isLoading}
          variant="secondary"
          onClick={closeCurrentDialog}
          title={i18n.t('common.cancel')}
        />
        <Button
          data-testid={testIds.tenantDetail('removeLicense-submit')}
          isLoading={isLoading}
          onClick={onSubmit}
          title={i18n.t('common.remove')}
        />
      </ButtonGroup>
    </>
  );
}
