import {Integer, Box, Center, Clickable, HStack, Icon, ThemeColorPath} from 'platform/foundation';
import styled from 'styled-components';

import {pipe, defaultTo, path} from 'ramda';

import {suffixTestId} from 'shared';

import {FlagLabel} from '../Flag/components/FlagLabel';
import {BaseFlagProps} from '../Flag/types';
import {getColorsFromScheme, getFlagElementSizes} from '../Flag/utils';

export type TagSizeType = 'default' | 'small';

export interface TagProps extends BaseFlagProps {
  onDelete: () => void;
  onClick?: () => void;
}

export function Tag(props: TagProps) {
  const {backgroundColor, color, hoverColor} = getColorsFromScheme(
    props.colorScheme,
    props.isSubtle
  );
  const {height, textSize, labelHorizontalPadding} = getFlagElementSizes(props.size);

  const iconSize: Integer = props.size === 'small' ? 3 : 4;
  /**
   * We want the iconWrapper to be a rectangle
   */
  const iconWrapperWidth = height;

  return (
    <Clickable onClick={props.onClick}>
      <Box
        borderRadius="small"
        height={height}
        backgroundColor={backgroundColor}
        overflow="hidden"
        data-testid={suffixTestId('tagWrapper', props)}
      >
        <HStack justify="space-between" align="center" height="100%">
          <Box paddingHorizontal={labelHorizontalPadding} data-testid={suffixTestId('tag', props)}>
            <FlagLabel label={props.label} size={textSize} color={color} />
          </Box>

          <TagHoverBackgroundColor hoverColorPath={hoverColor}>
            <Center height="100%" width={iconWrapperWidth}>
              <Icon
                value="navigation/close"
                size={iconSize}
                color={color}
                onClick={(event) => {
                  event.stopPropagation();
                  props.onDelete();
                }}
                data-testid={suffixTestId('tagIcon', props)}
              />
            </Center>
          </TagHoverBackgroundColor>
        </HStack>
      </Box>
    </Clickable>
  );
}

export const TagHoverBackgroundColor = styled.div<{hoverColorPath: ThemeColorPath}>`
  height: 100%;

  &:hover {
    box-shadow: ${(props) => props.theme.shadows.elevation_2};
    background: ${(props) =>
      pipe(
        path<string>(props.hoverColorPath.split('.')),
        defaultTo('transparent')
      )(props.theme.colors)};
  }
`;
