import {Box, HStack, Space, VStack} from 'platform/foundation';

import {Outlet} from 'react-router-dom';

import {Header} from './components/Header';
import {Menu} from './components/Menu';

export function Layout() {
  return (
    <HStack height="100vh" width="100vw" maxWidth="100vw">
      <Menu />
      <Box
        flex={1}
        data-testid="layoutContent"
        height="100%"
        overflow="hidden"
        backgroundColor="palettes.white.10.100"
      >
        <VStack height="100%">
          <Header />
          <Space vertical={20} />
          <Box role="main" flex={1} overflowY="auto" overflowX="hidden" padding={4}>
            <Outlet />
          </Box>
        </VStack>
      </Box>
    </HStack>
  );
}
