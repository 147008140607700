import {FunctionComponent, SVGProps} from 'react';

import {
  ComponentStyleConfig,
  extendTheme as chakraExtendTheme,
  Theme as ChakraTheme,
  theme,
  ThemeOverride as ChakraThemeOverride,
} from '@chakra-ui/react';
import {requiredChakraThemeKeys} from '@chakra-ui/theme';

import {globalOverrides} from './globalOverrides';

export type ExtendTheme = <Overrides extends ThemeOverride = ThemeOverride>(
  overrides: Overrides
) => ChakraTheme & Overrides;

export const extendTheme: ExtendTheme = <Overrides extends ThemeOverride = ThemeOverride>(
  overrides: Overrides,
  customBaseTheme?: IndexedTheme
): ChakraTheme & Overrides => {
  const baseTheme = customBaseTheme ?? chakraBaseTheme();

  Object.keys(overrides?.components ?? {}).forEach((componentName) =>
    removeOverriddenComponentStyles(baseTheme, overrides, componentName)
  );

  return chakraExtendTheme(
    // any here is to prevent recursive type errors causing build and IDE issues
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    baseTheme as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    overrides as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mockChakraBaseTheme() as any
  ) as ChakraTheme & Overrides;
};

const mockChakraBaseTheme = () =>
  requiredChakraThemeKeys.reduce((acc, cur) => ({...acc, [cur]: {}}), {});

const removeOverriddenComponentStyles = (
  baseTheme: IndexedTheme,
  overrides: ThemeOverride,
  componentName: string
) => {
  if (overrides.removeDefaultStyles?.includes(componentName)) {
    delete baseTheme.components[componentName];
    return;
  }

  let baseComponent = baseTheme.components[componentName];
  const override = overrides.components?.[componentName];
  if (!baseComponent || !override) return;

  baseComponent = {...baseComponent};
  if (override.sizes) delete baseComponent.sizes;
  if (override.variants) delete baseComponent.variants;
  if (override.defaultProps) delete baseComponent.defaultProps;

  baseTheme.components[componentName] = baseComponent;
};

const chakraBaseTheme = (): IndexedTheme => {
  const {
    Badge: _badge,
    Divider: _divider,
    Slider: _slider,
    Menu: Dropdown,
    Modal: Dialog,
    ...components
  } = theme.components;

  return chakraExtendTheme(
    // any here is to prevent recursive type errors causing build and IDE issues
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as any,
    {
      styles: {
        global: globalOverrides,
      },
    },
    {
      ...theme,
      // eslint-disable-next-line no-restricted-syntax
      components: {
        ...components,
        Dropdown,
        Dialog,
      } as unknown as IndexedTheme['components'],
      // any here is to prevent recursive type errors causing build and IDE issues
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any
  ) as IndexedTheme;
};

export type Theme = ChakraTheme & {
  icons?: Record<string, FunctionComponent<SVGProps<SVGSVGElement> & {title?: string | undefined}>>;
  components: {
    Dropdown?: ComponentStyleConfig;
    Dialog?: ComponentStyleConfig;
  };
};

export type ThemeOverride = ChakraThemeOverride & {
  icons?: Record<string, unknown>;
  removeDefaultStyles?: string[];
};

export type IndexedTheme = Theme & {
  components: Record<string, ComponentStyleConfig>;
};
