import styled from 'styled-components';

import {ForwardedRef, forwardRef} from 'react';

import {LightboxApi} from '../hooks/useLightbox';
import {LightboxSlide} from './LightboxSlide';

interface LightboxSliderProps {
  api: LightboxApi;
}

const LightboxSliderStyled = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const LightboxSlider = forwardRef(LightboxSliderComponent);
export function LightboxSliderComponent(
  {api}: LightboxSliderProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <LightboxSliderStyled ref={ref}>
      {api.slides?.map((slide, i) => (
        <LightboxSlide
          key={`${i}_${slide.src}`}
          isActive={i === api.activeSlide}
          api={api}
          {...slide}
        />
      ))}
    </LightboxSliderStyled>
  );
}
