import {Box, Heading, HStack, Show, VStack} from 'platform/foundation';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {Button} from '../Button/Button';
import {useTranslationContext} from '../TranslationProvider/TranslationProvider';
import {NotificationItem} from './components/NotificationItem';
import {NotificationItemData} from './NotificationItemData';

interface NotificationCenterProps extends TestIdProps {
  notifications: NotificationItemData[] | Nullish;
  isDisabled?: boolean;
  onMarkAllAsRead?: VoidFunction;
  onNavigate: (url: string) => void;
  onMarkAsRead?: (id: string) => void;
  isHeaderHidden?: boolean;
}

export function NotificationCenter(props: NotificationCenterProps) {
  const t = useTranslationContext();
  const hasUnreadNotifications = props.notifications?.some((notification) => !notification.isRead);

  return (
    <VStack data-testid={props['data-testid']}>
      <Show when={!props.isHeaderHidden}>
        <Box padding={2}>
          <HStack spacing={4} align="center" justify="space-between" minHeight={8}>
            <Heading size={4}>{t('general.notifications.title')}</Heading>
            <Show when={hasUnreadNotifications}>
              <Button
                variant="link"
                isDisabled={props.isDisabled}
                onClick={props.onMarkAllAsRead}
                title={t('general.notifications.markAllAsRead')}
                data-testid={suffixTestId('readAllButton', props)}
              />
            </Show>
          </HStack>
        </Box>
      </Show>
      <VStack spacing={1}>
        {props.notifications?.map((notification, index) => (
          <NotificationItem
            data-testid={suffixTestId(`notificationItem-[${index}]`, props)}
            onMarkAsRead={props.onMarkAsRead}
            onNavigate={props.onNavigate}
            key={notification.id}
            {...notification}
          />
        ))}
      </VStack>
    </VStack>
  );
}
