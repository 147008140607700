export const routes = {
  homepage: '/',
  loginPage: '/login',
  tenantBase: '/tenant',
  tenantOverview: '/tenant/overview',
  tenantDetail: '/tenant/:id',
  projectBase: '/project',
  projectOverview: '/project/overview',
  projectDetail: '/project/:id',
} as const;
