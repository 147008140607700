import {
  TextProps,
  getColorFromSeverity,
  Severity,
  ThemeIconKey,
  Icon,
  Show,
  Text,
  Spinner,
} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {MouseEventHandler, ReactElement, ReactNode} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {MenuItem} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

export interface DropdownItemProps extends TestIdProps {
  label: string | ReactElement<TextProps, typeof Text>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  prefix?: ReactNode;
  suffix?: ReactElement;
  leftIcon?: ThemeIconKey;
  isLoading?: boolean;
  severity?: Severity;
  isActive?: boolean;
}

export function DropdownItem(props: DropdownItemProps) {
  const theme = useTheme();
  const iconColor = props.severity ? getColorFromSeverity(props.severity) : undefined;
  const backgroundColor = props.isActive ? theme.colors.general.accent : 'none';

  const icon = props.leftIcon && (
    <Show when={props.leftIcon}>
      <Icon color={iconColor} value={props.leftIcon} size={4} />
    </Show>
  );

  const content = (
    <>
      {props.label}
      {props.isLoading && <Spinner variant="overlay" size="small" />}
    </>
  );

  return (
    <MenuItem
      backgroundColor={backgroundColor}
      onClick={props.onClick}
      isDisabled={props.isDisabled}
      autoFocus={false}
      icon={icon ? icon : (isNotNil(props.prefix) && <>{props.prefix}</>) || undefined}
      // eslint-disable-next-line no-restricted-syntax
      command={props.suffix && ((<>{props.suffix}</>) as unknown as string)}
      data-testid={suffixTestId('dropdownItem', props)}
    >
      {content}
    </MenuItem>
  );
}
