import {ReactNode} from 'react';

import {allPass, always, has, is, isNotNil, propSatisfies} from 'ramda';

/**
 * A type guard function to check if the given value is a function.
 * TypeScript is great for type-checking at compile time, but it does not have runtime type information.
 * Therefore, TypeScript can't validate that a function will definitely return a ReactNode at runtime.
 */
const isReactNodeFunction = (val: unknown): val is (...args: unknown[]) => ReactNode =>
  typeof val === 'function';

/**
 * This function checks whether a given key in an object exists and/or has a specific type.
 * The type is checked with a typeCheck function that is provided as a parameter.
 *
 * @param value - The object that we are checking.
 * @param key - The key that we are looking for in the object.
 * @param typeCheck - A function to check the type of the value.
 *
 * @returns - True if the key exists in the object and its value passes the typeCheck.
 *            False otherwise.
 */
function hasKey<KeyName extends string, ValueType>(
  value: unknown,
  key: KeyName,
  typeCheck?: (val: unknown) => val is ValueType
): value is Record<KeyName, ValueType> {
  return allPass([
    is(Object),
    has(key),
    isNotNil(typeCheck) ? propSatisfies(typeCheck, key) : always(true),
  ])(value);
}

export {hasKey, isReactNodeFunction};
