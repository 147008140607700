import {Severity, ThemeIconKey} from 'platform/foundation';

export interface TableRowAction {
  icon: ThemeIconKey;
  title: string;
  severity?: Severity;
  isDisabled?: boolean;
  description?: string;
  onClick: () => void;
}
