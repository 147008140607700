import {Icon} from 'platform/foundation';

import {PaginationVariant} from '../types/PaginationVariant';

export const defaultProps = {
  showFirstButton: false,
  showPrevButton: false,
  showNextButton: false,
  showLastButton: false,

  showFirstPage: true,
  showLastPage: true,
  showPageSeparator: <Icon value="navigation/more_horiz" />,

  variant: 'button' as PaginationVariant,
  inputSegmentSeparator: 'of ',

  activePage: 1,
  siblingCount: 1,
  boundaryCount: 1,

  pagesQuantity: 10,
  isDisabled: false,
};
