import {testIds} from '@omnetic-admin-service/shared';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useUpdateTenantMutation} from '../../../api/adminApi';
import {i18n} from '../../../i18n/config';
import {GetTenantApiResponse, TenantResponseBody, UpdateTenantApiArg} from '../../../types';
import {handleApiError} from '../../../utils/handleApiError';

interface EditTenantModalProps {
  tenant?: GetTenantApiResponse;
}

export function EditTenantDialog(props: EditTenantModalProps) {
  const [updateTenant, {isLoading}] = useUpdateTenantMutation();

  const onSubmit: FormSubmitHandler<TenantResponseBody> = async (values) => {
    const {id, ...updateTenantRequestBody} = values;
    const args: UpdateTenantApiArg = {
      tenantId: id,
      updateTenantRequestBody,
    };

    await updateTenant(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('tenants.editTenantSuccessMessage'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  return (
    <Form onSubmit={onSubmit} schema={schema} defaultValues={props.tenant}>
      {(control) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                data-testid={testIds.tenantDetail('editDialog-id')}
                name="id"
                type="text"
                control={control}
                isDisabled
                label={i18n.t('common.id')}
              />
            </Box>
            <Box flex={1}>
              <FormField
                data-testid={testIds.tenantDetail('editDialog-host')}
                name="host"
                type="text"
                isRequired
                control={control}
                label={i18n.t('common.host')}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                data-testid={testIds.tenantDetail('editDialog-workspace')}
                name="workspace"
                type="text"
                control={control}
                isDisabled
                label={i18n.t('common.workspace')}
              />
            </Box>
            <Box flex={1}>
              <FormField
                data-testid={testIds.tenantDetail('editDialog-name')}
                name="name"
                type="text"
                isRequired
                control={control}
                label={i18n.t('common.name')}
              />
            </Box>
          </HStack>
          <FormField
            data-testid={testIds.tenantDetail('editDialog-note')}
            name="note"
            type="textarea"
            control={control}
            label={i18n.t('common.note')}
            minRows={3}
          />

          <ButtonGroup align="right">
            <Button
              isDisabled={isLoading}
              data-testid={testIds.tenantDetail('editDialog-discard')}
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('common.cancel')}
            />
            <FormButton
              control={control}
              type="submit"
              data-testid={testIds.tenantDetail('editDialog-Save')}
              isLoading={isLoading}
              title={i18n.t('common.save')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  host: Yup.string().required(),
  name: Yup.string().required(),
  id: Yup.string().trim(),
  workspace: Yup.string().trim(),
  note: Yup.string().trim().max(255),
});
